const defaultState = {
  conversation: [],
  retry: false
};
const pdfConversationReducer = (state = defaultState, action) => {
  switch (action.type) {
      case 'SET_CONVERSATION':
          return {...state, conversation: action.welcomyAI.pdfConversation};
      case 'SET_RETRY':
        return {...state, retry: action.retry};

      default:
          return state;
  }
};

export default pdfConversationReducer;
import { notification } from "antd";


export const displayNotification = (type, title, desc) => {

    if (!["info", "error", "success"].includes(type)) type = "info";

    notification[type]({
        message: title,
        description: desc,
        ...(type === "error" && {duration: 8}),
        ...(desc.indexOf("\n") >= 0 &&
            { className: 'line-break' }),
        style: {
            fontFamily: "Raleway, sans-serif"
        },
    });
}

export const DELETE_LINK = "DELETE_LINK";
export const SET_LINKS = "SET_LINKS";
export const UPDATE_LINK = "UPDATE_LINK";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const SET_WORKSPACES = "SET_WORKSPACES";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";

export const setConfig = (config) => {
  return {
    type: "SET",
    companyConfig: config,
  };
};

export const deleteCompanyLink = (linkId) => {
  return {
    type: DELETE_LINK,
    payload: { linkId },
  };
};

export const setCompanyLinks = (links) => {
  return {
    type: SET_LINKS,
    payload: links,
  };
};

export const updateCompanyLink = ({ id, updatedLink }) => {
  return {
    type: UPDATE_LINK,
    payload: { id, updatedLink },
  };
};

export const setWorkspace = (workspaces) => {
  return {
    type: SET_WORKSPACES,
    payload: workspaces,
  };
};

export const createWorkspaceAction = ({ workspace }) => {
  return {
    type: CREATE_WORKSPACE,
    payload: { workspace },
  };
};

export const updateWorkspace = ({ id, updatedWorkspace }) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: { id, updatedWorkspace },
  };
};

export const deleteWorkspace = ({ subAccountId }) => {
  return {
    type: DELETE_WORKSPACE,
    payload: { subAccountId },
  };
};

import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector, batch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import update from "immutability-helper";
import {
  Button,
  Col,
  Grid,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { getIntl } from "../../utils/intl";
import http, { getErrorMsg } from "../../utils/http";
import { displayNotification } from '../../components/Notification';

import { EditOutlined, MenuOutlined } from "@ant-design/icons";
import CreateDish from "./CreateDish";
import {
  dishSetter,
  digitalMenuDishesSetter,
  digitalMenuCatsSetter,
} from "../../redux/actions";

const { Title, Text } = Typography;

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  categoryIndex,
  ...restProps
}) => {
  const ref = useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: "DragableBodyRow",
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index, categoryIndex);
    },
  });
  const [, drag] = useDrag({
    type: "DragableBodyRow",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move" }}
      {...restProps}
    />
  );
};

const CategoryInput = ({
  changeCategoryName,
  setShowCategoryCreaction,
  createNewCategory,
  showDelete = false,
  placeholderText = 'New category',
  deleteCategory = () => null,
  }) => {
  const intl = getIntl();
  const Confirm = intl.formatMessage({
    id: "OrganizeMenuPage.Category.Button.Save",
    defaultMessage: "Bestätigen"
  });
  const Cancel = intl.formatMessage({
    id: "Cancel",
    defaultMessage: "Abbrechen"
  });
  const Delete = intl.formatMessage({
    id: "OrganizeMenuPage.NewCategory.Delete",
    defaultMessage: "Kategorie löschen"
  });
  const Disclaimer = intl.formatMessage({
    id: "OrganizeMenuPage.NewCategory.Disclaimer",
    defaultMessage: `Hiermit wird die Kategorie inklusive der Inhalte gelöscht.
    Sind Sie sicher, dass Sie den Löschvorgang durchführen möchten?`
  });

  return (
    <Row
      className="category-creation-wrapper m-t-24"
      key="new-category-input"
    >
      <Col xs={24}>
        <Row gutter={[12, 12]} align="bottom">
          <Col xs={24} lg={showDelete ? 11 : 16}>
            <Input
              placeholder={placeholderText}
              bordered={false}
              className="category-creation-input"
              onChange={changeCategoryName}
            />
          </Col>
          <Col xs={24} lg={4}>
            <Button type="secundary" block onClick={() => setShowCategoryCreaction(false)}>
              {Cancel}
            </Button>
          </Col>
          { showDelete &&
            <Col xs={24} lg={5}>
              <Popconfirm
                title={Disclaimer}
                onConfirm={deleteCategory}
                okButtonProps={{
                  danger: true
                }}
                cancelText={Cancel}
                okText={Confirm}
              >
                <Button type="primary" danger block >
                  {Delete}
                </Button>
              </Popconfirm>
            </Col>
          }
          <Col xs={24} lg={4}>
            <Button type="primary" block onClick={createNewCategory}>
              {Confirm}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

const Category = ({
  className,
  style,
  category,
  categoryIndex,
  showModal,
  setDishToEdit,
  ...props
}) => {
  const dispatch = useDispatch();
  const dishes = useSelector((state) => state.digitalMenuConfig.dishes);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const verticalId = useSelector((state) => state.companyConfig.vertical_id);
  const [shouldSaveDishOrder, setShouldSaveDishOrder] = useState(false);
  const [shouldSaveCategoryOrder, setShouldSaveCategoryOrder] = useState(false);
  const [showEditCat, setShowEditCat] = useState(false);
  const [newCategoryName, setnewCategoyName] = useState('');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint()
  const intl = getIntl();

  let observer = null;
  if (dishes !== null) {
    observer = dishes;
  }

  const sort = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Sort",
    defaultMessage: "Sortieren"
  });
  const title = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Name",
    defaultMessage: "Name"
  });
  const description = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Description",
    defaultMessage: "Beschreibung"
  });
  const price = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Price",
    defaultMessage: "Preis"
  });
  const available = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Available",
    defaultMessage: "Verfügbar"
  });
  const editButtonText = intl.formatMessage({
    id: "OrganizeMenuPage.DishTable.Column.Button.Edit",
    defaultMessage: "Bearbeiten"
  });

  const EditCategory = intl.formatMessage({
    id: "OrganizeMenuPage.NewCategory.Edit",
    defaultMessage: "Name der Kategorie bearbeiten"
  });

  const multiPricing = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.MultiPricing",
    defaultMessage: "Mehrfache Preisoptionen"
  });

  let newItemId = 'OrganizeMenuPage.DishTable.Button.NewDish';
  let itemOrderId = 'OrganizeMenuPage.DishTable.Button.DishOrder';
  if (verticalId && verticalId == 4){
    newItemId = 'OrganizeMenuPage.Business.DishTable.Button.NewDish';
    itemOrderId = 'OrganizeMenuPage.Business.DishTable.Button.DishOrder';
  }

  const changeAvailability = async (record) => {
    const newAvailability = !record.availability;
    const dishIndex = dishes[categoryIndex].map(dish => dish.id).indexOf(record.id);
    dishes[categoryIndex][dishIndex].availability = newAvailability;
    try {
      const response = await http.put(
        `gastro/${companyId}/category/${category.id}/dish/${dishes[categoryIndex][dishIndex].id}`,
        {'availability': newAvailability}
      );
      if (response.status === 200) {
        dispatch(digitalMenuDishesSetter([...dishes]));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error);
    }

  }

  const columns = [
    {
      title: sort,
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />,
    },
    {
      title: title,
      dataIndex: "name",
      className: "drag-visible",
      render: (item) => {
        return (
          <Text className="inline-block max-width-100">{ item }</Text>
        );
      }
    },
    {
      title: description,
      dataIndex: "description",
      render: (item) => {
        if (!item) return '';
        return (
          <Text className="max-width-100" ellipsis>{ item }</Text>
        );
      }
    },
    {
      title: price,
      dataIndex: "price",
      render: (item) => {
        let types = [];
        if (item instanceof Object) {
          types = Object.keys(item);
        }
        if (types.length === 1) {
          return Number(item[types[0]]).toFixed(2);
        } else if (types.length > 1) {
          return multiPricing;
        }
        return "0.00";
      },
    },
    {
      title: available,
      dataIndex: "availability",
      render: (item, record) => {
        return <Switch  checked={item} onChange={() => changeAvailability(record)}/>
      },
    },
    {
      title: editButtonText,
      render: (item) => (
        <EditOutlined
          key="edit"
          onClick={() => {
            setDishToEdit(item);
            showModal(true);
            dispatch(
              dishSetter({ category: { index: categoryIndex, ...category } })
            );
          }}
        />
      ),
    },
  ];

  const categoryData = useSelector(
    (state) => state.digitalMenuConfig.categories
  );

  useEffect(() => {
    if (shouldSaveCategoryOrder) {
      saveCategoryOrder();
    }
  }, [categoryData, shouldSaveCategoryOrder]);


  const saveCategoryOrder = () => {
    let ids = categoryData.map((dish) => {
      return dish.id;
    });
    const data = { ids: ids };
    http
      .post(`/gastro/${companyId}/category_rank`, data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const moveCategory = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCategory = categoryData[dragIndex];
      const dragDishes = dishes[dragIndex];

      const resultCategory = update(categoryData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCategory],
        ],
      });

      const reorderDishes = update(dishes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragDishes],
        ],
      });
      // Re-render only once
      batch(() => {
        dispatch(digitalMenuDishesSetter(reorderDishes));
        dispatch(digitalMenuCatsSetter(resultCategory));
      });
      setShouldSaveCategoryOrder(true);
    },
    [categoryData, dishes]
  );

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      setShouldSaveDishOrder(true);
      const dragRow = dishes[categoryIndex][dragIndex];

      const result = update(dishes, {
        [categoryIndex]: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      });
      dispatch(digitalMenuDishesSetter(result));
    },
    [observer]
  );

  const ref = useRef(null);

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: "DraggableCategory",
    collect: (monitor) => {
      const { categoryIndex: dragIndex } = monitor.getItem() || {};
      if (dragIndex === categoryIndex) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < categoryIndex
            ? " drop-over-downward"
            : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveCategory(item.categoryIndex, categoryIndex);
    },
  });
  const [, drag] = useDrag({
    type: "DraggableCategory",
    item: { categoryIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const createNewDish = (catIndex, category) => {
    dispatch(dishSetter({ category: { index: catIndex, ...category } }));
    showModal(true);
  };

  const saveDishOrder = (catIndex) => {
    if (!dishes) {
      // Notification
      return;
    }
    let ids = [];
    dishes[catIndex].map((dish) => {
      ids.push(dish.id);
    });
    const data = { ids: ids };
    http
      .post(`/gastro/${companyId}/category/${category.id}/dish_rank`, data)
      .then((res) => {
        setShouldSaveDishOrder(false);
        const title = intl.formatMessage(
          {
            id: "OrganizeMenuPage.RankDish.Success.Title",
            defaultMessage: "Erfolg!"
          }
        );
        const body = intl.formatMessage(
          {
            id: "OrganizeMenuPage.RankDish.Success.Body",
            defaultMessage: "Die Position der Menüpunkte wurde erfolgreich aktualisiert"
          }
        );
        displayNotification("success", title, body);
      })
      .catch((error) => {
        console.log(error);
        const title = intl.formatMessage(
          {
            id: "OrganizeMenuPage.RankDish.Error.Title",
            defaultMessage: "Die Änderung ist fehlgeschlagen"
          }
        );
        const message = getErrorMsg(error, intl, false, true);
        displayNotification("error", title, message);
      });
  };

  const editCategoryName = (e) => {
    setnewCategoyName(e.target.value)
  }

  const showEditCategory = () => {
    setShowEditCat(!showEditCat);
    setnewCategoyName('');
  }
  const persistCategotryEdit = async () => {
    if (newCategoryName) {
      try {
        const categoryId = category.id;
        const result = await http.put(`/gastro/${companyId}/category/${categoryId}`, {
          name: newCategoryName,
        });
        if (result.status === 204) {
          const _categories = categoryData.map(cat => {
            if (cat.id === category.id) {
              cat.name = newCategoryName;
            }
            return cat;
          });
          dispatch(digitalMenuCatsSetter(_categories));
          showEditCategory();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteCategory = async () => {
    try {
      const categoryId = category.id;
      const result = await http.delete(`/gastro/${companyId}/category/${categoryId}`);
      if (result.status === 204) {
        const _categories = categoryData.filter(cat => cat.id !== category.id);
        dispatch(digitalMenuCatsSetter(_categories));
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Row
      ref={ref}
      className={`p-t-32 ${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...props}
    >
      <Col xs={24}>
        <Row>
          { showEditCat &&
            <Col xs={24} className="m-b-6">
              <CategoryInput
                changeCategoryName={editCategoryName}
                setShowCategoryCreaction={showEditCategory}
                createNewCategory={persistCategotryEdit}
                showDelete={true}
                placeholderText={EditCategory}
                deleteCategory={deleteCategory}
              />
            </Col>
          }
          { !showEditCat &&
            <>
              <Col xs={24} lg={12} id={category.id}>
                <Title level={4}>
                  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />{" "}
                  {category.name}
                  {" "}<EditOutlined onClick={() => showEditCategory()}/>
                </Title>
              </Col>
              <Col xs={24} lg={12}>
                <div className={(screens.xs || screens.sm) && !screens.lg ? "m-b-6" : "button--new-category"}>
                  <Space wrap>
                    <Button
                      type="primary"
                      className="responsive__button"
                      onClick={() => createNewDish(categoryIndex, category)}
                    >
                      <FormattedMessage
                        id={newItemId}
                        defaultMessage="Neues Gericht erstellen"
                      ></FormattedMessage>
                    </Button>
                    <Button
                      className="responsive__button"
                      type="primary"
                      disabled={!shouldSaveDishOrder}
                      onClick={() => saveDishOrder(categoryIndex, category)}
                    >
                      <FormattedMessage
                        id={itemOrderId}
                        defaultMessage="Position der Gerichte speichern"
                      ></FormattedMessage>
                    </Button>
                  </Space>
                </div>
              </Col>
            </>
          }
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={dishes !== null ? dishes[categoryIndex] : null}
              pagination={false}
              components={components}
              // rowKey={(record, index) => {
              //   return `${index}-${record.name}`;
              // }}
              onRow={(record, index) => ({
                type: "DragableBodyRow",
                index,
                moveRow,
                categoryIndex,
              })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

function OrganizeMenu(props) {
  const intl = getIntl();
  const [dishModalOpen, setDishModalOpen] = useState(false);
  const [dishToEdit, setDishToEdit] = useState(null);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const dispatch = useDispatch();

  // FETCH DATA
  useEffect(() => {
    http
      .get(`gastro/${companyId}/menu`, {
        params: {
          available_only: false,
        },
      })
      .then((response) => {
        const { payload } = response.data;
        let transformedMenu = [];
        if (Object.keys(payload).indexOf('menu_url') === -1) {
          Object.entries(payload).map(([key, value]) => {
            let dishes = [];
            if (value.dishes) {
              value["dishes"].map((dish, index) => {
                dishes.push({
                  id: dish["dish_id"],
                  name: dish["dish_name"],
                  price: dish["dish_price"],
                  description: dish["description"],
                  allergens: dish["allergens"].reduce((acc, al) => {
                    if (typeof al.is_preservative !== 'undefined' && !al.is_preservative) {
                      acc.push(al.allergen_id);
                    }
                    return acc;
                  }, []),
                  preservatives: dish["allergens"].reduce((acc, al) => {
                    if (typeof al.is_preservative !== 'undefined' && al.is_preservative) {
                      acc.push(al.allergen_id);
                    }
                    return acc;
                  }, []),
                  availability: dish["availability"],
                  index: index,
                  images: dish["dish_images"],
                  spice_level: dish['spice_level'] !== null ? dish['spice_level'] : 0,
                });
              });
            }
            transformedMenu.push({
              id: key,
              name: value["category_name"],
              dishes: dishes,
            });
          });
        }

        dispatch(
          digitalMenuDishesSetter(transformedMenu.map((cat) => cat.dishes))
        );
        dispatch(
          digitalMenuCatsSetter(
            transformedMenu.map((cat) => {
              delete cat.dishes;
              return cat;
            })
          )
        );
      });
  }, []);

  const RenderCategory = (props) => {
    const [showCategoryCreaction, setShowCategoryCreaction] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState(null);
    const dispatch = useDispatch();
    const intl = getIntl();
    const NewCategory = intl.formatMessage({
      id: "OrganizeMenuPage.NewCategory",
      defaultMessage: "Neuer Kategoriename"
    });
    
    const categoryData = useSelector(
      (state) => state.digitalMenuConfig.categories
    );

    const dishes = useSelector(state => state.digitalMenuConfig.dishes)

    const changeCategoryName = (e) => {
      setNewCategoryName(e.target.value);
    };

    const createNewCategory = async () => {
      try {
        const result = await http.post(`gastro/${companyId}/category`, {
          name: newCategoryName,
        });

        categoryData.push({ id: result.data.payload.id, name: newCategoryName });
        dishes.push([]);
        dispatch(digitalMenuCatsSetter([...categoryData]));
        dispatch(digitalMenuDishesSetter([...dishes]));
        const title = intl.formatMessage(
          {
            id: "OrganizeMenuPage.NewCategory.Success.Title",
            defaultMessage: "Erfolg!"
          }
        );
        const body = intl.formatMessage(
          {
            id: "OrganizeMenuPage.NewCategory.Success.Body",
            defaultMessage: "Die neue Kategorie wurde erfolgreich kreiert"
          }
        );
        displayNotification("success", title, body);
      } catch (error) {
        console.error("Not able to create category", error);
        const title = intl.formatMessage(
          {
            id: "OrganizeMenuPage.NewCategory.Error.Title",
            defaultMessage: "Die Erstellung der neuen Kategorie ist fehlgeschlagen!"
          }
        );
        const message = getErrorMsg(error, intl, false, true);
        displayNotification("error", title, message);
      }
      setShowCategoryCreaction(false)
    };

    const toRender = [];

    if (categoryData && categoryData !== null) {
      toRender.push(
        <div>
          {Object.keys(categoryData).map((index) => (
            <Category
              className="category-wrapper m-b-32"
              category={categoryData[index]}
              categoryIndex={index}
              key={categoryData[index].id}
              showModal={setDishModalOpen}
              setDishToEdit={setDishToEdit}
            />
          ))}
        </div>
      );
    }
    toRender.push(
      <div>
        {showCategoryCreaction === false && (
          <Row className="m-t-24" key="new-category-button">
            <Col xs={24}>
              <Button
                type="primary"
                block
                size="large"
                onClick={() => setShowCategoryCreaction(true)}
              >
                <FormattedMessage
                  id="OrganizeMenuPage.Category.Button.Create"
                  defaultMessage="Neue Kategorie erstellen"
                ></FormattedMessage>
              </Button>
            </Col>
          </Row>
        )}
        {showCategoryCreaction === true && (
          <CategoryInput
            changeCategoryName={changeCategoryName}
            setShowCategoryCreaction={setShowCategoryCreaction}
            createNewCategory={createNewCategory}
            placeholderText={NewCategory}
          />
        )}
      </div>
    );

    return <>{toRender}</>;
  };

  return (
    <div>
      <CreateDish
        className="create-dish-wrapper"
        isOpen={dishModalOpen}
        showModal={setDishModalOpen}
        dishToEdit={dishToEdit}
        setDishToEdit={setDishToEdit}
      />
      <Row>
        <Col xs={24}>
          <Title level={4} className="page-title m-t-32">
            <FormattedMessage
              id="OrganizeMenuPage.DigitalMenu.SubHeader"
              defaultMessage="Verwalten Sie Ihre digitale Welcomy Speise- und Getränkekarte"
            ></FormattedMessage>
          </Title>
        </Col>
      </Row>
      <DndProvider backend={HTML5Backend}>
        <RenderCategory />
      </DndProvider>
    </div>
  );
}

export default React.memo(OrganizeMenu);

import { SET_HUB_CONFIG } from "../actions/hubConfig";

const initialState = {
  name: null,
  hubLogo: null,
  title: null,
  subtitle: null,
}

const hubConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HUB_CONFIG:
      const payload = {
        ...state,
        ...action.config,
        hubLogo: action.config.image_url || action.config.hubLogo,
      };
      delete payload.image_url;
      return payload;
    default:
      return state;
  }
}

export default hubConfigReducer;
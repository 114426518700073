import React from "react";
import { Helmet } from "react-helmet";

const SEOPreMenuPage = ({ companyName, canonical, companyLogo }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{`${companyName} - Welcomy App`} </title>
    <link rel="canonical" href={canonical} />
    <meta
      name="description"
      content={`Discover all the necessary information about "${companyName}" using Welcomy App. Links, social networks, food menu, and more!`}
    />
    <meta property="og:title" content={`${companyName} - Welcomy App`} />
    <meta property="og:url" content={canonical} />
    <meta
      property="og:description"
      content={`Discover all the necessary information about "${companyName}" using Welcomy App. Links, social networks, menu, and more!`}
    />
    <meta property="og:image" content={companyLogo} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="de_DE" />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@WelcomyApp" />
  </Helmet>
);
export default SEOPreMenuPage;

export const setPdfConversation = (conversation) => {
  console.log('ACTION', conversation);
  return {
      type: 'SET_CONVERSATION',
      welcomyAI: {
        pdfConversation: conversation,
      },
  };
};

export const setPdfConversationMessageRetry = (retry) => {
  return {
      type: 'SET_RETRY',
      retry,
  };
};
import {createLink, deleteLink} from "./linkManagerApiService";

export const createLinkManagement = (existingLinks, companyId, newLink) => {
    // here we delete id because BE expects links to be without ID
    // BE only cares about the order of the links
    const links = [newLink, ...existingLinks].map((link) => {
        delete link.id;
        if(link.type === "email"){
            // Delete subject as BE doesn't expect it. It is added for supporting
            // subjects in email type links.
            delete link.subject;
        }
        return link;
    })

    return createLink(companyId, links);
}

export const deleteLinkManagement = (companyId, linkId) => {
    return deleteLink(companyId, linkId);
}
export const ADD_LIVE_PREVIEW_LINK = 'ADD_LIVE_PREVIEW_LINK';
export const UPDATE_LIVE_PREVIEW_NEW_LINK = 'UPDATE_LIVE_PREVIEW_NEW_LINK';
export const DELETE_LIVE_PREVIEW_LINK = 'DELETE_LIVE_PREVIEW_LINK';
export const SET_LIVE_PREVIEW_LINKS = 'SET_LIVE_PREVIEW_LINKS';
export const UPDATE_LIVE_PREVIEW_LINK = 'UPDATE_LIVE_PREVIEW_LINK';
export const UPDATE_LIVE_PREVIEW_COMPANY_SETUP = 'UPDATE_LIVE_PREVIEW_COMPANY_SETUP';

export const setLivePreviewLinks = (links) => {
    return {
        type: SET_LIVE_PREVIEW_LINKS,
        payload: links
    }
}

export const addLivePreviewLink = (content) => {
    return {
        type: ADD_LIVE_PREVIEW_LINK,
        payload: content,
    };
};

export const deleteLivePreviewLink = (id) => {
    return {
        type: DELETE_LIVE_PREVIEW_LINK,
        payload: id,
    }
};

export const updateLivePreviewNewLink = ({id, content}) => {
    return {
        type: UPDATE_LIVE_PREVIEW_NEW_LINK,
        payload: {id, content},
    };
};

export const updateLivePreviewLink = ({id, updatedLink}) => {
    return {
        type: UPDATE_LIVE_PREVIEW_LINK,
        payload: {id, updatedLink}
    }
}

export const updateLivePreviewCompanySetup = (companySetup) => {
    return {
        type: UPDATE_LIVE_PREVIEW_COMPANY_SETUP,
        payload: companySetup,
    }
}


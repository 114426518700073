import { DownOutlined, FlagFilled } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import DeFlag from "../../../../../static/flags/de";
import GbFlag from "../../../../../static/flags/gb";
import ItFlag from "../../../../../static/flags/it";
import FrFlag from "../../../../../static/flags/fr";
import NlFlag from "../../../../../static/flags/nl";


function GoogleTranslator(props) {
  const originalLanguage = 'de'
  const [currLang, setCurrLang] = useState(originalLanguage);
  const langFlags = {
    'de': <DeFlag className="flag--small"/>,
    'en': <GbFlag className="flag--small"/>,
    'it': <ItFlag className="flag--small"/>,
    'fr': <FrFlag className="flag--small"/>,
    'nl': <NlFlag className="flag--small"/>,
  };

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    const cur_lang = window.localStorage.getItem('cur_lang') || originalLanguage;
    setCurrLang(cur_lang);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: originalLanguage,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const changeLanguage = (e, lang) => {
    const href = e.currentTarget.getAttribute("href");
    window.location = href;
    window.localStorage.setItem('cur_lang', lang);
    document.getElementsByClassName("goog-te-combo").value = lang;
    if (lang === originalLanguage) {
      // Force google translator to propagate change across website.
      document.cookie = 'googtrans=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = `googtrans=; Domain=${window.location.hostname}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
    window.location.reload();
  };

  const Languages = (
    <Menu theme="light">
      <Menu.Item>
        <a
          href="#googtrans(de|de)"
          onClick={(e) => changeLanguage(e, "de")}
        >
          <DeFlag className="flag--small"/>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="#googtrans(de|en)"
          onClick={(e) => changeLanguage(e, "en")}
        >
          <GbFlag className="flag--small"/>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="#googtrans(de|it)"
          onClick={(e) => changeLanguage(e, "it")}
        >
          <ItFlag className="flag--small"/>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="#googtrans(de|fr)"
          onClick={(e) => changeLanguage(e, "fr")}
        >
          <FrFlag className="flag--small"/>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="#googtrans(de|nl)"
          onClick={(e) => changeLanguage(e, "nl")}
        >
          <NlFlag className="flag--small"/>
        </a>
      </Menu.Item>
    </Menu>
  );

  const SelectedLanguage = () => {
    return langFlags[currLang];
  };

  return (
    <Dropdown overlay={Languages} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()} className="google-translator__dropdown">
        <Space>
          <SelectedLanguage />
        </Space>
      </a>
    </Dropdown>
  );
}

export default GoogleTranslator;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PasswordChecklist from "react-password-checklist"

import { configSetter } from "../../redux/actions";
import http from "../../utils/http";
import "./login.scss";
import Logo from '../../static/Logo';
import { getIntl } from "../../utils/intl";

import { Row, Col, Button, Typography, Alert, Space, Input } from "antd";
import { getCookie } from "../../components/getCookie";
import { cookieDomain } from "../../utils/envs";
import BusinessImageSrc from "../../static/login-screen/screen.png";

import { CheckCircleFilled } from '@ant-design/icons';

import ClientLogo from "./ClientLogo";
import { PasswordResetTranslations } from "../PasswordReset/translations";

const { Title, Text, Paragraph } = Typography;

const BusinessImg = () => (
  <div className="welcomy-screenshot">
  <img
    src={BusinessImageSrc}
    alt="Captured screen from Welcomy. The best platform for your B2B sales and online presence."
    srcset=""
  />
  </div>
);

// All of the verticals are now returning the same image for the right part of the
// screen. This is to be more concise with our new B2B branding approach.
// This logic will be kept in place in case we want to change the captured
// screens in the future tho.
const BackgroundImage = ({vertical_id}) => {
  if (vertical_id) {
    switch (vertical_id) {
      case 1:
        return <BusinessImg />
      case 2:
        return <BusinessImg />
      case 3:
        return (
        <BusinessImg />
      );
      case 4:
        return (
          <BusinessImg />
        );
      default:
        return <BusinessImg />;
    }
  }
  return <BusinessImg />;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.btnRef = React.createRef();
    this.emailPasswordReset = React.createRef(null);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      accessCode: "",
      error: false,
      domainError: "",
      errorMessage: "",
      domain: "",
      showSignup: false,
      signupValid: false,
      showPasswordReset: false,
      passwordResetError: null,
      passwordResetSuccess: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const company_id = this.props.companyConfig.company_id;
    if (company_id && getCookie("_a")) {
      if(this.props.companyConfig.super_account){
        this.props.history.push("/dashboard/sub-accounts");
      }
      else{
        this.props.history.push("/dashboard");
      }
    }
  }

  updateEmail = (e) => {
    const { value } = e.target;
    this.setState({
      email: value,
    });
  };

  updatePassword = (e) => {
    const { value } = e.target;
    this.setState({
      password: value,
    });
  };

  updateConfirmPassword = (e) => {
    const { value } = e.target;
    this.setState({
      confirmPassword: value,
    });
  };

  updateSignUpValid = (isValid) => {
    this.setState({
      signupValid: isValid,
    });
  };

  updateAccessCode = (e) => {
    const { value } = e.target;
    this.setState({
      accessCode: value,
    });
  };

  updateUrl = (e) => {
    const { value } = e.target;
    this.setState({
      domain: value,
    });
  };

  validateForm = (isSignup) => {
    const emailLength = this.state.email.length;
    const passwordLength = this.state.password.length;
    let allInputsWritten = emailLength > 0 && passwordLength > 0;
    if (isSignup) {
      const activationCode = this.state.accessCode.length;
      allInputsWritten = allInputsWritten && activationCode;
      if (!this.state.signupValid) {
        const intl = getIntl();
        const invalidInput = intl.formatMessage({ id: "Signup.Error" });
        this.setState({
          error: true,
          errorMessage: invalidInput,
        });
        return false;
      }
    }

    if (!allInputsWritten) {
      const intl = getIntl();
      const emptyCredentials = intl.formatMessage({
        id: "Login.Error.Credentials.Empty",
        defaultMessage: "Bitte geben Sie Ihre Anmeldeinformationen an.",
      });
      this.setState({
        error: true,
        errorMessage: emptyCredentials,
      });
    }

    return allInputsWritten;
  };

  doLogin = async (e) => {
    e.preventDefault();
    if (this.validateForm(false)) {
      const { history } = this.props;
      const { email, password } = this.state;
      const company_id = this.props.companyConfig.company_id;

      try {
        const result = await http.post("auth/login", {
          email,
          password,
          company_id,
        });
        const { access_token, role } = result.data.payload;
        const user_id = result.data.payload.id;
        const domain = cookieDomain();
        document.cookie = `_a=${access_token};domain=${domain};path=/;`;
        document.cookie = `_c=${company_id};domain=${domain};path=/;`;
        document.cookie = `_u=${user_id};domain=${domain};path=/;`;
        document.cookie = `_ur=${role};domain=${domain};path=/;`;

        await this.fetchCompanyConfig();
        if(this.props.companyConfig.super_account){
          history.push("/dashboard/sub-accounts");
        }
        else{
          history.push("/dashboard");
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 401) {
          const intl = getIntl();
          const invalidCredentials = intl.formatMessage({
            id: "Login.Error.Credentials.Invalid",
            defaultMessage:
              "Fehler beim Anmelden. Bitte checken Sie Ihre Anmeldedaten. Falls Sie noch keinen Welcomy-Account haben, so registrieren Sie sich Bitte zuerst.",
          });
          this.setState({
            error: true,
            errorMessage: invalidCredentials,
          });
        }
      }
    }
  };

  fetchCompanyConfig = async () => {
    let id = this.props.companyConfig.company_id || window.localStorage["_c"];
    let default_name = "New Company";
    let default_logo_url = "";
    let default_sub_accounts = [];
    let default_super_account = false;
    let default_vertical_id = 0;
    try {
      const response = await http.get(`company/${id}`);
      const accountInfo = await http.get(`/company/${id}/account`);
      let { sub_accounts, super_account } = accountInfo.data.payload;
      default_sub_accounts = sub_accounts;
      default_super_account = super_account;

      let { name, logo_url, vertical_id, links, legal_entity } = response.data.payload;
      default_name = name === null ? default_name : name;
      default_logo_url = logo_url === null ? default_logo_url : logo_url;
      default_vertical_id =
        vertical_id === null ? default_vertical_id : parseInt(vertical_id);

      const domain = cookieDomain();
      document.cookie = `_v=${default_vertical_id};domain=${domain};path=/;`;

      const toBeSetted = {
        name: default_name,
        company_logo: default_logo_url,
        company_id: id,
        hasFetchedConfig: true,
        sub_accounts: default_sub_accounts,
        super_account: default_super_account,
        vertical_id: default_vertical_id,
        legalEntity: legal_entity,
        links: links,
      };

      this.props.dispatch(configSetter({ ...toBeSetted }));
    } catch (error) {
      console.error(error);
    }
  };

  doSignup = async (e) => {
    e.preventDefault();
    if (this.validateForm(true)) {
      const { email, password, accessCode } = this.state;
      const company_id = this.props.companyConfig.company_id;
      try {
        const result = await http.post("auth/signup", {
          email,
          password,
          access_code: accessCode,
          company_id,
        });
        const { access_token, role } = result.data.payload;
        const user_id = result.data.payload.id;

        const domain = cookieDomain();

        document.cookie = `_a=${access_token};domain=${domain};path=/;`;
        document.cookie = `_c=${company_id};domain=${domain};path=/;`;
        document.cookie = `_u=${user_id};domain=${domain};path=/;`;
        document.cookie = `_ur=${role};domain=${domain};path=/;`;

        await this.fetchCompanyConfig();
        if(this.props.companyConfig.super_account){
          this.props.history.push("/dashboard/sub-accounts");
        }
        else{
          this.props.history.push("/dashboard");
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 401) {
          // TODO: Needs Translation. 401 would be a result of invalid access code
          this.setState({
            error: true,
            errorMessage:
              "Invalid credentials. Please check your credentials or sign up.",
          });
        }
        // TODO: Need to handle 400
      }
    }
  };

  showSignup = () => {
    this.setState({
      showSignup: !this.state.showSignup,
      showPasswordReset: false,
    });
  };

  togglePasswordReset = (show) => {
    this.setState({
      showPasswordReset: show,
      showSignup: false,
      passwordResetError: null,
      passwordResetSuccess: false,
    });
  };

  extractCompanyFromURL = async (domain) => {
    let path = window.location.hostname.split(".")[0];
    if (domain) {
      path = domain;
    }
    if (path && path !== "welcomyapp" && path !== "localhost") {
      try {
        const result = await http.get("/company_path", {
          params: {
            path,
          },
        });
        return true;
      } catch (error) {
        return error.response.status;
      }
    }
    return false;
  };

  hasCompanyDomain = () => {
    const path = window.location.hostname.split(".")[0];
    if (!path || path === "welcomyapp" || path === "localhost") return false;
    return path;
  };

  goToUrl = async (e) => {
    e.preventDefault();
    const { domain } = this.state;
    if (domain.trim() && domain.trim().length > 0) {
      // Check if company exists
      const existCompany = await this.extractCompanyFromURL(domain);
      if (existCompany === 404) {
        this.setState({
          domainError: true,
          errorMessage:
            "We were not able to find your Welcomy workspace, please contact us.",
        });
      } else if (existCompany) {
        const { protocol, host } = window.location;
        window.location = `${protocol}//${domain}.${host}`;
      }
    } else {
      this.setState({
        domainError: true,
        errorMessage: "Please provide us with your workspace name.",
      });
    }
  };

  doPasswordReset = async (e) => {
    e.preventDefault();
    const email = this.emailPasswordReset.current.value;
    try {
      const result = await http.post(`/auth/forgot_password`, {
        email,
      });
      console.log(result);

      // Remove any previous errors
      if (this.state.passwordResetError) {
        this.setState({
          passwordResetError: null,
        });
      }

      if (result.status >= 200 && result.status <= 299) {
        this.setState({
          passwordResetSuccess: true,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        this.setState({
          passwordResetError: 'E-Mail konnte nicht gefunden werden',
          passwordResetSuccess: false,
        });
      }
    }
  };

  renderUrlPicker = () => {
    const hasDomain = this.hasCompanyDomain();
    const intl = getIntl();
    const greetingTitle = intl.formatMessage({
      id: "Login.Greetings.Signup",
      defaultMessage: "Anmelden",
    });
    const greetingText = intl.formatMessage({
      id: "Login.Greetings.Text",
      defaultMessage: "Melden Sie sich hier mit Ihrem Welcomy-Account an.",
    });
    const passwordResetSubtitle = intl.formatMessage(PasswordResetTranslations.title);
    const passwordResetPageTitle = intl.formatMessage(PasswordResetTranslations.pageTitle);
    const passwordResetEmailSent = intl.formatMessage(PasswordResetTranslations.submitSuccess);
    const workspaceText = intl.formatMessage({
      id: "Login.Workspace.Text",
      defaultMessage: "Geben Sie den Namen Ihres Welcomy-Workspaces ein.",
    });
    const workspacePlaceholder = intl.formatMessage({
      id: "Login.Workspace.Placeholder",
      defaultMessage: "Ihr Welcomy-Workspace, z.B. MeinUnternehmen",
    });
    const workspaceButton = intl.formatMessage({
      id: "Continue",
      defaultMessage: "Weiter",
    });
    const emailPlacholder = intl.formatMessage({
      id: "Email.Address",
      defaultMessage: "E-mail-Adresse",
    });
    const passwordPlaceholder = intl.formatMessage({
      id: "Password",
      defaultMessage: "Passwort",
    });
    const signupText = intl.formatMessage({
      id: "Sign.Up",
      defaultMessage: "Registrieren",
    });
    const signInText = intl.formatMessage({
      id: "Sign.In",
      defaultMessage: "Anmelden",
    });
    const registerText = intl.formatMessage({
      id: "Login.Register.Text",
      defaultMessage: "Bitte geben Sie Ihre Daten für die Registrierung an.",
    });
    if (!hasDomain) {
      return (
        <React.Fragment>
          <Title level={2} className="m-l-0 m-r-0">
            {greetingTitle}
          </Title>
          <Title level={4} className="m-l-0 m-r-0">
            {workspaceText}
          </Title>
          {this.state.domainError && (
            <Text className="error-message">{this.state.errorMessage}</Text>
          )}
          <form onSubmit={this.goToUrl} className="form-inline">
            <div>
              <input
                className="welcomy-input"
                type="text"
                placeholder={workspacePlaceholder}
                onChange={this.updateUrl}
              />
              <label>.welcomyapp.com</label>
            </div>
            <Button
              ref={this.btnRef}
              htmlType="submit"
              size="large"
              className="login-button"
              block
            >
              {workspaceButton}
            </Button>
          </form>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ClientLogo />
          {this.state.error && (
            <Text className="error-message">{this.state.errorMessage}</Text>
          )}
          {!this.state.showSignup && !this.state.showPasswordReset && (
            <>
              <Title level={2} className="m-l-0 m-r-0">
                {signInText}
              </Title>
              <Title level={4} className="m-l-0 m-r-0">
                {greetingText}
              </Title>
              <form onSubmit={this.doLogin}>
                <Input
                  className="welcomy-input"
                  type="email"
                  placeholder={emailPlacholder}
                  onChange={this.updateEmail}
                  tabIndex={1}
                />
                <Input.Password
                  className="welcomy-input"
                  type="password"
                  placeholder={passwordPlaceholder}
                  onChange={this.updatePassword}
                  tabIndex={2}
                />
                <Row>
                  <Col xs={24}>
                    <Paragraph className="m-t-4 m-b-4" underline>
                      <span
                        className="pointer"
                        onClick={() => this.togglePasswordReset(true)}
                      >
                        Passwort vergessen?
                      </span>
                    </Paragraph>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      ref={this.btnRef}
                      htmlType="submit"
                      size="large"
                      className="login-button"
                    >
                      {signInText}
                    </Button>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      ref={this.btnRef}
                      ghost
                      size="large"
                      className="login-button--ghost"
                      onClick={this.showSignup}
                    >
                      {signupText}
                    </Button>
                  </Col>
                </Row>
              </form>
            </>
          )}

          {this.state.showSignup && !this.state.showPasswordReset && (
            <>
              <Title level={2} className="m-l-0 m-r-0">
                {greetingTitle}
              </Title>
              <Title level={4} className="m-l-0 m-r-0">
                {registerText}
              </Title>
              <form onSubmit={this.doSignup}>
                <input
                  className="welcomy-input"
                  type="text"
                  placeholder="Registrierungscode"
                  onChange={this.updateAccessCode}
                  tabIndex={1}
                />

                <input
                  className="welcomy-input"
                  type="email"
                  placeholder="E-Mail"
                  onChange={this.updateEmail}
                  tabIndex={2}
                />
                <Input.Password
                  className="welcomy-input"
                  type="password"
                  placeholder="Passwort"
                  onChange={this.updatePassword}
                  tabIndex={3}
                />
                <Input.Password
                  className="welcomy-input"
                  type="password"
                  placeholder="Passwort wiederholen"
                  onChange={this.updateConfirmPassword}
                  tabIndex={3}
                />
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  messages={{
                    minLength:
                      "Das Passwort soll mindestens 8 Zeichen enthalten.",
                    specialChar:
                      "Das Passwort soll mindestens ein Sonderzeichen enthalten.",
                    number: "Das Passwort soll mindestens eine Zahl enthalten.",
                    capital:
                      "Das Passwort soll mindestens einen Großbuchstaben enthalten.",
                    match: "Beide Passworteingaben stimmen nicht überein.",
                  }}
                  minLength={8}
                  value={this.state.password}
                  valueAgain={this.state.confirmPassword}
                  onChange={(isValid) => {
                    this.updateSignUpValid(isValid);
                  }}
                />
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      ref={this.btnRef}
                      htmlType="submit"
                      size="large"
                      className="login-button"
                    >
                      Weiter
                    </Button>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      ref={this.btnRef}
                      ghost
                      size="large"
                      className="login-button--ghost"
                      onClick={this.showSignup}
                    >
                      Zurück
                    </Button>
                  </Col>
                </Row>
              </form>
            </>
          )}
          {!this.state.showSignup && this.state.showPasswordReset && (
            <>
              <Title level={2} className="m-l-0 m-r-0">
                {passwordResetPageTitle}
              </Title>
              <Title level={4} className="m-l-0 m-r-0">
                {passwordResetSubtitle}
              </Title>
              <form onSubmit={this.doPasswordReset}>
                {this.state.passwordResetSuccess && (
                  <Row>
                    <Col>
                      <Space>
                        <span>
                          <CheckCircleFilled style={{ color: "green", fontSize: 18 }} />
                        </span>
                        <span>{passwordResetEmailSent}</span>
                      </Space>
                    </Col>
                  </Row>
                )}
                <input
                  className="welcomy-input"
                  type="email"
                  placeholder={emailPlacholder}
                  ref={this.emailPasswordReset}
                />
                {this.state.passwordResetError && (
                  <Row>
                    <Col>
                      <Paragraph className="error-message m-t-4 m-b-4">
                        {this.state.passwordResetError}
                      </Paragraph>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      htmlType="submit"
                      size="large"
                      className="login-button"
                    >
                      Weiter
                    </Button>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Button
                      block
                      ghost
                      size="large"
                      className="login-button--ghost"
                      onClick={() => this.togglePasswordReset(false)}
                    >
                      Zurück
                    </Button>
                  </Col>
                </Row>
              </form>
            </>
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <div className="login-wrapper">
        <Row align="middle">
          <Col xs={24} lg={10} className="left-side-wrapper">
            <div className="login-logo">
              <a href="https://www.welcomy.co" target="_blank">
                <Logo id="big-logo" />
              </a>
            </div>
            <div className="form-wrapper">
              <div className="login-logo-small">
                <a href="https://www.welcomy.co" target="_blank">
                  <Logo id="small-logo" />
                </a>
              </div>
              {this.renderUrlPicker()}
            </div>
          </Col>
          <Col className="login-right" sm={0} lg={14}>
            <BackgroundImage
              vertical_id={this.props.companyConfig.vertical_id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export default withRouter(connect(mapStateToProps)(Login));

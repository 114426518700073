import amplitude from 'amplitude-js';


function getCookieDomain(){
  if (window.location.host.indexOf('localhost') >= 0){
    return 'localhost.com';
  }
  return 'welcomyapp.com';
}


/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: 'de',
    autoclear_cookies: true, // default: false
    page_scripts: false, // default: false
    cookie_domain: getCookieDomain(),
  
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
  
    onFirstAction: function (user_preferences, cookie) {
    },

    onAccept: function (cookie) {
      // ...
      if (cookie.categories.includes('Statistik')){
        amplitude.getInstance().init(
          '162b1660fde1a440c9382126643e79dd', null, {
          serverZone: 'EU',
          serverZoneBasedApi: true,
          deferInitialization: true,
          cookieExpiration: 7,
          storage: 'cookies',
          trackingOptions: {
              city: false,
              country: true,
              carrier: false,
              device_manufacturer: false,
              device_model: false,
              dma: false,
              ip_address: false,
              language: false,
              os_name: false,
              os_version: false,
              platform: false,
              region: false,
              version_name: false
          }
        });
        amplitude.getInstance().enableTracking();
      }
      else{
        amplitude.setOptOut(true);
      }
    },
  
    onChange: function (cookie, changed_preferences) {
      // ...
      window.CC.accept(cookie.categories);
      if (!cookie.categories.includes('Statistik')){
        amplitude.setOptOut(true);
      }
    },
  
    languages: {
      de: {
        consent_modal: {
          title: 'Cookie-Einwilligung',
          description:
            'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Statistik-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt werden. <button type="button" data-cc="c-settings" class="cc-link">Einstellungen</button>',
          primary_btn: {
            text: 'ALLE AKZEPTIEREN',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Ablehnen',
            role: 'accept_necessary', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie Settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
          ],
          blocks: [
            {
              title: 'Cookie usage 📢',
              description:
                'Wir verwenden Dienste von Drittanbietern, die Informationen im Endgerät eines Seitenbesuchers speichern oder dort abrufen. Anschließend verarbeiten wir die Informationen weiter. Dies alles hilft uns, unsere Website optimal zu gestalten und fortlaufend zu verbessern. Für die Speicherung, den Abruf und die Verarbeitung benötigen wir Ihre Einwilligung. Ihre Einwilligung. Sie können Ihre Einwilligung jederzeit in der Cookie-Einstellung widerrufen. Weitere Informationen finden Sie in unserer <a href="https://docs.google.com/gview?url=https://res.cloudinary.com/dwzihftxc/image/upload/v1689376660/data_protection/qc6g8j2adncdnvjirnsb.pdf&embedded=true" class="cc-link">privacy policy</a>.',
            },
            {
              title: 'Notwendig',
              description:
                'Erforderliche Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website notwendig. Sie dienen der sicheren und bestimmungsgemäßen Nutzung und sind daher nicht deaktivierbar.',
              toggle: {
                value: 'Notwendig',
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: 'Statistik',
              description:
                'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website verwenden. Sie speichern keine Informationen, die eine persönliche Identifikation des Besuchers erlauben. Die mit Hilfe dieser Cookies gesammelten Informationen sind aggregiert und somit anonym. Sie dienen dazu die Qualität unserer Webseite fortlaufend zu verbessern.',
              toggle: {
                value: 'Statistik', // your cookie category
                enabled: true,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: '^_amp', // match all cookies starting with "_ga"
                  col2: 'amplitude.com',
                  col3: '7 days',
                  is_regex: true,
                },
              ],
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://www.welcomy.co/contact">contact us</a>.',
            },
          ],
        },
      },
    },
  };
  
  export default pluginConfig;
  
import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 76.8" {...props}>
      <defs>
        <linearGradient
          id="prefix__a__icon"
          x1={10.07}
          y1={-6.94}
          x2={48.54}
          y2={106.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ffd301" />
          <stop offset={0.21} stopColor="#f4ad19" />
          <stop offset={0.43} stopColor="#e98930" />
          <stop offset={0.65} stopColor="#e17040" />
          <stop offset={0.84} stopColor="#dd604a" />
          <stop offset={1} stopColor="#db5b4d" />
        </linearGradient>
        <linearGradient
          id="prefix__b__icon"
          x1={99.77}
          y1={-11.18}
          x2={59.6}
          y2={89.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.11} stopColor="#d7e2f1" />
          <stop offset={0.25} stopColor="#adc3e1" />
          <stop offset={0.4} stopColor="#8aa9d5" />
          <stop offset={0.54} stopColor="#6f95cb" />
          <stop offset={0.69} stopColor="#5b87c4" />
          <stop offset={0.83} stopColor="#507fbf" />
          <stop offset={0.99} stopColor="#4c7cbe" />
        </linearGradient>
        <linearGradient
          id="prefix__c__icon"
          x1={52.13}
          y1={89.12}
          x2={51.82}
          y2={-18.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.05} stopColor="#fbe2e2" />
          <stop offset={0.13} stopColor="#f6b5b5" />
          <stop offset={0.22} stopColor="#f18d8d" />
          <stop offset={0.32} stopColor="#ed6c6b" />
          <stop offset={0.42} stopColor="#ea5050" />
          <stop offset={0.52} stopColor="#e73b3a" />
          <stop offset={0.64} stopColor="#e52c2b" />
          <stop offset={0.78} stopColor="#e42423" />
          <stop offset={1} stopColor="#e42120" />
        </linearGradient>
      </defs>
      <g data-name="Camada 2">
        <g data-name="Camada 1">
          <path
            d="M35.9 31.8a13.2 13.2 0 01.6 3.9 14 14 0 003.3 8.6.1.1 0 00.1.1L52 58.2l-9.3 10.4-3 3.5a14.1 14.1 0 01-8.9 4.6h-2.6a12.8 12.8 0 01-2.7-.5 15.1 15.1 0 01-3.5-1.4 15.6 15.6 0 01-5.1-4.8 11.7 11.7 0 01-1.8-3.9l-1.4-4.3-13-42.3A15 15 0 0110.6.7 13.5 13.5 0 0115 0a14.4 14.4 0 016.2 1.4l.8.3a15 15 0 017.4 8.9l6.3 20.5z"
            fill="url(#prefix__a__icon)"
          />
          <path
            d="M68 31.8a17.4 17.4 0 00-.5 3.9 14 14 0 01-3.3 8.6h-.1L52 58.2l9.2 10.4 3.1 3.5a13.8 13.8 0 008.8 4.6h2.6a12.6 12.6 0 002.8-.5 14.3 14.3 0 003.4-1.4 15.9 15.9 0 005.2-4.8 11.7 11.7 0 001.8-3.9l1.3-4.3 13.1-42.3A15.1 15.1 0 0093.4.7 13.9 13.9 0 0089 0a15.2 15.2 0 00-6.3 1.4l-.7.3a15 15 0 00-7.4 8.9l-6.4 20.5z"
            fill="url(#prefix__b__icon)"
          />
          <path
            d="M63.1 43.3l-.3.4L52 55.9 40.9 43.3h-.1a12.5 12.5 0 01-3-9.5 12.8 12.8 0 014.2-8l2-1.8a12.1 12.1 0 018-3 12.3 12.3 0 018 3l2 1.8a12.4 12.4 0 014 7.4 12.2 12.2 0 01-2.9 10.1z"
            fill="url(#prefix__c__icon)"
          />
          <circle cx={52} cy={36.8} r={2.1} fill="#fff" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { getIntl } from "../../utils/intl";
import { updateWorkspaceApi } from "../../api/workspacesApiService";
import { displayNotification } from "../../components/Notification";
import { useDispatch } from "react-redux";
import { updateWorkspace } from "../../redux/actions/companyConfig";

const UpdateWorkspaceModal = ({ workspace, onConfirm, onCancel }) => {
  const [form] = Form.useForm();
  const intl = getIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const successNotificationTitle = intl.formatMessage({
    id: "Success",
    defaultMessage: "Erfolg!",
  });
  const successNotificationBody = intl.formatMessage({
    id: "SubAccounts.Notification.Edit.Success",
    defaultMessage: "Kundenzugang erfolgreich aktualisiert.",
  });
  const errorNotificationTitle = intl.formatMessage({
    id: "SubAccounts.Notification.Edit.Error.Title",
    defaultMessage: "Kundenzugang konnte nicht aktualisiert werden.",
  });
  const errorNotificationBody = intl.formatMessage({
    id: "SubAccounts.Notification.Edit.Error.Body",
  });
  const editModalTitle = intl.formatMessage({
    id: "SubAccounts.Edit.Title",
  });
  const editInputName = intl.formatMessage({
    id: "SubAccounts.Edit.Name",
  });
  const editNamePlaceholder = intl.formatMessage({
    id: "SubAccounts.Edit.Name.Placeholder",
  });
  const mandatoryField = intl.formatMessage({
    id: "Field.Mandatory",
  });


  useEffect(() => {
    form.setFieldsValue(workspace);
    setIsModalVisible(!!workspace);
  }, [workspace]);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    onCancel();
  };

  const handleModalOk = () => {
    updateWorkspaceApi(workspace?.id, form.getFieldsValue())
      .then((res) => {
        dispatch(
          updateWorkspace({
            id: workspace?.id,
            updatedWorkspace: form.getFieldsValue(),
          }),
        );
        setIsModalVisible(false);
        form.resetFields();
        displayNotification(
          "success",
          successNotificationTitle,
          successNotificationBody,
        );
      })
      .catch((e) => {
        form.resetFields();
        console.error(e);
        displayNotification(
          "error",
          errorNotificationTitle,
          errorNotificationBody,
        );
      });
  };

  const validateMessages = {
    required: mandatoryField,
  }
  return (
    <>
      <Modal
        title={editModalTitle}
        okText="Update"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        visible={isModalVisible}
      >
        <Form form={form} layout="vertical" validateMessages={validateMessages}>
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={editInputName}
          >
            <Input placeholder={editNamePlaceholder}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateWorkspaceModal;

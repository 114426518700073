import React from "react";
// Main app styles
import "./App.scss";

import { createStore } from "redux";
import { Provider } from "react-redux";

import { Helmet } from "react-helmet";
import { Layout } from "antd";

import "vanilla-cookieconsent/dist/cookieconsent.css";

import allReducers from "./redux/reducers";

import Auth from "./components/Auth";
import SideMenu from "./components/SideMenu";
import LanguagePicker from "./components/LanguagePicker";
import ScrollListener from "./components/ScrollListener";
import CookieConsent from "./pages/Tablet/public/components/Consent/cookieConsent";

const { Header, Sider, Content } = Layout;

const store = createStore(
  allReducers,
);

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcomy</title>
        <link rel="canonical" href="https://welcomyapp.com" />
        <meta
          name="description"
          content="The User Friendly Visitor Management System"
        />
        <meta property="og:title" content="Welcomy" />
        <meta property="og:url" content="https://welcomyapp.com" />
        <meta
          property="og:description"
          content="The User Friendly Visitor Management System"
        />
        <meta
          property="og:image"
          content="https://www.welcomyapp.com/favicon.ico"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="de_DE" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@WelcomyApp" />
      </Helmet>

      <header className="App-header">
        <Provider store={store}>
          <LanguagePicker>
            <Auth>
              <Layout>
                <Sider className="sider" breakpoint="lg">
                  <SideMenu />
                </Sider>
                <Layout>
                  {/* 
                      Wrapper for main content.
                      This is needed to change the navbar color
                      on scroll.
                  */}
                  <ScrollListener />
                </Layout>
              </Layout>
            </Auth>
          </LanguagePicker>
        </Provider>
      </header>
      <CookieConsent></CookieConsent>
    </div>
  );
}

export default App;

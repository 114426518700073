const defaultState = {
    category: null,
};
const dishConfigReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_DISH':
            return {...state, category: action.dishConfig.category};

        default:
            return state;
    }
};

export default dishConfigReducer;
import React from 'react';
import {Col, Row} from "antd";
import LivePreview from "../../components/LivePreview/LivePreview";

const WelcomyHubPreview = ({children}) => {
    return (
        <div>
            <Row gutter={[32, 32]}>
                <Col span={16}>{children}</Col>
                <Col span={8}>
                    <LivePreview></LivePreview>
                </Col>
            </Row>
        </div>
    )
}

export default WelcomyHubPreview;

import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Table, Button } from "antd";
import { useSelector } from "react-redux";
import http from "../../utils/http";
import moment from "moment";
import SubscriptionsImage from "../../static/Page Headers/SubscriptionsImage";
import downloader from "../../components/Downloader";
import { getIntl } from "../../utils/intl";
import { displayNotification } from "../../components/Notification";

const { Title, Paragraph } = Typography;

function Subscriptions(props) {
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const intl = getIntl();

  const deleteError = intl.formatMessage({
    id: "General.ErrorNotification.Body",
    defaultMessage:
      "Etwas ist schief gelaufen! Wenn das Problem weiterhin besteht, bitte kontaktieren Sie unser freundliches Support-Team! :)",
  });

  const title = intl.formatMessage({
    id: "SubscriptionsPage.Title",
    defaultMessage: "Newsletter Manager",
  });

  const body = intl.formatMessage({
    id: "SubscriptionsPage.Body",
    defaultMessage:
      "Auf dieser Seite sehen Sie eine Übersicht der Personen, die Ihren Newsletter abonniert haben. Sie können den Namen und E-Mail-Adresse der Abonnenten in eine Excel-Datei exportieren, um sie für gezielte Werbung und Marketingkampagnen zu verwenden.",
  });

  const button = intl.formatMessage({
    id: "SubscriptionsPage.Button",
    defaultMessage: "Kontaktliste exportieren",
  });

  const fullname = intl.formatMessage({
    id: "SubscriptionsPage.FullName",
    defaultMessage: "Vollständiger Name",
  });

  const email = intl.formatMessage({
    id: "SubscriptionsPage.Email",
    defaultMessage: "E-mail",
  });

  const headerActions = intl.formatMessage({
    id: "VisitorLogPage.Table.Column.Actions",
    defaultMessage: "Aktionen",
  });

  const deleteBtn = intl.formatMessage({
    id: "Delete",
    defaultMessage: "Löschen",
  });

  const columns = [
    { title: fullname, dataIndex: "full_name", key: "full_name" },
    { title: email, dataIndex: "email", key: "email" },
    {
      title: headerActions,
      key: "actions",
      render: (_, record) => {
        return subscriptionsData.length >= 1 ? (
          <Button type="ghost" onClick={() => deleteSubscription(record)}>
            {deleteBtn}
          </Button>
        ) : null;
      },
    },
  ];

  const removeSubFromList = (id) => {
    const subs = subscriptionsData.filter(sub => sub.user_id !== id);
    setSubscriptionsData(subs);
  } 

  const deleteSubscription = async (record) => {
    try {
      const result = await http.delete(
        `gastro/${companyId}/subscription/${record.user_id}`
      );
      if (result.status === 204) {
        removeSubFromList(record.user_id);
      }
    } catch (error) {
      displayNotification("error", "", deleteError);
    }
  };

  useEffect(() => {
    const getSubs = async () => {
      const result = await http.get(`gastro/${companyId}/subscription`);
      setSubscriptionsData(result.data.payload);
    };
    getSubs();
  }, []);

  const exportCSV = () => {
    const header = `${fullname},${email},\n`;
    const body = subscriptionsData
      .map((sub) => `${sub.full_name},${sub.email},`)
      .join("\n");

    downloader(
      `${header}${body}`,
      `subscribers-list-${moment().local().format("YYYY-MM-DD")}`
    );
  };

  return (
    <div>
      <Row>
        <Col xs={0} lg={7}></Col>
        <Col xs={24} lg={10}>
          <div className="subscriptions-wrapper">
            <Row>
              <Col xs={24}>
                <div className="subscriptions-header-image m-b-24">
                  <SubscriptionsImage />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className="subscriptions-title">
                  <Title level={1}>{title}</Title>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className="subscriptions-subtitle">
                  <Paragraph>{body}</Paragraph>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Button
                  type="primary"
                  block
                  onClick={exportCSV}
                  className="m-b-12"
                >
                  {button}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className="subscriptions-table">
                  <Table columns={columns} dataSource={subscriptionsData} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} lg={7}></Col>
      </Row>
    </div>
  );
}

export default Subscriptions;

import React from "react";

export const AiChatIcon = () => (
  <svg
		style={{ height: "22px", width: "22px" }}
    version="1.1"
    id="Outline"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    enable-background="new 0 0 48 48"
  >
    <g>
      <path
        d="M33,28.00078v-17c0-2.20557-1.79443-4-4-4H9c-2.20557,0-4,1.79443-4,4v17c0,1.86035,1.27686,3.42822,3,3.87354
		c0,0,0,2.1153,0,2.12642c0,0.66996,0.68614,1.15688,1.31641,0.94877l0.87891-0.29297
		c1.41943-0.47314,2.60303-1.40527,3.38818-2.65576H29C31.20557,32.00078,33,30.20635,33,28.00078z M13,30.00078
		c-0.37891,0-0.7251,0.21387-0.89453,0.55273C11.65088,31.46318,10.91309,32.17217,10,32.5877v-1.58691c0-0.55225-0.44775-1-1-1
		c-1.10303,0-2-0.89697-2-2v-17c0-1.10303,0.89697-2,2-2h20c1.10303,0,2,0.89697,2,2v17c0,1.10303-0.89697,2-2,2H13z"
      />
      <path
        d="M39,13.00078h-3c-0.55225,0-1,0.44775-1,1s0.44775,1,1,1h3c1.10303,0,2,0.89697,2,2v17c0,1.10303-0.89697,2-2,2
		c-0.55225,0-1,0.44775-1,1v1.58789c-0.91455-0.41992-1.65234-1.12939-2.10547-2.03516
		C35.7251,36.21465,35.37891,36.00078,35,36.00078H19c-0.8501,0-1.60303-0.53174-1.87354-1.32324
		c-0.17871-0.52246-0.74756-0.80176-1.26953-0.62305c-0.52295,0.17871-0.80176,0.74707-0.62305,1.26953
		C15.78125,36.9251,17.29443,38.00078,19,38.00078h15.4165c0.78369,1.24512,1.96582,2.17822,3.39062,2.65967
		c0,0,0.87988,0.29004,0.88003,0.29009C39.32015,41.1594,40,40.66881,40,40.00078v-2.12646c1.72314-0.44531,3-2.01318,3-3.87354v-17
		C43,14.79522,41.20557,13.00078,39,13.00078z"
      />
      <path
        d="M17.43652,15.14971c-0.31827-0.84863-1.55537-0.84607-1.87305,0l-3,8c-0.19385,0.51709,0.06836,1.09326,0.58545,1.2876
		c0.51855,0.19287,1.09375-0.06787,1.2876-0.58545l0.39954-1.06543h3.32788c0,0,0.39807,1.06152,0.39953,1.06542
		c0.19415,0.51773,0.77396,0.77618,1.2876,0.58546c0.51709-0.19434,0.7793-0.77051,0.58545-1.2876
		C20.43652,23.14971,17.4439,15.16936,17.43652,15.14971z M15.586,20.78643l0.914-2.4375l0.914,2.4375H15.586z"
      />
      <circle cx="23.5" cy="15.50078" r="1" />
      <path
        d="M24.5,22.50078v-4c0-0.55225-0.44775-1-1-1h-1c-0.55225,0-1,0.44775-1,1s0.44775,1,1,1v4c0,0.55225,0.44775,1,1,1h1
		c0.55225,0,1-0.44775,1-1S25.05225,22.50078,24.5,22.50078z"
      />
      <path
        d="M29,14.00078c0-1.6543-1.3457-3-3-3H12c-1.6543,0-3,1.3457-3,3v11c0,1.6543,1.3457,3,3,3h14c1.6543,0,3-1.3457,3-3
		V14.00078z M27,25.00078c0,0.55127-0.44873,1-1,1H12c-0.55127,0-1-0.44873-1-1v-11c0-0.55127,0.44873-1,1-1h14
		c0.55127,0,1,0.44873,1,1V25.00078z"
      />
      <path d="M36,20.50078h2c0.55225,0,1-0.44775,1-1s-0.44775-1-1-1h-2c-0.55225,0-1,0.44775-1,1S35.44775,20.50078,36,20.50078z" />
      <path d="M36,24.50078h2c0.55225,0,1-0.44775,1-1s-0.44775-1-1-1h-2c-0.55225,0-1,0.44775-1,1S35.44775,24.50078,36,24.50078z" />
      <path d="M36,28.50078h2c0.55225,0,1-0.44775,1-1s-0.44775-1-1-1h-2c-0.55225,0-1,0.44775-1,1S35.44775,28.50078,36,28.50078z" />
      <path d="M36,32.50078h2c0.55225,0,1-0.44775,1-1s-0.44775-1-1-1h-2c-0.55225,0-1,0.44775-1,1S35.44775,32.50078,36,32.50078z" />
    </g>
  </svg>
);

import * as React from "react";


const FrFlag = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 512 512" {...props}>
    <path fill="#fff" d="M0 0h512v512H0z" />
    <path fill="#002654" d="M0 0h170.7v512H0z" />
    <path fill="#ce1126" d="M341.3 0H512v512H341.3z" />
  </svg>
)

export default FrFlag;

import React from "react";
import Certifications from "../Certifications";

function CoverLoading({ logo, ...props }) {
  return (
    <div className={`cover-loading`} >
      <div className="cover-loading__logo">
        <div className="cover-loading__wrapper">{logo}</div>
        <div className="cover-loading__icons">
          <Certifications />
        </div>
      </div>
    </div>
  );
}

export default CoverLoading;

import React, { useState } from "react";
import { Avatar, Table, Space, Button, Input, Col } from "antd";
import "./Workspaces.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteWorkspaceApi } from "../../api/workspacesApiService";
import UpdateWorkspaceModal from "./UpdateWorkspace";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { displayNotification } from "../../components/Notification";
import { deleteWorkspace } from "../../redux/actions/companyConfig";
import { getIntl } from "../../utils/intl";
import { getCookie } from "../../components/getCookie";
import { cookieDomain, isAllSuperAccount } from "../../utils/envs";

const Workspaces = () => {
  const workspaces = useSelector(
    (state) => state.companyConfig["sub_accounts"],
  );
  const [updateWorkspace, setUpdateWorkspace] = useState(null);
  const [deleteWorkspaceId, setDeleteWorkspaceId] = useState(null);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const filteredWorkspaces = workspaces?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.path.toLowerCase().includes(searchText.toLowerCase()),
  );
  const intl = getIntl();
  const superAccount = useSelector(
    (state) => state.companyConfig.super_account
  );
  const successNotificationTitle = intl.formatMessage({
    id: "Success",
    defaultMessage: "Erfolg!",
  });
  const successNotificationBody = intl.formatMessage({
    id: "SubAccounts.Notification.Delete.Success",
    defaultMessage: "Kundenzugang wurde erfolgreich gelöscht.",
  });
  const errorNotificationTitle = intl.formatMessage({
    id: "SubAccounts.Notification.Delete.Error.Title",
    defaultMessage: "Kundenzugang konnte nicht gelöscht werden.",
  });
  const errorNotificationBody = intl.formatMessage({
    id: "SubAccounts.Notification.Delete.Error.Body",
  });
  const searchPlaceholder = intl.formatMessage({
    id: "SubAccounts.Search.Placeholder",
    defaultMessage: "Suche nach Name oder URL"
  });
  const editButtonText = intl.formatMessage({
    id: "Edit",
  });
  const viewButtonText = intl.formatMessage({
    id: "View",
  });
  const deleteButtonText = intl.formatMessage({
    id: "Delete",
  });
  const confirmationDeleteTitle = intl.formatMessage({
    id: "SubAccounts.Delete.Title",
  });
  const confirmationDeleteMessage = intl.formatMessage({
    id: "SubAccounts.Delete.Message",
  });

  const columns = [
    {
      title: "Logo",
      dataIndex: "id",
      width: 50,
      maxWidth: 50,
      render: (_, workspace) => {return <Avatar shape="circle" src={workspace['hub_logo_url'] || workspace['logo_url']} />},
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
    },
    {
      title: "",
      key: "actions",
      render: (text, workspace) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleUpdateWorkspace(workspace)}>{editButtonText}</Button>
          <Button type="primary" onClick={() => handleGoToWorkspace(workspace)}>{viewButtonText}</Button>
          <Button onClick={() => handleDeleteWorkspace(workspace.id)} danger>
            {deleteButtonText}
          </Button>
        </Space>
      ),
    },
  ];

  const changeToAccount = (accounts) => {
    const domain = cookieDomain();
    const superName = getCookie("_super_name");
    const superId = getCookie("_super_c");
    const superVertical = getCookie("_super_v");
  
    document.cookie = `_c=${accounts[0].id};domain=${domain};path=/;`;
    document.cookie = `_v=${accounts[0].vertical};domain=${domain};path=/;`;
    if (isAllSuperAccount(superAccount)) {
      document.cookie = `_super_name=${superName};domain=${domain};path=/;`;
      /**
       * Subdomains cannot be multilevel in order for this to work (multiple dots).
       */
      document.cookie = `_super_path=${
        window.location.hostname.split(".")[0]
      };domain=${domain};path=/;`;
      document.cookie = `_super_c=${superId};domain=${domain};path=/;`;
      document.cookie = `_super_v=${superVertical};domain=${domain};path=/;`;
    } else {
      document.cookie = `_super_name=${superName};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      /**
       * Subdomains cannot be multilevel in order for this to work (multiple dots).
       */
      document.cookie = `_super_path=${
        window.location.hostname.split(".")[0]
      };domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
      document.cookie = `_super_c=${superId};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      document.cookie = `_super_v=${superVertical};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
    if (domain === ".localhost.com") {
      window.location = `http://${accounts[0].path}.localhost.com:3000/dashboard`;
    } else {
      window.location = `http://${accounts[0].path}.welcomyapp.com/dashboard`;
    }
  };

  const handleGoToWorkspace = (workspace) => {
    changeToAccount([
        {
          name: workspace['name'],
          id: workspace['id'],
          vertical: workspace['vertical'],
          path: workspace['path'],
        },
    ])
  };

  const handleUpdateWorkspace = (workspace) => {
    setUpdateWorkspace(workspace);
  };

  const handleDeleteWorkspace = (key) => {
    setConfirmationModalVisible(true);
    setDeleteWorkspaceId(key);
  };

  const handleUpdateModalConfirm = (e) => {
    setUpdateWorkspace(null);
  };

  const handleConfirm = () => {
    deleteWorkspaceApi(deleteWorkspaceId)
      .then(() => {
        setConfirmationModalVisible(false);
        dispatch(deleteWorkspace({ subAccountId: deleteWorkspaceId }));

        displayNotification(
          "success",
          successNotificationTitle,
          successNotificationBody,
        );
      })
      .catch((e) => {
        console.error(e);
        displayNotification(
          "error",
          errorNotificationTitle,
          errorNotificationBody,
        );
      });
  };

  const handleCancel = () => {
    setConfirmationModalVisible(false);
  };

  return (
    <>
      <div className="workspace-wrapper max-width-1200">
        <Col span={24}>
          <div className="workspaces-container">
            <Input.Search
              className="workspace-search"
              placeholder={searchPlaceholder}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Table
              dataSource={filteredWorkspaces}
              columns={columns}
              pagination={false}
            />
            <ConfirmationModal
              visible={confirmationModalVisible}
              title={confirmationDeleteTitle}
              message={confirmationDeleteMessage}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
            <UpdateWorkspaceModal
              workspace={updateWorkspace}
              onConfirm={(e) => handleUpdateModalConfirm(e)}
              onCancel={() => setUpdateWorkspace(null)}
            />
          </div>
        </Col>
      </div>
    </>
  );
};

export default Workspaces;


import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    deleteError: {
        id: "WelcomyHub.Delete.Error",
        defaultMessage: "Der Link konnte nicht gelöscht werden",
    },
    errorTitle: {
        id: "Error",
        defaultMessage: "Etwas ist schief gelaufen!",
    },
});

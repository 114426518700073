
import { defineMessages } from 'react-intl';

export const qrModalStrings = defineMessages({
  title: {
    id: "QrDownload.Modal.Title",
    defaultMessage: "Wähle Sie Ihr gewünschtes Dateiformat",
  },
  disclaimer: {
    id: "QrDownload.Modal.Subtitle",
    defaultMessage: "Laden Sie eine PNG-Datei mit transparentem Hintergrund herunter, wenn Sie diese eigenständig in Ihre Projekte implementieren möchten, oder entscheiden Sie sich für den Download einer Welcomy Karte, welche den QR-Code, Kurz-URL und Logo Ihrer Hub in einem eleganten Design vereint.",
  },
  pngTitle: {
    id: "QrDownload.Modal.PNG.Title",
    defaultMessage: "QR-Code",
  },
  pngSubtitle: {
    id: "QrDownload.Modal.PNG.Subtitle",
    defaultMessage: "Datei mit transparentem Hintergrund im PNG-Format. Implementieren Sie diesen in Ihre Projekte und Designideen.",
  },
  cardLightTitle: {
    id: "QrDownload.Modal.CardLight.Title",
    defaultMessage: "Welcomy Karte - hell",
  },
  cardLightSubtitle: {
    id: "QrDownload.Modal.CardLight.Subtitle",
    defaultMessage: "Eine virtuelle Visitenkarte, die Sie als Hintergrundbild für den Sperrbildschirm Ihres Telefons verwenden können.",
  },
  cardDarkTitle: {
    id: "QrDownload.Modal.CardDark.Title",
    defaultMessage: "Welcomy Karte - dunkel",
  },
  cardDarkSubtitle: {
    id: "QrDownload.Modal.CardDark.Subtitle",
    defaultMessage: "Eine virtuelle Visitenkarte, die Sie als Hintergrundbild für den Sperrbildschirm Ihres Telefons verwenden können.",
  },
  cancel: {
    id: "Cancel",
    defaultMessage: "Abbrechen",
  },
});

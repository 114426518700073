import http from "../utils/http";

export const getWorkspacesApi = (companyId) => {
  return http.get(`/company/${companyId}/account`);
};

export const deleteWorkspaceApi = (subAccountId) => {
  return http.delete(`/company/${subAccountId}/account`);
};

export const updateWorkspaceApi = (subAccountId, updatedWorkspace) => {
  return http.put(`/company/${subAccountId}/account`, {
    ...updatedWorkspace,
  });
};

export const createWorkspaceApi = (companyId, body) => {
  const { name, path } = body;
  return http.post(`/company/${companyId}/account`, {
    name,
    path: path.toLowerCase(),
  });
};

import { defineMessages } from "react-intl";

export const PasswordResetTranslations = defineMessages({
  passwordResetSuccess: {
    id: "PasswordReset.Success.Message",
    defaultMessage: "Sie haben Ihr Passwort erfolgreich geändert!",
  },
  forgotPassword: {
    id: "PasswordReset.Forgot.Text",
    defaultMessage: "Passwort vergessen?",
  },
  subtitle: {
    id: "PasswordReset.Sucess.Subtitle",
    defaultMessage: "Bitte melden Sie sich erneut an.",
  },
  goToLogin: {
    id: "PasswordReset.Success.Button",
    defaultMessage: "Zur Anmeldeseite gehen",
  },
  passwordResetGenericError: {
    id: "PasswordReset.Error.Message",
    defaultMessage:
      "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es erneut oder kontaktieren Sie den Welcomy Kundensupport",
  },
  passwordResetLengthError: {
    id: "PasswordReset.Form.Action.Password.Error",
    defaultMessage: "Sie müssen zwischen 8 und 36 Zeichen verwenden.",
  },
  tokenError: {
    id: "PasswordReset.Form.Action.Token.Error",
    defaultMessage:
      "Ihr Token is ungültig, versuchen Sie es sp6ater noch einmal oder wenden Sie sich an den Welcomy Support.",
  },
  title: {
    id: "PasswordReset.Form.Title",
    defaultMessage: "Geben Sie die E-Mail-Adresse Ihres Welcomy-Kontos an.",
  },
  submitError: {
    id: "PasswordReset.Form.Action.Email.Error",
    defaultMessage:
      "Die angegebenen E-Mail-Adresse konnte keinem Welcomy-Konto zugeordnet werden.",
  },
  submitSuccess: {
    id: "PasswordReset.Form.Action.Email.Success",
    defaultMessage:
      "Wir haben Ihnen eine E-Mail zum Zurücksetzen des Passworts gesendet.",
  },
  emailPlaceholder: {
    id: "PasswordReset.Form.Action.Email.Text",
    defaultMessage: "E-mail-Adresse",
  },
  inputNewPassword: {
    id: "PasswordReset.Form.Input.NewPassword",
    defaultMessage: "Neues Passwort",
  },
  inputRepeatPassword: {
    id: "PasswordReset.Form.Input.RepeatNewPassword",
    defaultMessage: "Passwort wiederholen",
  },
  passwordMatchError: {
    id: "PasswordReset.Form.Error.Match",
    defaultMessage: "Passwörter sind nicht übereinstimmend.",
  },
  saveButton: {
    id: "PasswordReset.Form.Save",
    defaultMessage: "Neues Passwort speichern",
  },
  pageTitle: {
    id: "PasswordReset.Page.Title",
    defaultMessage: "Passwort zurücksetzen",
  },
});

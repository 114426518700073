import {DeleteOutlined, EditOutlined, MenuOutlined} from "@ant-design/icons";
import {Button, Card} from "antd";
import React from "react";
import Meta from "antd/es/card/Meta";
import {deleteLinkManagement} from "../../api/linkManagerManagementService";
import {deleteCompanyLink} from "../../redux/actions/companyConfig";
import {displayNotification} from "../Notification";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {CardTagInVCard, CardTagLinkType, CardTagVisible} from "../../pages/WelcomyHub/cards/CardTags";
import { messages } from "./Translations";

const LinkCard = ({ link, showAddNewLink, editCard, onChange }) => {
    const companyId = useSelector((state) => state.companyConfig.company_id);
    const dispatch = useDispatch();
    const translation = useIntl()

    const editLinkCard = (linkId) => {
        onChange(linkId)
    }
    const deleteCard = (linkId) => {
        return deleteLinkManagement(companyId, linkId)
            .then(() => {
                dispatch(deleteCompanyLink(linkId));
                displayNotification(
                    "success",
                    'Removed',
                    'Link is deleted successfully'
                );
            }).catch((error) => {
                displayNotification(
                    "error",
                    translation.formatMessage(messages.errorTitle),
                    translation.formatMessage(messages.deleteError)
                );
                console.log("Unable to delete link:", error);
            });
    }

    const CardMeta = ({hubLink}) => (
        <div>
            <div className="m-b-12">
                <span>{hubLink.link}</span><br/>
            </div>
            <div>
                <CardTagVisible visible={hubLink.visible} />
                <CardTagLinkType type={hubLink.type} />
                <CardTagInVCard inVCard={hubLink.to_vcard} />
            </div>
        </div>
    )

    return (
        <Card key={`${link.id}`} hoverable className={`card m-b-8 ${showAddNewLink ? 'disable' : ''}`}>
            <Meta
                title={link.name || link.link}
                description={<CardMeta hubLink={link} />}
                className="meta"
            />
            <div className="card-dragger-wrapper">
                <div className="card-dragger">
                    <MenuOutlined key="setting" style={{ color: '#212529' }} />
                </div>
            </div>
            <div className="card-actions">
                <div className="card-action-button">
                    <Button type="text" onClick={() => deleteCard(link.id)}>
                        <DeleteOutlined key="setting"/>
                    </Button>
                </div>
                <div className="card-action-button">
                    <Button type="text" disabled={editCard !== null && link.id !== editCard} onClick={() => editLinkCard(link?.id)}>
                        <EditOutlined key="edit"/>
                    </Button>
                </div>
            </div>

        </Card>
    )
}

export default LinkCard;
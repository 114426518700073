export const QR_CODE_EXPORT_SELECTION = 'QR_CODE_EXPORT_SELECTION';
export const QR_CODE_EXPORT_TRIGGER = 'QR_CODE_EXPORT_TRIGGER';
export const QR_CODE_MODAL_OPEN = 'QR_CODE_MODAL_OPEN';

export const selectQrExportType = (selectedQrExportType) => {
  return {
    type: QR_CODE_EXPORT_SELECTION,
    payload: selectedQrExportType,
  };
};

export const qrCodeExportTrigger = (shouldTrigger) => {
  return {
    type: QR_CODE_EXPORT_TRIGGER,
    payload: shouldTrigger,
  };
};

export const qrCodeExportModalOpen = (isOpen) => {
  return {
    type: QR_CODE_MODAL_OPEN,
    payload: isOpen,
  };
};

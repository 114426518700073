import QRcode from "qrcode.react";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qrCodeExportTrigger } from "../../redux/actions/qrCodeExport";
import { newDimensions } from "../QrCode";
import { QR_CODE_PNG_TRANSPARENT_BG } from "./QRCodePicker";

import {
  CARDS_BGS,
  downloadPngQRCode,
  downloadVCardQrCode,
  qrCodeVCardRender,
} from "./qr-code/QrCodeUtils";

const MINI_CANVAS_SIZE = { x: 150, y: 200 };
const MINI_CANVAS_BOUNDRIES = { x: 145, y: 195 };

const useCanvas = (callback) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    callback([canvas, ctx]);
  }, []);

  return canvasRef;
};

const QrTemplate = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const downloadTrigger = useSelector(
    (state) => state.qrCodeExportConfig.qrCodeExportTrigger
  );
  const selectedQrType = useSelector(
    (state) => state.qrCodeExportConfig.selectedQrExportType
  );
  const shortLink = useSelector((state) => state.companyConfig.short_link);
  const companyLogo = useSelector((state) => state.hubConfig.company_logo);
  const hubLogo = useSelector((state) => state.hubConfig.hubLogo);

  const logo = hubLogo || companyLogo;

  const canvasRef = useCanvas(([canvas, ctx]) => {
    if (Object.keys(CARDS_BGS).indexOf(type) > -1) {
      qrCodeVCardRender(
        ctx,
        canvas,
        type,
        logo,
        shortLink,
        MINI_CANVAS_BOUNDRIES
      );
    } else {
      const canvasQr = document.getElementById("qr-code-to-pdf");
      const [qrWidth, qrHeight] = newDimensions(400, 400, 80, 80);
      const qrX = (MINI_CANVAS_SIZE.x - qrWidth) / 2;
      const qrY = (MINI_CANVAS_SIZE.y - qrHeight) / 2;
      ctx.drawImage(canvasQr, qrX, qrY, qrWidth, qrHeight);
    }
  });

  useEffect(() => {
    if (downloadTrigger && selectedQrType === type) {
      downloadQr();
    }
  }, [downloadTrigger]);

  const downloadQr = () => {
    if (selectedQrType === QR_CODE_PNG_TRANSPARENT_BG) {
      downloadPngQRCode(() => {
        dispatch(qrCodeExportTrigger(false));
      });
    }

    if (Object.keys(CARDS_BGS).indexOf(selectedQrType) > -1) {
      downloadVCardQrCode(selectedQrType, logo, shortLink, () => {
        dispatch(qrCodeExportTrigger(false));
      });
    }
  };

  if (type) {
    return (
      <div className="qr-template__wrapper">
        {type !== QR_CODE_PNG_TRANSPARENT_BG && (
          <QRcode
            style={{ display: "none" }}
            size={400}
            id={`qr-template-${type}`}
            value={`https://${window.location.host}/public/welcome/form`}
            fgColor={CARDS_BGS[type].fg}
            bgColor={CARDS_BGS[type].bg}
          />
        )}
        <canvas
          ref={canvasRef}
          width={MINI_CANVAS_SIZE.x}
          height={MINI_CANVAS_SIZE.y}
          id={`canvas-qr-${type}`}
        />
      </div>
    );
  }

  return null;
};

export default QrTemplate;

import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getIntl } from "../../utils/intl";
import { LanguagePickerConsumer } from '../../components/LanguagePicker.jsx';


import { 
    Button,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Row,
    Typography,
} from 'antd';

import { GlobalOutlined } from '@ant-design/icons'

import http from '../../utils/http';
import WelcomyLogo from '../../static/Logo';

const { Title, Paragraph } = Typography

function ReportEmergency(props) {
  const [hasCookie, setHasCookie] = useState(false);
  const companyConfig = useSelector((state) => state.companyConfig)
  const intl = getIntl();

  const languages = (
    <LanguagePickerConsumer>
      {({ switchToEnglish, switchToGerman }) => (
        <Menu theme="light">
          <Menu.Item>
            <a target="_blank" onClick={switchToGerman}>Deutsch</a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" onClick={switchToEnglish}>English</a>
          </Menu.Item>
        </Menu>
      )}
    </LanguagePickerConsumer>
  );

  useEffect(() => {
    assignPublicCookie();
  }, [companyConfig]);

  const assignPublicCookie = async () => {
    const { company_id } = companyConfig;
    if (company_id) {
      const response = await http.get(`/company/${company_id}/assign_token`);
      if (response.status === 201) {
        setHasCookie(true);
      }
    }
  }

    const onFinish = async (values) => {
      const { email, date, agreement } = values;

      if (agreement) {
        try {
          const response = await http.post('/emergencies', {
            email,
            event_date: date.utc().format('YYYY-MM-DD'),
          });
  
          if (response.status === 204) {
            message.success('Your emergency notification was received.');
          } else {
            message.error('Something went wrong, please try again later or contact Welcomy directly.');
          }
        } catch (error) {
          message.error('Something went wrong, please try again later or contact Welcomy directly.');
        }
      }
    };
    const disabledDate = (current) => {
      // Can not select days after today
      return current && current > moment().local().endOf("day");
    };

    const dateOftest = intl.formatMessage(
      {
        id: "Corona.DateTest",
        defaultMessage: "Datum des positiven Testergebnisses"
      }
    );

    const pickerPlaceholder = intl.formatMessage(
      {
        id: "SelectDate",
        defaultMessage: "Wählen Sie ein Datum",
      }
    );

    const agreementRequired = intl.formatMessage(
      {
        id: "Corona.Field.Agreement.Mandatory",
        defaultMessage: "Wir benötigen Ihre Zustimmung"
      }
    );

    const emailInvalid = intl.formatMessage(
      {
        id: "Corona.Field.Email.Invalid",
        defaultMessage: "'${label} ist keine valide E-mails",
      }
    );

    const dateInvalid = intl.formatMessage(
      {
        id: "Corona.Field.Date.Invalid",
        defaultMessage: "'${label}' ist keine valide Datumsangabe",
      }
    );

    const fieldRequired = intl.formatMessage(
      {
        id: "Field.Mandatory",
        defaultMessage: "'${label}' ist ein Pflichtfeld",
      }
    );

    const validateMessages = {
      required: fieldRequired,
      types: {
        email: emailInvalid,
        date: dateInvalid,
      },
    };


    return (
      <div className="emergency-wrapper p-t-32">
        <Row justify="center">
          <Col xs={24} md={8}>
            <Row justify="center" align="middle">
              <Col xs={20}>
                <WelcomyLogo className="logo" />
              </Col>
              <Col xs={4} className="p-t-6">
                <Dropdown className="language-selector" overlay={languages} onClick={e => e.preventDefault()} trigger="click">
                  <GlobalOutlined type="global" theme="outlined" />
                </Dropdown>
              </Col>
            </Row>
            <Row justify="left" className="p-t-24">
              <Col>
                <Title level={2}>
                  <FormattedMessage id="Corona.Title"
                    defaultMessage="COVID-19 Infektionsmeldung">
                  </FormattedMessage>
                </Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph>
                  <FormattedMessage id="Corona.Disclaimer1"
                    defaultMessage="Durch das Erstellen einer neuen COVID-19 Infektionsmeldung werden die relevanten Parteien im Welcomy-Netzwerk über Ihren Fall informiert. Unser Team wird Ihre Meldung verarbeiten und sich gegebenenfalls an Sie wenden, um Ihren Infektionsfall zu verifizieren. Dieser Prozess hilft uns dabei, Kontaktpersonen bestmöglich nachverfolgen zu können und die Infektionsketten durch unsere Welcomy-Benachrichtigungsfunktion schnellstmöglich zu unterbrechen.">
                  </FormattedMessage>
                </Paragraph>
                <Paragraph>
                  <FormattedMessage id="Corona.Disclaimer2"
                    defaultMessage="Danke für Ihren Beitrag im Kampf gegen Corona.">
                  </FormattedMessage>
                </Paragraph>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24}>
                <Form
                  name="emergency-report"
                  layout="vertical"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { 
                        type: 'email',
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="date"
                    label={dateOftest}
                    rules={[
                      { 
                        type: 'date',
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{minWidth: "60%"}}
                      disabledDate={disabledDate}
                      placeholder={pickerPlaceholder}
                    />
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error(agreementRequired)),
                      },
                    ]}
                  >
                    <Checkbox>
                      <FormattedMessage
                        id="Corona.AcceptAgreement"
                        defaultMessage="Ich habe die {agb} gelesen"
                        values={{agreement: <a>agreement</a>, agb: <a href={`/public/welcome/privacy?doc=Nutzungbedingungen`} target="_blank">AGBs</a>}}
                      />
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" block htmlType="submit" size="large">
                      <FormattedMessage
                        id="Submit"
                        defaultMessage="Abschicken"
                      />
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
}

export default ReportEmergency;
import * as React from "react";


const ItFlag = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-it" viewBox="0 0 512 512" {...props}>
    <path fill="#fff" d="M0 0h512v512H0z" />
    <path fill="#009246" d="M0 0h170.7v512H0z" />
    <path fill="#ce2b37" d="M341.3 0H512v512H341.3z" />
  </svg>
)

export default ItFlag;

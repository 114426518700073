import React from 'react';
import { Modal } from 'antd';

function ModalWrapper({
    title,
    visible,
    onOk,
    onCancel,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
    confirmLoading,
    bodyStyle,
    width,
    ...props
}) {
    return (
        <div>
            <Modal
                title={title}
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading || false}
                onCancel={onCancel}
                cancelText={cancelText || "Cancel"}
                okText={okText || "Ok"}
                okButtonProps={okButtonProps || {}}
                cancelButtonProps={cancelButtonProps || {}}
                bodyStyle={bodyStyle}
                width={width}
                {...props}
            >
                { props.children }
            </Modal>
        </div>
    );
}

export default ModalWrapper;
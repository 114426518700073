import React from "react";
import { Button, Form, Input, Modal, Select, Typography } from "antd";
import { getIntl } from "../../utils/intl";
import { useDispatch, useSelector } from "react-redux";
import { inviteUserApi } from "../../api/userManagementApiService";
import { displayNotification } from "../Notification";
import { addUser } from "../../redux/actions/usersActions";

const InviteUserModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const intl = getIntl();
  const companyId = useSelector((state) => state.companyConfig.company_id);

  const inviteUserText = intl.formatMessage({
    id: "UserManagement.InviteUser.Button.Text",
    defaultMessage: "Benutzer einladen"
  });
  const userRoleText = intl.formatMessage({
    id: "UserManagement.InviteUser.Form.UserRole.Text",
    defaultMessage: "Benutzer-Rolle"
  });


  const validateEmail = (rule, value) => {
    if (!value || /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid email format");
  };
  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  const inviteUser = () => {
    const inviteUserData = form.getFieldsValue();
    inviteUserApi(companyId, inviteUserData)
      .then((res) => {
        dispatch(addUser(inviteUserData));
        displayNotification(
          "success",
          successNotificationTitle,
          "User is successfully invited",
        );
        onCancel();
      })
      .catch((error) => {
        console.log(error);
        displayNotification("error", "Error", "Failed to invite User");
      });
  };

  const successNotificationTitle = intl.formatMessage({
    id: "Success",
    defaultMessage: "Erfolg!",
  });

  return (
    <Modal
      visible={visible}
      title={inviteUserText}
      onCancel={handleOnCancel}
      footer={[
        <Button key="cancel" onClick={handleOnCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={inviteUser}>
          {inviteUserText}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={inviteUser}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter email" },
            { validator: validateEmail },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          name="role"
          label={userRoleText}
          rules={[{ required: true, message: "Please select a user role" }]}
        >
          <Select placeholder="Select a role">
            <Option value="admin">Admin</Option>
            <Option value="employee">Employee</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;

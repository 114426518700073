import { Button, Col, Empty, Row, Tooltip, Typography,  } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import http from "../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { getIntl } from "../../utils/intl";
import { displayNotification } from "../../components/Notification";
import {
  addLivePreviewLink,
  setLivePreviewLinks,
} from "../../redux/actions/livePreviewActions";
import { getLinks } from "../../api/linkManagerApiService";
import NewLink from "../../components/NewLink/NewLink";
import LinkCard from "../../components/linkCard/LinkCard";

const { Title, Paragraph } = Typography;

const WelcomyHub = (props) => {
  const intl = getIntl();

  const clickPreventionText = intl.formatMessage({
    id: "WelcomyHub.click.prevention",
    defaultMessage: "Beenden Sie Ihre aktuelle Arbeit, bevor Sie fortfahren.",
  });
  const addButton = intl.formatMessage({
    id: "WelcomyHub.Link.Add",
    defaultMessage: "Neuen Link hinzufügen",
  });

  const title = intl.formatMessage({
    id: "WelcomyHub.Title",
    defaultMessage: "Link Manager: Anpassungen an Ihrer Welcomy Hub",
  });

  const noLinksText = intl.formatMessage({
    id: "WelcomyHub.NoLinks",
    defaultMessage: "Beginnen Sie mit der Erstellung neuer Links!✨",
  });

  const [links, setLinks] = useState([]);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const [showAddNewLink, setShowAddNewLink] = useState(false);
  const [showEmptyPlaceholder, setshowEmptyPlaceholder] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const dispatch = useDispatch();
  let observer = null;
  if (links !== null) {
    observer = links;
  }

  const fetchLinks = () => {
    getLinks(companyId)
      .then((response) => {
        const payload = response.data.payload;
        if (payload.length > 0) {
          payload.forEach((link) => {
            if (link["type"] == "email") {
              const emailParts = link["link"].split("?subject=");
              if (emailParts.length > 1) {
                link["subject"] = emailParts[1];
                link["link"] = emailParts[0];
              }
            }
          });
          setLinks(response.data.payload);
          const previewLinks = response.data.payload.filter((link) => link.visible === true);
          dispatch(setLivePreviewLinks(previewLinks));
        } else {
          setshowEmptyPlaceholder(true)
        }
      })
      .catch((error) => {
        console.log("Unable to fetch links list.", error);
      });
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  const DragableWrapper = ({ index, moveCard, categoryIndex, children }) => {
    const ref = useRef();

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: "DragableBodyRow",
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveCard(item.index, index);
      },
    });

    const [, drag] = useDrag({
      type: "DragableBodyRow",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    if (!(editCard || showAddNewLink)) {
      drop(drag(ref));
    }

    return (
      <div ref={ref} style={{ cursor: "move" }}>
        {children}
      </div>
    );
  };

  const saveLinks = async (_links = links) => {
    try {
      return await http.post(`/company/${companyId}/link`, {
        links: _links.map((link) => {
          delete link.id;
          return link;
        }),
      });
    } catch (error) {
      console.log("Unable to save links", error);
    }
  };

  const moveCard = useCallback(
    async (dragIndex, hoverIndex) => {
      const dragRow = links[dragIndex];

      const result = update(links, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      await setLinks(result);
      result.map((link) => {
        if (link["type"] == "email"){
          const subject = link["subject"] || "";
          link["link"] = `${link["link"]}?subject=${subject}`;
          delete link["subject"]; 
        }
      });
      await saveLinks(result)
        .then((response) => {
          /**
           * @TODO: Debounce requests
           *
           * The sucess feedback for the reordering operation should be the
           * the new position of the element in the UI with no errors.
           *
           * If we really want a feedback operation, e.g. notification, we will
           * require to debouce this operation in order not to spam the user
           * (as we hit the API after every change).
           *
           */
          const previewLinks = response.data.payload.filter((link) => link.visible === true);
          dispatch(setLivePreviewLinks(previewLinks));
          setLinks([...response.data.payload]);
        })
        .catch((error) => {
          const errorTitle = intl.formatMessage({
            id: "Error",
            defaultMessage: "Etwas ist schief gelaufen!",
          });
          const reorderError = intl.formatMessage({
            id: "WelcomyHub.Save.Order.Error",
            defaultMessage: "Die Änderung ist fehlgeschlagen",
          });
          displayNotification("error", errorTitle, reorderError);
          console.log(error);
        });
      /**
       * UPDATE BACKEND WITH NEW ORDER
       */
    },
    [observer],
  );

  const newLinkChange = () => {
    setShowAddNewLink(false);
    setshowEmptyPlaceholder(true);
    setEditCard(null);
  };

  const linkCardChange = (linkId) => {
    setEditCard(linkId);
  };

  const Cards = () => {
    // Add a link type here to avoid rendering it.
    const EXCLUSION_LINKS = ['registration'];
    return (
      <>
        {!!links.length &&
          links.filter(aLink => EXCLUSION_LINKS.indexOf(aLink.type) === -1).map((aLink, index) => {
            return (
              <DragableWrapper key={index} moveCard={moveCard} index={index}>
                <Tooltip
                  placement="right"
                  title={clickPreventionText}
                  overlayStyle={{ width: 200 }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  visible={popoverIndex === index}
                >
                  <LinkCard
                    key={aLink.id}
                    link={aLink}
                    showAddNewLink={showAddNewLink}
                    editCard={editCard}
                    onChange={linkCardChange}
                  ></LinkCard>
                  {aLink.id === editCard && (
                    <NewLink
                      existingLinks={links}
                      companyId={companyId}
                      updateLinkId={aLink.id}
                      disableEdit={aLink.id !== editCard}
                      onChange={newLinkChange}
                    />
                  )}
                </Tooltip>
              </DragableWrapper>
            );
          })}
      </>
    );
  };

  const addNewLink = () => {
    setShowAddNewLink(true);
    setshowEmptyPlaceholder(false);
    dispatch(addLivePreviewLink({ id: "TEMP_LINK" }));
  };

  return (
    <div className="welcomyhub-wrapper">
      <Row>
        <Col xs={24} lg={24}>
          <Title key="1" level={1}>
            {title}
          </Title>
          <Tooltip
            placement="right"
            title={clickPreventionText}
            overlayStyle={{ width: 200 }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            visible={popoverIndex === -1}
          >
            <Button
              type="primary"
              block
              className="add-button m-b-12"
              disabled={editCard || showAddNewLink}
              onClick={addNewLink}
              icon={<PlusOutlined/>}
            >
              {addButton}
            </Button>
            {showAddNewLink && (
              <NewLink
                companyId={companyId}
                existingLinks={links}
                onChange={newLinkChange}
              />
            )}
          </Tooltip>
          { links.length === 0 && showEmptyPlaceholder && (
            <div style={{
              height: "50dvh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Empty
              description={
                <span>
                  {noLinksText}
                </span>
              }
            /></div>
          )}
          {!!links.length && (
            <DndProvider backend={HTML5Backend}>
              <Cards />
            </DndProvider>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(WelcomyHub);

import {
  CREATE_WORKSPACE,
  DELETE_LINK,
  DELETE_WORKSPACE,
  SET_LINKS,
  UPDATE_LINK,
  UPDATE_WORKSPACE,
} from "../actions/companyConfig";

const defaultState = {
  hasFetchedConfig: false,
  sub_accounts: [],
  super_account: null,
};

const companyConfigReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, ...action.companyConfig };
    case SET_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case DELETE_LINK:
      return {
        ...state,
        links: state?.links.filter((link) => link.id !== action.payload.linkId),
      };
    case UPDATE_LINK:
      const updatedPreviewLinks = state.links.map((link) =>
        link.id === action.payload.id
          ? { ...link, ...action.payload.updatedLink }
          : link,
      );

      return {
        ...state,
        links: [...updatedPreviewLinks],
      };
    case CREATE_WORKSPACE:
      return {
        ...state,
        sub_accounts: [action.payload.workspace, ...state.sub_accounts],
      };
    case UPDATE_WORKSPACE:
      const updatedWorkspaces = state.sub_accounts.map((w) =>
        w.id === action.payload.id
          ? { ...w, ...action.payload.updatedWorkspace }
          : w,
      );
      return {
        ...state,
        sub_accounts: updatedWorkspaces,
      };
    case DELETE_WORKSPACE:
      return {
        ...state,
        sub_accounts: state?.sub_accounts.filter(
          (s) => s.id !== action.payload.subAccountId,
        ),
      };
    default:
      return state;
  }
};

export default companyConfigReducer;

const defaultState = {
  impressumEditor: {
    shouldSave: false,
  },
};
const editorsReducer = (state = defaultState, action) => {
  switch (action.type) {
      case 'SET_IMPRESSUM_SHOULDSAVE':
          const aux = {...state};
          aux.impressumEditor.shouldSave = action.impressumEditor.shouldSave;
          return {...aux};

      default:
          return state;
  }
};

export default editorsReducer;
import { Tag } from "antd";
import React from "react";
import { getIntl } from "../../../utils/intl";

const LinkType = ({ type }) => {
  const intl = getIntl();
  const linkCall = intl.formatMessage({
    id: "WelcomyHub.Link.Call",
    defaultMessage: "Telefonanruf",
  });
  const UploadText = intl.formatMessage({
    id: "Upload",
    defaultMessage: "Datei hochladen",
  });

  const linkTypeMapping = {
    link: "Link",
    file: "Cloud-Link",
    upload: UploadText,
    software: "Software-Tool",
    newsletter: "Newsletter",
    email: "E-mail",
    phone: linkCall,
    linkedin: "LinkedIn",
    whatsapp: "Whatsapp",
    instagram: "Instagram",
    twitter: "Twitter",
    facebook: "Facebook",
    impressum: "Impressum",
    menu: "Menu",
    registration: "Questionnaire",
    product_and_services: "Product Page"
  };

  return <span>{linkTypeMapping[type]}</span>;
};

export const CardTagVisible = ({ visible, ...props }) => {
  if (typeof visible === "undefined") return null;

  if (visible) return <Tag color="geekblue">Visible</Tag>;
  return <Tag color="red">Hidden</Tag>;
};

export const CardTagInVCard = ({ inVCard, ...props }) => {
  if (!inVCard) return null;

  const intl = getIntl();
  const vCardText = intl.formatMessage({
    id: "WelcomyHub.Tag.VirtualCard.Text",
    defaultMessage: "Digitale Business-Karte",
  });

  return <Tag color="green">{vCardText}</Tag>;
};

export const CardTagLinkType = ({ type, ...props }) => {
  if (!type) return null;

  const intl = getIntl();
  const linkVariationText = intl.formatMessage({
    id: "WelcomyHub.Link.Type",
    defaultMessage: "Art des Links",
  });

  return (
    <Tag color="orange">
      <span>{linkVariationText}: </span><b><u><LinkType type={type} /></u></b>
    </Tag>
  );
};

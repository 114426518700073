import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import http from '../../../../../utils/http';
import { Divider, Typography } from "antd";
import { getIntl } from '../../../../../utils/intl';


const { Paragraph } = Typography;

const Disclaimer = ({isPreview = false}) => {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const [impressum, setImpressum] = useState('');
  const [dataProtection, setDataProtection] = useState('');

  const getFirstLoadMetadata = async () => {
    const result = await http.get(`gastro/${companyId}/metadata`);
    setImpressum(result?.data?.payload?.impressum);
    setDataProtection(result?.data?.payload?.datenschutz);
  }
  
  useEffect(() => {
    getFirstLoadMetadata();
  }, []);

  /**
   * BEGIN TRANSLATIONS
   */

  const intl = getIntl();

  const footerBegin = intl.formatMessage(
    {
      id: "QuestionFlow.Disclaimer.Text",
      defaultMessage: "Durch Nutzung der Registrierungssoftware stimmen Sie unseren",
    }
  );

  const privacyPolicy = intl.formatMessage(
    {
      id: "QuestionFlow.Disclaimer.PrivacyPolicy",
      defaultMessage: "Datenschutzrichtlinien",
    }
  );

  const footerEnd = intl.formatMessage(
    {
      id: "QuestionFlow.Disclaimer.Text2",
      defaultMessage: "zu",
    }
  );

  /**
  * END TRANSLATIONS
  */



  return (
    <div className="tablet-form-disclaimer">
      {(impressum || dataProtection) && (
        <Paragraph>
          {impressum && (
            <a
              href={"/public/welcome/impressum"}
              target="_blank"
              // Prevent clicking on Preview Mode
              style={{ pointerEvents: isPreview ? "none" : "auto" }}
            >
              Impressum
            </a>
          )}
          {impressum && dataProtection && <Divider type="vertical" />}
          {dataProtection && (
            <a
              href={"/public/welcome/data-protection"}
              target="_blank"
              // Prevent clicking on Preview Mode
              style={{ pointerEvents: isPreview ? "none" : "auto" }}
            >
              Datenschutz
            </a>
          )}
        </Paragraph>
      )}
      <Paragraph>
        {footerBegin}{" "}
        <span className="text--link">
          <a
            href={`/public/welcome/privacy?doc=Datenschutzrichtlinien`}
            target="_blank"
            rel="noopener noreferrer"
            // Prevent clicking on Preview Mode
            style={{ pointerEvents: isPreview ? "none" : "auto" }}
          >
            {privacyPolicy}
          </a>
        </span>{" "}
        {footerEnd}.{" "}
        <a
          href="javascript:void(0);"
          onClick={() => window.CC.showSettings()}
          // Prevent clicking on Preview Mode
          style={{ pointerEvents: isPreview ? "none" : "auto" }}
        >
          Cookie-Einstellungen
        </a>
      </Paragraph>
    </div>
  );
};

export default Disclaimer;

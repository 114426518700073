import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RichEditor from '../../components/RichEditor'
import http from '../../utils/http';
import { getIntl } from '../../utils/intl';


const { Title, Paragraph } = Typography;

function DataProtection(props) {
  const [dataprotection, setDataprotection] = useState('');
  const companyId = useSelector(state => state.companyConfig.company_id);
  const intl = getIntl();

  const titleText = intl.formatMessage({
    id: 'DataProtectionPage.Title',
    defaultMessage: 'Datenschutzrichtlinien',
  });

  const bodyText = intl.formatMessage({
    id: 'DataProtectionPage.Body',
    defaultMessage: 'Auf dieser Seite können Sie den Texteditor verwenden, um Ihre Datenschutz-Seite zu erstellen.\n Sie sollten sicherstellen, dass Sie immer die aktuellste Version Ihrer Datenschutzrichtlinien Ihres Unternehmens verwenden.\n\n Wichtiger Hinweis: Bitte beachten Sie, dass Ihre Datenschutzrichtlinien erforderlich sind, um die \n Compliance-Anforderungen Ihrer Kunden im Businesskontext zu erfüllen.',
  });

  const getFirstLoadDataProtection = async () => {
    const result = await http.get(`gastro/${companyId}/metadata`);
    const _dataProtection = result?.data?.payload?.datenschutz;
    const _impressum = result?.data?.payload?.impressum;
    const _businessHours = result?.data?.payload?.business_hours;
    
    if (!_dataProtection && !_businessHours && !_impressum) {
      await http.post(`gastro/${companyId}/metadata`, {
        datenschutz: '',
      });
    }
    
    setDataprotection(_dataProtection);
  }

  
  useEffect(() => {
    getFirstLoadDataProtection();
  }, []);

  const saveDocument = async (html) => {
    return await http.put(`/gastro/${companyId}/metadata`, {
      datenschutz: html,
    });
  }

  return (
    <div>
      <Row>
        <Col xs={0} lg={5}></Col>
        <Col xs={24} lg={14}>
          <Row>
            <Col xs={24}>
              <Title level={1}>{titleText}</Title>
              <Paragraph>
                {bodyText}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              {
                <RichEditor initialEditorState={dataprotection?.length > 0 ? dataprotection : ""} saveDocument={saveDocument}/>
              }
            </Col>
          </Row>
        </Col>
        <Col xs={0} lg={5}></Col>
      </Row>
    </div>
  );
}

export default DataProtection;
import {
  ADD_USER,
  DELETE_USER,
  SET_USERS,
  UPDATE_USER,
} from "../actions/usersActions";

const defaultState = {
  users: [],
};

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state?.users.filter((user) => user.id !== action.payload),
      };
    case UPDATE_USER:
      const updatedUsers = state.users.map((user) =>
        user.id === action.payload.id
          ? { ...user, ...action.payload.updatedLink }
          : user,
      );

      return {
        ...state,
        users: [...updatedUsers],
      };
    case ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };

    default:
      return state;
  }
};

export default usersReducer;

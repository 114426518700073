export default (toDownload, fileName) => {
  const blob = new Blob([toDownload], { type: "octet/stream" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}-welcomy.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
  window.URL.revokeObjectURL(url);
};
import React, { useEffect, useState } from "react";
import { Form, Modal, Select } from "antd";
import { getIntl } from "../../utils/intl";
import { updateInvitedUserApi } from "../../api/userManagementApiService";
import { displayNotification } from "../../components/Notification";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/actions/usersActions";

const { Option } = Select;

const UpdateUserModal = ({ user, onConfirm, onCancel }) => {
  const [form] = Form.useForm();
  const intl = getIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const successNotificationTitle = intl.formatMessage({
    id: "Success",
    defaultMessage: "Erfolg!",
  });
  const successNotificationBody = intl.formatMessage({
    id: "UserManagement.Notification.Edit.Success",
    defaultMessage: "Benutzer erfolgreich aktualisiert.",
  });
  const errorNotificationTitle = intl.formatMessage({
    id: "UserManagement.Notification.Edit.Error.Title",
    defaultMessage: "Benutzer konnte nicht aktualisiert werden.",
  });
  const errorNotificationBody = intl.formatMessage({
    id: "UserManagement.Notification.Edit.Error.Body",
  });
  const editModalTitle = intl.formatMessage({
    id: "UserManagement.Edit.Title",
  });
  const formFieldErrorMessage = intl.formatMessage({
    id: "UserManagement.Form.Error.Message",
  });
  const userRoleText = intl.formatMessage({
    id: "UserManagement.InviteUser.Form.UserRole.Text",
    defaultMessage: "Benutzer-Rolle"
  });
  const adminRole = intl.formatMessage({
    id: "UserManagement.Admin",
  });
  const employeeRole = intl.formatMessage({
    id: "UserManagement.Employee",
  });
  const updateText = intl.formatMessage({
    id: "Button.Update",
  });


  useEffect(() => {
    form.setFieldsValue(user);
    setIsModalVisible(!!user);
  }, [user]);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    onCancel();
  };

  const handleModalOk = () => {
    updateInvitedUserApi(user?.id, user?.company_id, form.getFieldsValue())
      .then((res) => {
        dispatch(
          updateUser({
            id: user?.id,
            content: form.getFieldsValue(),
          }),
        );
        setIsModalVisible(false);
        form.resetFields();
        displayNotification(
          "success",
          successNotificationTitle,
          successNotificationBody,
        );
      })
      .catch((e) => {
        form.resetFields();
        console.error(e);
        displayNotification(
          "error",
          errorNotificationTitle,
          errorNotificationBody,
        );
      });
  };


  return (
    <>
      <Modal
        title={editModalTitle}
        okText={updateText}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        visible={isModalVisible}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="role"
            rules={[{ required: true, message: formFieldErrorMessage }]}
            label={userRoleText}
          >
            <Select placeholder={userRoleText}>
              <Option value="admin" key="admin">{adminRole}</Option>
              <Option value="employee" key="employee">{employeeRole}</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserModal;

import React, { Component } from "react";
import { Typography, Avatar, Row, Col, Dropdown, Menu } from "antd";
import { GlobalOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { configSetter } from "../../redux/actions";
import { LanguagePickerConsumer } from '../LanguagePicker.jsx';
import { getIntl } from "../../utils/intl";
import http from '../../utils/http';
import { cookieDomain, isAllSuperAccount } from "../../utils/envs";
import { getCookie } from "../getCookie";

const { Text } = Typography;


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportedLanguages: {},
    }
    const intl = getIntl();
    const logoutText = intl.formatMessage(
      {
        id: "Navbar.Logout",
        defaultMessage: "Abmelden"
      }
    );
    const backToMainAccountText = intl.formatMessage(
      {
        id: "Navbar.Accounts.Back",
        defaultMessage: "Zum Hauptaccount"
      }
    );
    this.logo = props.companyConfig.company_logo;
    this.name = props.companyConfig.name;
    this.superAccount = this.props.companyConfig.super_account;
    const superPath = getCookie("_super_path");
    this.menu = (
      <Menu>
        {/* Below condition is to handle:
         - Showing logout button to super accounts
         - Showing logout button to sub accounts when users directly login to it */}
        { ((this.superAccount !== null && isAllSuperAccount(this.superAccount)) || 
          (this.superAccount !== null && !isAllSuperAccount(this.superAccount) && !superPath)) &&
          <Menu.Item>
            <a target="_blank" onClick={this.logout}>{logoutText}</a>
          </Menu.Item>
        }
        { this.superAccount !== null && !isAllSuperAccount(this.superAccount) && superPath &&
          <Menu.Item>
            <a target="_blank" onClick={this.goToMainAccount}>{backToMainAccountText}</a>
          </Menu.Item>
        }
      </Menu>
    );
    this.languages = (
      <LanguagePickerConsumer>
        {({switchTo }) =>(
          <Menu theme="light">
             {this.state.supportedLanguages.map(
          (aLang, index) => (
            <Menu.Item>
              <a target="_blank" onClick={() => switchTo(aLang.iso_code)}>{aLang.language}</a>
            </Menu.Item>
             ))}
          </Menu>
        )}
      </LanguagePickerConsumer>
    );
  }

  goToMainAccount = () => {
    const changeToAccount = (accounts) => {
      const domain = cookieDomain();
      const { company_id, vertical_id, name } = this.props.companyConfig;
      document.cookie = `_c=${accounts[0].id};domain=${domain}`;
      document.cookie = `_v=${accounts[0].vertical};domain=${domain}`;
      if (isAllSuperAccount(this.superAccount)) {
        document.cookie = `_super_name=${name};domain=${domain}`;
        /**
         * Subdomains cannot be multilevel in order for this to work (multiple dots).
         */
        document.cookie = `_super_path=${window.location.hostname.split(".")[0]};domain=${domain}`;
        document.cookie = `_super_c=${company_id};domain=${domain}`;
        document.cookie = `_super_v=${vertical_id};domain=${domain}`;
      } else {
        document.cookie = `_super_name=${name};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        /**
         * Subdomains cannot be multilevel in order for this to work (multiple dots).
         */
        document.cookie = `_super_path=${window.location.hostname.split(".")[0]};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        document.cookie = `_super_c=${company_id};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        document.cookie = `_super_v=${vertical_id};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      }
      if (domain === '.localhost.com') {
        window.location = `http://${accounts[0].path}.localhost.com:3000/dashboard/sub-accounts`;
      } else {
        window.location = `http://${accounts[0].path}.welcomyapp.com/dashboard/sub-accounts`;
      }
    };

    const superName = getCookie("_super_name");
    const superPath = getCookie("_super_path");
    const superId = getCookie("_super_c");
    const superVertical = getCookie("_super_v");
    changeToAccount([
      {
        name: superName,
        id: superId,
        vertical: superVertical,
        path: superPath,
      },
    ]);
  }

  getLanguages = async () => {
    const { company_id } =  this.props.companyConfig;
    if (company_id) {
      const result = await http.get(`/company/${company_id}/languages`);
      const { payload } = result.data;
      this.setState({
        supportedLanguages: payload,
      });
    }
  }

  componentDidMount() {
    this.getLanguages();
  }


  goTablet = () => {
    const { history } = this.props;
    history.replace('/welcome');
  }

  logout = () => {
    const domain = cookieDomain();
    document.cookie = `_r=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Refresh Token
    document.cookie = `_a=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Access Token
    document.cookie = `_c=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Company Id
    document.cookie = `_v=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Vertical Id
    document.cookie = `_u=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // User Id
    document.cookie = `_ur=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account Vertical

    // Delete super account cookies just to be sure that sub accounts will leave no trace behind.
    document.cookie = `_super_name=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account path
    document.cookie = `_super_path=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account path
    document.cookie = `_super_c=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Supper Account Id
    document.cookie = `_super_v=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account Vertical

    this.props.dispatch(
      configSetter({
        name: null,
        company_logo: null,
        hasFetchedConfig: false,
        user_id: null,
        hasFetchedId: false,
        vertical_id: 0,
        legalEntity: null,
      })
    );
    setTimeout(() => {
      this.props.history.push('/login')
    }, 2500);
  };

  render() {
    const {isScrolled} = this.props;

    return (
      <div className={`navbar ${isScrolled ? 'header-scroll-bg' : ''}`}>
        <Row align="middle">
          <Col sm={24}>
            <div className="right-side">
            <span>{this.props.isTop}</span>
              <Dropdown overlay={this.menu} onClick={(e) => e.preventDefault()}>
                <Text
                  ellipsis
                  style={{
                    cursor: "pointer",
                    color: "#eceff4",
                    fontWeight: "bold",
                  }}
                >
                  <Avatar shape="circle" className="club-logo" src={this.logo} />
                  <span className="club-name" style={{ margin: "0px 15px" }}>{this.name}</span>
                </Text>
              </Dropdown>
              <Dropdown className="language-selector" overlay={this.languages} onClick={e => e.preventDefault()}>
                <GlobalOutlined type="global" theme="outlined" />
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
    layoutConfig: state.layoutConfig,
  };
};

export default withRouter(connect(mapStateToProps)(Navbar));

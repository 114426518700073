import React from "react";
import { Button, Col, Form, Input, Modal, Row, Space, Typography } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { getIntl } from "../../utils/intl";
import { useDispatch, useSelector } from "react-redux";
import { cookieDomain } from "../../utils/envs";
import { displayNotification } from "../Notification";
import { createWorkspaceApi } from "../../api/workspacesApiService";
import { createWorkspaceAction } from "../../redux/actions/companyConfig";

const CreateWorkspaceModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const intl = getIntl();
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const { Title, Paragraph } = Typography;
  const dispatch = useDispatch();
  const pageTitle = intl.formatMessage({
    id: "SubAccounts.Title",
    defaultMessage: "Neuen Kundenzugang erstellen",
  });

  const pageDescription = intl.formatMessage({
    id: "SubAccounts.Description",
    defaultMessage:
      "Kreieren Sie einen neuen Kundenzugang, indem Sie einen Namen und eine individualisierte URL festlegen. Wir empfehlen Ihnen den Namen Ihres Kunden oder Zielgruppe in der URL zu verwenden, da dieser beim Teilen der Web-App angezeigt wird.",
  });

  const nameInput = intl.formatMessage({
    id: "SubAccounts.Input.Name",
    defaultMessage: "Name des Kundenzugangs",
  });

  const urlInput = intl.formatMessage({
    id: "SubAccounts.Input.Url",
    defaultMessage: "URL des Kundenzugangs",
  });

  const createButton = intl.formatMessage({
    id: "SubAccounts.Button.Create",
    defaultMessage: "Bestätigen",
  });

  const cancelButton = intl.formatMessage({
    id: "Cancel",
    defaultMessage: "Abbrechen",
  });

  const requiredText = intl.formatMessage({
    id: "Error.Required",
    defaultMessage: "Bitte geben Sie die notwendigen Informationen an.",
  });

  const successNotificationTitle = intl.formatMessage({
    id: "Success",
    defaultMessage: "Erfolg!",
  });

  const successNotificationBody = intl.formatMessage({
    id: "SubAccounts.Notification.Success",
    defaultMessage: "Neuer Kundenzugang wurde erfolgreich erstellt.",
  });

  const noSpecialChars = intl.formatMessage({
    id: "SubAccounts.Error.SpecialChars",
    defaultMessage: "Erlaubt sind nur Buchstaben, Zahlen und das Symbol '-'",
  });

  const handleOnCancel = () => {
    onCancel();
  };

  const createWorkspace = () => {
    createWorkspaceApi(companyId, form.getFieldsValue()).then((response) => {
      const { access_token, company_id } = response.data.payload;
      if (access_token) {
        const domain = cookieDomain();
        document.cookie = `_a=${access_token};domain=${domain};path=/;`;
      }
      dispatch(
        createWorkspaceAction({
          workspace: { id: company_id, ...form.getFieldsValue() },
        }),
      );
      displayNotification(
        "success",
        successNotificationTitle,
        successNotificationBody,
      );
    });
  };

  const invalidChars = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
  return (
    <Modal
      visible={visible}
      title="Create Sub Account"
      onCancel={handleOnCancel}
      footer={[
        <Button key="cancel" onClick={handleOnCancel}>
          {cancelButton}
        </Button>,
        <Button key="confirm" type="primary" onClick={createWorkspace}>
          {createButton}
        </Button>,
      ]}
    >
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title>
                <Space>
                  {pageTitle} <TeamOutlined />
                </Space>
              </Title>
              <Paragraph>{pageDescription}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form form={form} layout="vertical">
                <Form.Item
                  label={nameInput}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: requiredText,
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label={urlInput}
                  name="path"
                  rules={[
                    {
                      required: true,
                      message: requiredText,
                    },
                    {
                      validator: async (_, value) => {
                        if (value && invalidChars.test(value)) {
                          throw new Error(noSpecialChars);
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    addonAfter=".welcomyapp.com"
                    addonBefore="https://"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateWorkspaceModal;

import React, { createContext, useContext, useState, useEffect } from "react";
import http from "../../../../../utils/http";
import { useSelector } from 'react-redux';
const DishesCtx = createContext();

export const useDishes = () => useContext(DishesCtx);

function DishesContext({ children, ...props }) {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const [dishes, setDishes] = useState([]);
  useEffect(() => {
    const fetchDishes = async () => {
      let payload;
      try {
        const response = await http.get(`gastro/${companyId}/menu`, {
          params: {
            empty_categories: false,
          }
        });
        payload = response.data.payload;
      } catch (error) {
        console.error("Not able to fetch menu", error);
      }
      if (payload) {
        const categories = [];
        for (const category in payload) {
          payload[category].key = category;
          categories.push(payload[category]);
        }
        setDishes(categories);
      }
    }
    fetchDishes();
  }, []);

  return <DishesCtx.Provider value={dishes}>{children}</DishesCtx.Provider>;
}

export default DishesContext;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import http from "../../utils/http";
const SpiceLevelsCtx = createContext();

export const useSpiceLevels = () => useContext(SpiceLevelsCtx);

function SpiceLevelsContext({ children, ...props }) {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const verticalId = useSelector(state => state.companyConfig.vertical_id);
  const [spiceLevels, setSpiceLevels] = useState([]);
  useEffect(() => {
    const fetchSpiceLevels = async () => {
      let payload;
      try {
        const response = await http.get(`gastro/${companyId}/spice_level`);
        payload = response.data.payload;
      } catch (error) {
        console.error("Not able to fetch spice levels", error);
      }
      if (payload) {
        setSpiceLevels(payload);
      }
    }
    if (verticalId === 3) fetchSpiceLevels();
  }, []);

  return <SpiceLevelsCtx.Provider value={spiceLevels}>{children}</SpiceLevelsCtx.Provider>;
}

export default SpiceLevelsContext;

import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import QueueAnim from "rc-queue-anim";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayNotification } from "../../components/Notification";
import { uploadImages } from "../../utils/image";
import { getIntl } from "../../utils/intl";
import { addHubConfig, getHubConfig } from "../../api/layOutApiService";
import { setHubConfig as setHubConfigAction } from "../../redux/actions";
import { updateLivePreviewCompanySetup } from "../../redux/actions/livePreviewActions";
import { getBase64 } from "./utils";
import { LogoUpload } from "../LogoUpload";

const { Paragraph } = Typography;

const maxCharsTitle = 55;
const maxCharsSubtitle = 255;

const WelcomyHubConfig = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const companyId = useSelector((state) => state.companyConfig.company_id);

  const [hubConfig, setHubConfig] = useState(null);
  let initialConfigHubState = null;

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [hubName, setHubName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [logo, setLogo] = useState(null);
  const [hubLogo, setHubLogo] = useState("");

  /**
   * Translations
   */
  const intl = getIntl();

  const titleInput = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Title",
    defaultMessage: "Erste Zeile (Empfehlung: schreiben Sie eine Willkommensnachricht)",
  });

  const logoLabel = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Logo",
    defaultMessage: "Logo hochladen",
  });

  const nameLabel = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Name",
    defaultMessage: "Zweite Zeile (Empfehlung: geben Sie der Hub einen maßgeschneiderten Namen oder definieren Sie den Zweck der Hub)",
  });

  const namePlaceholder = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Name.Input",
    defaultMessage: "Fügen Sie den Zweck oder Namen der Hub ein",
  });

  const titlePlaceholder = intl.formatMessage({
    id: "WelcomyHubConfig.Placeholder.Title",
    defaultMessage: "Fügen Sie hier Ihre Willkommensnachricht ein",
  });

  const subtitleInput = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Subtitle",
    defaultMessage: "Zweite Zeile (Empfehlung: geben Sie der Hub einen maßgeschneiderten Namen oder definieren Sie den Zweck der Hub)",
  });

  const subtitlePlaceholder = intl.formatMessage({
    id: "WelcomyHubConfig.Placeholder.Subtitle",
    defaultMessage: "Schildern Sie hier den Inhalt Ihrer Welcomy Hub",
  });

  const saveButton = intl.formatMessage({
    id: "Save",
    defaultMessage: "Speichern",
  });

  const errorTitle = intl.formatMessage({
    id: "Error",
    defaultMessage: "Etwas ist schief gelaufen!",
  });

  // Fetch initial data
  useEffect(() => {
    fetchHubData();
  }, [], () => {
    dispatch(setHubConfigAction({...initialConfigHubState}));
  });

  // Set initial data to form
  useEffect(() => {
    if (hubConfig && Object.keys(hubConfig || {}).length > 0) {
      const { title, subtitle, name, hubLogo } = hubConfig;
      form.setFieldsValue({
        "title": title || "",
        "name": name || "",
        "subtitle": subtitle || "",
      });
      setTitle(title || "");
      setHubName(name || "");
      setSubtitle(subtitle || "");
      setHubLogo(hubLogo || "");
      setIsFirstLoad(true);
    }
  }, [hubConfig]);

  const fetchHubData = async () => {
    try {
      const {
        data: { payload },
      } = await getHubConfig(companyId);
      dispatch(
        updateLivePreviewCompanySetup({
          companyTitle: payload.title,
          description: payload.subtitle,
          hubName: payload.name,
          logo: payload.image_url,
        })
      );

      const _hubConfig = {
        ...payload,
        hubLogo: payload.image_url,
      };

      setHubConfig(_hubConfig);
      initialConfigHubState = {..._hubConfig}
    } catch (error) {
      console.error("Error loading Hub Data");
      displayNotification("error", errorTitle, "");
    }
  };

  const changeTitle = ({ target: { value } }) => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    if (title.length <= maxCharsTitle) {
      setTitle(value);
    }
  };

  const changeHubName = ({ target: { value } }) => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    setHubName(value);
  };

  const changeSubtitle = ({ target: { value } }) => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    if (subtitle.length <= maxCharsSubtitle) {
      setSubtitle(value);
    }
  };

  const submit = async () => {
    setIsLoading(true);
    if (logo) {
      try {
        logo.thumbUrl = await getBase64(logo);
        const response = await uploadImages(companyId, [logo], "hub/logo");
        if (response.length === 0) throw new Error();
        await addHubConfig(title, subtitle, hubName, response[0], companyId);
        /**
         * update initial state to reflect changes
         */
        initialConfigHubState = {
          company_id: companyId,
          image_url: response[0],
          name: hubName,
          title,
          subtitle,
        };
        dispatch(
          setHubConfigAction({
            name: hubName,
            title,
            subtitle,
            hubLogo: response[0],
          })
        );
      } catch (error) {
        console.error("Error while uploading hub image");
        displayNotification("error", errorTitle, "");
      }
    } else {
      await addHubConfig(title, subtitle, hubName, hubLogo, companyId);
      /**
       * update initial state to reflect changes
       */
      initialConfigHubState = {
        company_id: companyId,
        image_url: hubLogo,
        name: hubName,
        title,
        subtitle,
      };
      dispatch(
        setHubConfigAction({
          name: hubName,
          title,
          subtitle,
          hubLogo,
        })
      );
    }
    setIsLoading(false);
  };

  const handleLogoChange = useCallback((payload) => {
    const { companyLogo } = payload;
    if (companyLogo && companyLogo[0] && companyLogo[0].originFileObj) {
      const logoFile = companyLogo[0].originFileObj;
      setLogo(logoFile);
      getBase64(logoFile, (logoBase64) => {
        dispatch(
          updateLivePreviewCompanySetup({
            logo: logoBase64,
          })
        );
      });
    }
  }, []);

  const layoutFormChange = (changes, allChanges) => {
    dispatch(
      updateLivePreviewCompanySetup({
        companyTitle: allChanges["title"],
        hubName: allChanges["name"],
        description: allChanges["subtitle"],
      })
    );
  };

  return (
    <div>
      <QueueAnim delay={500} component={Row}>
        <Col xs={24} lg={24} className="p-r-24" key="a">
          <div className="layout-form">
            <Form
              form={form}
              name="layout-config-form"
              layout="vertical"
              autoComplete="off"
              onValuesChange={layoutFormChange}
              onFinish={submit}
            >
              <LogoUpload onFormChange={handleLogoChange} logoImage={hubLogo} inputLabel={logoLabel} disabled={isLoading}/>
              <Form.Item
                name="title"
                label={titleInput}
                className="m-b-0"
              >
                <Input
                  type="text"
                  onChange={(e) => changeTitle(e)}
                  maxLength={maxCharsTitle}
                  placeholder={titlePlaceholder}
                  disabled={isLoading}
                />
              </Form.Item>
              <Paragraph className="layout-form__title__count">
                {title.length}/{maxCharsTitle}
              </Paragraph>
              <Form.Item name="name" label={nameLabel}>
                <Input
                  type="text"
                  onChange={(e) => changeHubName(e)}
                  placeholder={namePlaceholder}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name="subtitle"
                label={subtitleInput}
                className="m-b-0"
              >
                <Input.TextArea
                  onChange={(e) => changeSubtitle(e)}
                  maxLength={maxCharsSubtitle}
                  className="layout-form__textarea"
                  placeholder={subtitlePlaceholder}
                  disabled={isLoading}
                />
              </Form.Item>
              <Paragraph className="layout-form__textarea__count">
                {subtitle.length}/{maxCharsSubtitle}
              </Paragraph>
              <Form.Item name="layout-form-submit">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {saveButton}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </QueueAnim>
    </div>
  );
};

export default WelcomyHubConfig;

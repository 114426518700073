import { Button, Col, Form, Input, Result, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import http from '../../utils/http';
import { assignPublicCookie } from '../Utils/cookies';

const { Title, Paragraph } = Typography;


const UnsubscribeForm = ({ companyName, onFinish }) => {
  const validateMessages = {
    required: 'E-mail ist ein Pflichtfeld',
    types: {
      email: 'E-mail ist keine valide E-mail',
    },
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <Title level={1} className="m-t-0 m-b-0">
            {companyName}
          </Title>
          <Title level={3} className="m-t-0 m-b-0">
            Geben Sie nun Ihre E-Mail-Adresse an,
          </Title>
          <Paragraph>
            damit wir Sie automatisch aus der Mailingliste entfernen können.
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form
            name="unsubscribe-form"
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit" size="large">
                Weiter
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const UnsubscribeSuccess = () => {
  const history = useHistory();
  const goMainPage = () => {
    history.push("/public/welcome/form");
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <Result
            status="success"
            title={`Ihre Daten wurden erfolgreich aus der Mailingliste entfernt.`}
            subTitle="Falls Sie Ihre Meinung ändern sollten oder Ihre angegebenen Informationen veraltet waren, so können Sie sich gerne erneut in unserer App registrieren."
            extra={[
              <Button type="primary" key="main-page" onClick={goMainPage}>
                Zur App-Startseite
              </Button>,
            ]}
          />
        </Col>
      </Row>
    </>
  );
};


function UnsubscribePage() {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const companyName = useSelector(state => state.companyConfig.name);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  useEffect(() => {
    const setCookie = async () => {await assignPublicCookie(companyId)};
    setCookie();
  }, [companyId]);

  const onFinish = async (values) => {
    try {
      const result = await http.post(`/unsubscribe`, {
        email: values.email,
      });
      const {status} = result;
      if (status >= 200 && status < 300) {
        setIsUnsubscribed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="unsubscribe__wrapper">
      <Row justify="center">
        <Col xs={24} md={6}></Col>
        <Col xs={24} md={12}>
          {!isUnsubscribed && (
            <UnsubscribeForm companyName={companyName} onFinish={onFinish} />
          )}
          {isUnsubscribed && <UnsubscribeSuccess companyName={companyName}/>

          }
        </Col>
        <Col xs={24} md={6}></Col>
      </Row>
    </div>
  );
}

export default UnsubscribePage;
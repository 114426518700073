import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import DishesContext, {
  useDishes,
} from "../Tablet/public/components/Restaurant/DishesContext";
import { useSelector } from "react-redux";
import { getIntl } from "../../utils/intl";
import AllergensContext, {
  useAllergens,
} from "../Tablet/public/components/Restaurant/AllergensContext";
import { displayNotification } from "../../components/Notification";
import { useReactToPrint } from "react-to-print";

const { Title } = Typography;

const MenuTemplate = React.forwardRef(
  ({ toExport, logoUrl, allergens, ...props }, ref) => {
    const totalAllergens = allergens.length;
    return (
      <>
        <style type="text/css" media="print">
          {`
          @page {
            size: A4;
            margin: 32px;
          }
        `}
        </style>
        <div className="pdf" ref={ref}>
          <div className="pdf__header">
            <div className="header__logo">
              <img src={logoUrl} />
            </div>
            <div className="header__name">
              <Title level={1}>{toExport?.restaurantName}</Title>
            </div>
            {toExport?.restaurantSubtitle && (
              <div className="header__subtitle">
                <Title level={4}>{toExport?.restaurantSubtitle}</Title>
              </div>
            )}
          </div>
          {toExport?.categories.map((cat) => (
            <div>
              <div className="category__title pdf--avoid-break" key={cat.key}>
                <Title level={2}>{cat.category_name}</Title>
              </div>
              {cat.dishes.map((dish) => (
                <div
                  className="category__dish pdf--avoid-break"
                  key={dish.dish_id}
                >
                  <table className="dish__table pdf--avoid-break">
                    <tr>
                      <td className="table__title-description pdf--avoid-break">
                        <div>
                          <span>
                            <Title level={4}>
                              {dish.dish_name}
                              {dish.allergens.length > 0 &&
                                dish.allergens
                                  .map((al) => {
                                    if (!al.is_preservative)
                                      return (
                                        <sup className="allergens--sup">
                                          {al.allergen_id}
                                        </sup>
                                      );
                                  })
                                  .reduce((accu, elem) => {
                                    if (elem)
                                      return accu === null
                                        ? [elem]
                                        : [
                                            ...accu,
                                            <sup className="allergens--sup">
                                              ,{" "}
                                            </sup>,
                                            elem,
                                          ];
                                  }, null)}
                            </Title>
                          </span>
                        </div>
                        <p>{dish.description}</p>
                      </td>
                      <td className="table__prices pdf--avoid-break">
                        <table
                          className="dish__prices pdf--avoid-break"
                          cellSpacing={2}
                        >
                          {dish.dish_price &&
                            Object.keys(dish.dish_price)?.map((price) => (
                              <tr className="pdf--avoid-break">
                                <td className="price__name">
                                  {price !== "default" && <span>{price}</span>}
                                </td>
                                <td className="price__value">{`${Number(
                                  dish.dish_price[price]
                                )?.toFixed(2)} €`}</td>
                              </tr>
                            ))}
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          ))}
          <AllergensFooter allergens={allergens}  className="allergens__footer"/>
        </div>
      </>
    );
  }
);

const AllergensFooter = ({allergens}) => {
  const totalAllergens = allergens.length;
  return (
    <section className="allergens__footer">
      <div className="allergens__wrapper">
        <div className="allergens__title">
          <Title level={4}>
            Allergene Informationen
          </Title>
        </div>
          {
            allergens
            .map((al, index) => {
              if (!al.is_preservative)
                return (<div className="allergen">{`${al.id}. ${al.name}${index + 1 !== totalAllergens ? `, `: ``}`}</div>);
            })
          }
      </div>
    </section>
  );
}

function Body(props) {
  const [form] = Form.useForm();
  const companyLogo = useSelector((state) => state.companyConfig.company_logo);
  const hubLogo = useSelector((state) => state.hubConfig.hubLogo);

  const logoUrl = hubLogo || companyLogo;

  const companyName = useSelector((state) => state.companyConfig.name);
  const verticalId = useSelector(state => state.companyConfig.vertical_id);
  const [toExport, setToExport] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const intl = getIntl();
  const allergens = useAllergens();
  const pdfRef = useRef();
  const categories = useDishes();
  const [plainOptions, setPlainOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Welcomy - Menu",
    removeAfterPrint: true,
  });

  let pageTitleId = "PrintMenuPage.Title";
  let subTitleId = "PrintMenuPage.Subtitle";
  let companyNameId = "PrintMenuPage.RestaurantName";
  if (verticalId && verticalId === 4){
    pageTitleId = "PrintMenuPage.Business.Title";
    subTitleId = "PrintMenuPage.Business.Subtitle";
    companyNameId = "PrintMenuPage.Business.RestaurantName";
  }
  const pageTitle = intl.formatMessage({
    id: pageTitleId,
    defaultMessage: "Welcomy Menükarten-Druckfunktion - Betaversion",
  });
  const pageSubtitle = intl.formatMessage({
    id: subTitleId,
    defaultMessage: "Welcomy Menükarten-Druckfunktion - Betaversion",
  });
  const formRestaurantName = intl.formatMessage({id: companyNameId});
  const formRestaurantSubtitle = intl.formatMessage({
    id: "PrintMenuPage.RestaurantSubtitle",
    defaultMessage: "Welcomy Menükarten-Druckfunktion - Betaversion",
  });
  const formRestaurantCategories = intl.formatMessage({
    id: "PrintMenuPage.RestaurantCategories",
    defaultMessage: "Welcomy Menükarten-Druckfunktion - Betaversion",
  });
  const categoriesSelectionError = intl.formatMessage({
    id: "PrintMenuPage.Error.Categories",
    defaultMessage: "Sie müssen mindestens eine Kategorie auswählen",
  });
  const pageExport = intl.formatMessage({
    id: "Export",
    defaultMessage: "Exportieren",
  });

  const onChangeCheckbox = (value) => {
    setCheckedList(value);
    setCheckAll(value.length === plainOptions.length);
  };

  const onCheckAllChange = (value) => {
    setCheckedList(value.target.checked ? plainOptions : []);
    setCheckAll(value.target.checked);
  };

  const exportCats = () => {
    form
      .validateFields()
      .then((values) => {
        if (checkedList.length == 0) {
          displayNotification("error", categoriesSelectionError, "");
          return;
        }
        values.categories = checkedList;

        let toExport = values;
        const selectedCategories = [];
        categories.map((category, _) => {
          checkedList.map((category_name, _) => {
            if (category.category_name == category_name) {
              selectedCategories.push(category);
            }
          });
        });
        toExport.categories = selectedCategories;
        setToExport({ ...toExport });
      })
      .catch((info) => {
        console.error("Validation failed:", info);
      });
  };

  useEffect(() => {
    if (toExport && toExport.categories.length > 0) {
      handlePrint();
    }
  }, [toExport]);

  useEffect(() => {
    if (categories) {
      const category_names = [];
      categories.map((category, _) => {
        category_names.push(category.category_name);
      });
      setPlainOptions(category_names);
    }
  }, [categories]);

  return (
    <div className="print-menu-wrapper">
      <Row gutter={32}>
        <Col xs={0} xl={5}></Col>
        <Col xs={24} xl={14}>
          <Row justify="center">
            <Col xs={24}>
              <Title level={1} className="page-title">
                {pageTitle}
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24}>{pageSubtitle}</Col>
          </Row>
          <Row justify="center" className="m-t-12">
            <Col xs={24}>
              <Form
                form={form}
                name="print-form"
                layout="vertical"
                initialValues={{
                  restaurantName: companyName,
                  categories: [],
                  restaurantSubtitle: "",
                }}
              >
                <Form.Item name="restaurantName" label={formRestaurantName}>
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="restaurantSubtitle"
                  label={formRestaurantSubtitle}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="categories"
                  label={formRestaurantCategories}
                  valuePropName="value"
                >
                  <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                    Check all
                  </Checkbox>
                  <Checkbox.Group
                    options={plainOptions}
                    onChange={onChangeCheckbox}
                    value={checkedList}
                    className="ant-checkbox-group-item"
                  ></Checkbox.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Button type="primary" onClick={exportCats}>
                {pageExport}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={0} xl={5} className="viewer">
          <MenuTemplate ref={pdfRef} toExport={toExport} logoUrl={logoUrl} allergens={allergens}/>
        </Col>
      </Row>
    </div>
  );
}

function PrintMenu(props) {
  return (
    <DishesContext>
      <AllergensContext>
        <Body props />
      </AllergensContext>
    </DishesContext>
  );
}

export default PrintMenu;

import { Button, Col, Form, Input, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useState } from "react";
import PageHeader from "../components/PageHeader";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getIntl } from "../utils/intl";
import { LogoUpload } from "./LogoUpload";
import { useDispatch, useSelector } from "react-redux";
import http from "../utils/http";
import { displayNotification } from "../components/Notification";
import { configSetter } from "../redux/actions";

const { Title, Paragraph } = Typography;

function CompanySetup(props) {
  const intl = getIntl();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(null);

  const companyLogo = useSelector((state) => state.companyConfig.company_logo);
  const name = useSelector((state) => state.companyConfig.name);
  const legalEntity = useSelector((state) => state.companyConfig.legalEntity);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const superAccount = useSelector(
    (state) => state.companyConfig.super_account,
  );

  const [logo, setLogo] = useState(companyLogo);

  const logoLabel = intl.formatMessage({
    id: "WelcomyHubConfig.Label.Logo",
    defaultMessage: "Logo hochladen",
  });

  const saveButton = intl.formatMessage({
    id: "Save",
    defaultMessage: "Speichern",
  });

  const title = intl.formatMessage({
    id: "MyCompany.Configuration.Title",
    defaultMessage: "Unternehmensprofil anpassen",
  });

  const formTitle = intl.formatMessage({
    id: "MyCompany.Configuration.Form.Title",
    defaultMessage: "Grundlegende Informationen Ihres Unternehmens",
  });

  const legalNameInput = intl.formatMessage({
    id: "MyCompany.Configuration.LegalName",
    defaultMessage: "Name der juristischen Person",
  });

  const commercialNameInput = intl.formatMessage({
    id: "MyCompany.Configuration.CommercialName",
    defaultMessage: "Handelsüblicher Name",
  });

  const successNotificationTitle = intl.formatMessage({
    id: "MyCompany.Configuration.Success.Title",
    defaultMessage: "Erfolg!",
  });

  const successNotificationBody = intl.formatMessage({
    id: "MyCompany.Configuration.Success.Body",
    defaultMessage: "Unternehmensprofil wurde erfolgreich gespeichert",
  });

  const errorNotificationTitle = intl.formatMessage({
    id: "MyCompany.Configuration.Error.Title",
    defaultMessage: "Unable to save company profile",
  });

  const errorNotificationBody = intl.formatMessage({
    id: "General.ErrorNotification.Body",
  });

  const handleLogoChange = useCallback((payload) => {
    const { companyLogo } = payload;
    if (companyLogo && companyLogo[0] && companyLogo[0].originFileObj) {
      const logoFile = companyLogo[0].originFileObj;
      setLogo(logoFile);
    }
  }, []);

  const saveCompanyConfig = async (name, legalEntity, logo) => {
    let data = new FormData();

    data.append("name", name);
    if (legalEntity){
      data.append("legal_entity", legalEntity);
    }
    if (logo) {
      data.append("logo", logo);
    }

    try {
      const response = await http.post(`/company/${companyId}`, data);
      return response;
    } catch(error) {
      console.log(error);
      displayNotification(
        "error",
        errorNotificationTitle,
        errorNotificationBody
      );
    }
  };

  const submit = async ({ companyLogo, legalEntity, commercialName }) => {
    setIsLoading(true);
    if (companyLogo) {
        try {
          const response = await saveCompanyConfig(commercialName, legalEntity, companyLogo[0].originFileObj);
          if (!response){
            setIsLoading(false);
            return;
          }
          if (response.length === 0) throw new Error();
          if (response.status === 200) {
            dispatch(
              configSetter({
                name: commercialName,
                legalEntity,
                company_logo: response.data.payload.logo_url
              })
            );
            displayNotification(
              "success",
              successNotificationTitle,
              successNotificationBody
            );
          }
        } catch (error) {
          console.error("Error while uploading hub image", Object.keys(error));
          displayNotification(
            "error",
            errorNotificationTitle,
            errorNotificationBody
          );
        }
    } else {
      try {
        const response = await saveCompanyConfig(commercialName, legalEntity);
        if(!response) throw new Error("Unable to save company config");
        if (response.status === 200) {
          dispatch(
            configSetter({
              name: commercialName,
              legalEntity,
            })
          );
          displayNotification(
            "success",
            successNotificationTitle,
            successNotificationBody
          );
        }
        else {
          displayNotification(
            "error",
            errorNotificationTitle,
            errorNotificationBody
          );
        }
      } catch(error){
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="company-setup__wrapper">
      <PageHeader title={title} subtitle={formTitle}/>
      <article>
        <Row>
          <Col xs={24} lg={8}>
            <Form
              form={form}
              layout="vertical"
              onFinish={submit}
              initialValues={{ commercialName: name || "", legalEntity: legalEntity || "" }}
            >
              <LogoUpload
                onFormChange={handleLogoChange}
                logoImage={logo}
                inputLabel={logoLabel}
              />
              {
                superAccount &&
                <Form.Item
                  label={legalNameInput}
                  name="legalEntity"
                  required
                  tooltip={{
                    title:
                      "The legal name of your company, e.g. Welcomy GmbH. This is the name we will display in your AGBs and contracts.",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input placeholder="Welcomy GmbH" />
                </Form.Item>
              }
  
              <Form.Item
                label={commercialNameInput}
                name="commercialName"
                required
                tooltip={{
                  title:
                    "The comercial name of your company, e.g. Welcomy. This is the name we will display to your clients.",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input placeholder="Welcomy" />
              </Form.Item>
              <Form.Item name="layout-form-submit">
                <Button type="primary" htmlType="submit">
                  {saveButton}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </article>
    </section>
  );
}

export default CompanySetup;

import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { Spin } from "antd";

function LineChart({ data, title, limit }) {
  const ref = useRef(null);

  let labels = [];
  let values = [];
  if (data) {
    labels = Object.keys(data);
    values = Object.values(data);

    if (limit) {
      labels = labels.splice(labels.length - limit);
      values = values.splice(values.length - limit);
    }
  }

  useEffect(() => {
    if (data) {
      new Chart(ref.current, {
        type: "line",
        data: {
          labels: labels,
          labels: labels,
          datasets: [
            {
              label: title,
              data: values,
              borderColor: "rgba(54, 162, 235, 1)",
              backgroundColor: "rgba(54, 162, 235, 1)",
              fill: false,
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              suggestedMin: 0,
              suggestedMax: 50,
            },
          },
        },
      });
    }
  }, [data]);

  if (!data)
    return (
      <div className="graph-wrapper-vertical">
        <div className="graph-wrapper-horizontal">
          <Spin className="chart-spin" />
        </div>
      </div>
    );

  return (
    <div>
      <canvas ref={ref}></canvas>
    </div>
  );
}

export default LineChart;

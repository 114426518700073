import { Modal, Spin, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const { Paragraph, Title } = Typography;

function QrCodeLoadingModal({isLoadingQR}) {
  return (
    <div>
      <Modal visible={isLoadingQR} footer={null} closable={false} centered>
        <Paragraph className="text-center">
          <Title level={3}>
            <FormattedMessage
              id="SetupPage.Modal.Title"
              defaultMessage="Ihr individueller QR Code wird generiert"
            ></FormattedMessage>
          </Title>
          <FormattedMessage
            id="SetupPage.Modal.Body"
            defaultMessage="Bitte warten Sie einen moment"
          ></FormattedMessage>
        </Paragraph>
        <Paragraph className="text-center">
          <Spin />
        </Paragraph>
      </Modal>
    </div>
  );
}

export default QrCodeLoadingModal;

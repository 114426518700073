export const LINK_TYPES = {
  INSTAGRAM: "instagram",
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  WHATSAPP: "whatsapp",
  LINKEDIN: "linkedin",
  PHONE: "phone",
  EMAIL: "email",
};

/*
 * @TODO: We might want to chang that on the database level
 * as 'small' and 'text' do not necessary reflect
 * 'icon' and 'button' variations on the UI.
 */
export const LINK_VARIATIONS = {
  ICON: "small",
  BUTTON: "text",
};

import { Alert, Button, Card, Form, Input, Radio, Space, Switch, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  deleteLivePreviewLink,
  updateLivePreviewLink,
  updateLivePreviewNewLink,
} from "../../redux/actions/livePreviewActions";
import { useDispatch } from "react-redux";
import http from "../../utils/http";
import { displayNotification } from "../Notification";
import { getIntl } from "../../utils/intl";
import { getCookie } from "../getCookie";
import LinkVariations from "../LinkVariations/LinkVariations";
import { messages } from "./Translations";
import { createLinkManagement } from "../../api/linkManagerManagementService";
import {
  setCompanyLinks,
  updateCompanyLink,
} from "../../redux/actions/companyConfig";
import { updateLinkApiService } from "../../api/linkManagerApiService";
import { linkCouldBeIcon } from './utils';
import { LINK_VARIATIONS } from "./linkMappings";

const VARIATION_DEFAULT_OPTION = LINK_VARIATIONS.BUTTON;

const NewLink = ({
  companyId,
  existingLinks,
  updateLinkId = null,
  onChange,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [linkType, setLinkType] = useState(null);
  const [isLinkSocialNetwork, setIsLinkSocialNetwork] = useState(false);
  const [linkVariation, setLinkVariation] = useState(VARIATION_DEFAULT_OPTION);
  const editIndex = existingLinks.findIndex((item) => item.id === updateLinkId);
  const updateLink = existingLinks[editIndex];
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    if (updateLink) {
      setLinkType(updateLink.type);
      form.setFieldsValue(updateLink);
      linkTypeChange(updateLink.type)
    }
  }, []);

  const translation = getIntl();
  const uploadProps = {
    name: "something",
    multiple: false,
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${getCookie("_a")}`,
    },
    onChange: (info) => {
      const { status } = info.file;
      if (status === "uploading") {
        setLoadingState(true);
      }
      else if (status === "done"){
        setLoadingState(false);
      }
      else if (status === "error"){
        setLoadingState(false);
        displayNotification(
          "error",
          translation.formatMessage(messages.errorTitle),
          translation.formatMessage(messages.uploadError),
        );
      }
    },
    async customRequest(fileOpts) {
      const { onSuccess, onError } = fileOpts;
      let data = new FormData();
      data.append("file", fileOpts.file);
      try {
        const res = await http.post(`/company/${companyId}/file`, data);
        form.setFieldsValue({ link: res.data.payload.url });
        onSuccess();
      } catch (error) {
        console.log("Error uploading file", error);
        onError(error);
      }
    },
  };

  const handleFormValueChanges = (changes, allChanges) => {
    if (updateLink) {
      dispatch(
        updateLivePreviewLink({
          id: updateLink.id,
          updatedLink: {
            id: updateLink.id,
            ...form.getFieldsValue(),
            variation: isLinkSocialNetwork ? linkVariation : VARIATION_DEFAULT_OPTION,
          },
        }),
      );
    } else {
      dispatch(
        updateLivePreviewNewLink({
          id: "TEMP_LINK",
          content: {
            ...allChanges,
            variation: isLinkSocialNetwork ? allChanges.variation : VARIATION_DEFAULT_OPTION,
          },
        }),
      );
    }
  };

  const AddressText = () => {
    if (linkType === "phone") {
      return translation.formatMessage(messages.phoneAddressText);
    }

    if (linkType === "file") {
      return translation.formatMessage(messages.fileText);
    }

    if (linkType === "software") {
      return translation.formatMessage(messages.softwareText);
    }

    if (linkType === "whatsapp") {
      return translation.formatMessage(messages.whatsappAddressText);
    }

    return translation.formatMessage(messages.address);
  };

  const cancelEdit = () => {
    if (!updateLink) {
      dispatch(deleteLivePreviewLink({ id: "TEMP_LINK" }));
      form.resetFields();
    }
    onChange();
  };

  const goTo = () => {
    if(linkType === 'newsletter'){
      history.push("/dashboard/subscriptions");
    }
    else if(linkType === 'product_and_services'){
      history.push("/dashboard/digital-menu");
    }
    else if(linkType === 'impressum'){
      history.push("/dashboard/impressum");
    }
  }

  const updateLinkCard = () => {
    let updatedLink = {
      ...updateLink,
      ...form.getFieldsValue(),
      type: linkType,
    };
    updatedLink = constructLinks(linkType, updatedLink);

    delete updatedLink.id;

    if (!validateForm(linkType, updatedLink)){
      return;
    }
    updateLinkApiService(companyId, updateLink?.id, updatedLink)
      .then((res) => {
        const linkUpdate = { id: updateLink.id, ...updatedLink };

        dispatch(
          updateCompanyLink({ id: updateLink.id, updatedLink: linkUpdate }),
        );
        displayNotification(
          "success",
          translation.formatMessage(messages.linkUpdateSuccessTitle),
          translation.formatMessage(messages.linkUpdateSuccessBody),
        );
        onChange();
      })
      .catch((err) => {
        console.error(err);
        displayNotification(
          "error",
          translation.formatMessage(messages.errorTitle),
          translation.formatMessage(messages.saveError),
        );
      });
  };
  const constructLinks = (linkType, link) => {
    if (linkType === "email") {
      const subject = link["subject"] || "";
      const emailLinkWithSubject = `${link["link"]}?subject=${subject}`;
      delete link["subject"];
      return {
        ...link,
        link: emailLinkWithSubject,
      };
    } else if (linkType === "newsletter") {
      return {
        ...link,
        link: `${window.location.protocol}//${window.location.host}/public/welcome/form/newsletter-form`,
      };
    } else if (linkType === "product_and_services") {
      return {
        ...link,
        link: `${window.location.protocol}//${window.location.host}/public/welcome/form/menu`,
      };
    } else if (linkType === "pdf-ai") {
      return {
        ...link,
        chat_with_pdf: true
      }
    } else if(linkType === "whatsapp"){
      if (!link["link"]) return link;
      const whatsappURL = "https://wa.me/";
      const urlIndex = link["link"].indexOf(whatsappURL);
      let phoneNumber = link["link"];
      if (urlIndex !== -1){
        phoneNumber = link["link"].substring(urlIndex + whatsappURL.length);
      }
      return {
        ...link,
        link: `https://wa.me/${phoneNumber}`,
      }
    } else {
      return link;
    }
  };

  const validateForm = (linkType, link) => {
    if(!link["link"]){
      if (linkType === "upload"){
        displayNotification(
          "error",
          translation.formatMessage(messages.UploadMissingErrorTitle),
          translation.formatMessage(messages.UploadMissingErrorBody),
        );
      }
      else {
        displayNotification(
          "error",
          translation.formatMessage(messages.NoLinkErrorTitle),
          translation.formatMessage(messages.NoLinkErrorBody),
        );
      }
      return false;
    }
    return true;
  }

  const saveCard = () => {
    let newLink = {
      ...form.getFieldsValue(),
      name:
        form.getFieldValue("variation") === LINK_VARIATIONS.ICON
          ? form.getFieldValue("link")
          : form.getFieldValue("name") || form.getFieldValue("name"),
      type: linkType,
    };
    newLink = constructLinks(linkType, newLink);

    if (!validateForm(linkType, newLink)){
      return;
    }
    createLinkManagement(existingLinks, companyId, newLink)
      .then((res) => {
        /* here I remove the link from the preview when the link is created successfully because
              the endpoint is already sending back the full updated links to avoid LINK duplication
              in the live preview */
        dispatch(deleteLivePreviewLink({ id: "TEMP_LINK" }));
        dispatch(setCompanyLinks(res.data.payload));
        displayNotification(
          "success",
          translation.formatMessage(messages.linkCreationSuccessTitle),
          translation.formatMessage(messages.linkCreationSuccessBody),
        );

        // this on Change to close the Add link section
        onChange();
      })
      .catch((err) => {
        dispatch(deleteLivePreviewLink({ id: "TEMP_LINK" }));
        displayNotification(
          "error",
          translation.formatMessage(messages.errorTitle),
          translation.formatMessage(messages.saveError),
        );
      });
  };


  const linkTypeChange = (linkType) => {
    const fieldsValue = form.getFieldsValue();

    if (linkCouldBeIcon(linkType)) {
      setIsLinkSocialNetwork(true);
    } else {
      setIsLinkSocialNetwork(false);
      fieldsValue.variation = VARIATION_DEFAULT_OPTION;
    }

    setLinkType(linkType);

    if (updateLink) {
      dispatch(
        updateLivePreviewLink({
          id: updateLink?.id,
          updatedLink: {
            ...fieldsValue,
            type: linkType,
            variation: fieldsValue.variation,
          },
        }),
      );
    } else {
      dispatch(
        updateLivePreviewNewLink({
          id: "TEMP_LINK",
          content: {
            ...fieldsValue,
            type: linkType,
            variation: fieldsValue.variation,
          },
        }),
      );
    }
  };

  return (
    <>
      <Card hoverable className="edit-link-card m-b-4">
        <div className="edit-link-form">
          <Form
            form={form}
            name="card-form"
            layout="vertical"
            autoComplete="off"
            initialValues={{ variation: VARIATION_DEFAULT_OPTION }}
            onValuesChange={handleFormValueChanges}
          >
            <LinkVariations
              initialValue={updateLink?.type || "link"}
              onChange={(e) => {
                linkTypeChange(e);
              }}
            />
            <Form.Item
              name="variation"
              className="m-t-24"
              label={translation.formatMessage(messages.linkVariationText)}
              hidden={!isLinkSocialNetwork}
            >
              <Radio.Group
                buttonStyle="solid"
                size="large"
                onChange={async (e) => {
                  await setLinkVariation(() => {
                    return e.target.value;
                  });
                  linkTypeChange(linkType);
                }}
              >
                <Radio.Button value={LINK_VARIATIONS.ICON}>
                  <Space>
                    {translation.formatMessage(messages.withoutTextText)}
                  </Space>
                </Radio.Button>

                <Radio.Button value={LINK_VARIATIONS.BUTTON}>
                  <Space>
                    {translation.formatMessage(messages.withTextText)}
                  </Space>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="visible"
              label={translation.formatMessage(messages.visibleText)}
              initialValue="true"
              valuePropName="checked"
              className="link-switch"
            >
              <Switch valuePropName="checked"></Switch>
            </Form.Item>
            {["phone", "email"].some((category) => category === linkType) && (
              <Form.Item
                name="to_vcard"
                label={translation.formatMessage(messages.digitalCardText)}
                initialValue="true"
                valuePropName="checked"
                className="link-switch"
              >
                <Switch valuePropName="checked"></Switch>
              </Form.Item>
            )}
            {(!isLinkSocialNetwork ||
              (isLinkSocialNetwork &&
                linkVariation === LINK_VARIATIONS.BUTTON)) && (
              <Form.Item
                name="name"
                label={translation.formatMessage(messages.linkText)}
              >
                <Input type="text" />
              </Form.Item>
            )}
            {linkType !== "newsletter" &&
              linkType !== "upload" &&
              linkType !== "pdf-ai" &&
              linkType !== "product_and_services" && (
                <Form.Item name="link" label={<AddressText />}>
                  <Input type="text" />
                </Form.Item>
              )}
            {(linkType === "upload" || linkType === "pdf-ai") && (
              <div>
                <Form.Item
                  name="link"
                  label={translation.formatMessage(messages.uploadText)}
                >
                  <Input type="text" disabled={true} />
                </Form.Item>
                {linkType === "pdf-ai" && (
                  <Alert
                    type="info"
                    showIcon
                    className="m-b-24"
                    message={translation.formatMessage(
                      messages.AiSupportAssistantDescription
                    )}
                  />
                )}
                <Upload {...uploadProps}>
                  <Button type="primary" icon={<UploadOutlined />}>
                    {translation.formatMessage(messages.uploadText)}
                  </Button>
                </Upload>
              </div>
            )}
            {linkType == "email" && (
              <Form.Item name="subject" label="Email Subject">
                <Input type="text" />
              </Form.Item>
            )}
          </Form>
        </div>
        <div className="add-link-actions">
          <Button onClick={cancelEdit}>
            {translation.formatMessage(messages.cancel)}
          </Button>
          {["newsletter", "impressum",
          "product_and_services"].includes(linkType) && (
            <Button type="primary" onClick={goTo}>
              {translation.formatMessage(messages.goTo)}
            </Button>
          )}
          {!updateLink && (
            <Button type="primary" onClick={saveCard} loading={loadingState}>
              {translation.formatMessage(messages.addLink)}
            </Button>
          )}
          {updateLink && (
            <Button type="primary" onClick={updateLinkCard}>
              {translation.formatMessage(messages.updateLink)}
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default NewLink;

import React, { memo, useEffect, useState } from "react";
import { Card, Col, Row, Statistic, Typography } from "antd";
import { useSelector } from "react-redux";
import http from "../../utils/http";
import { getIntl } from "../../utils/intl";
import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";
import TopCountries from "./tables/TopCountries";
import { messages } from "./translations";

const { Title } = Typography;

const HubAnalytics = () => {
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const [hubAnalytics, setHubAnalytics] = useState(null);

  const intl = getIntl();

  const fetchHubAccess = async () => {
    let response = {};
    if (!hubAnalytics?.hubAccessDates) {
      let responseByDate = await http.get(
        `/company/${companyId}/analytics?type=wh_open_rate`
      );
      response = {
        ...response,
        hubAccessDates: responseByDate.data.payload,
      };
    }
    if (!hubAnalytics?.hubAccessCountries) {
      let responseByCountry = await http.get(
        `/company/${companyId}/analytics?type=wh_open_rate_country`
      );
      response = {
        ...response,
        hubAccessCountries: responseByCountry.data.payload,
      };
    }
    setHubAnalytics(response);
  };

  useEffect(() => {
    fetchHubAccess();
  }, []);

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Title level={1}>{intl.formatMessage(messages.pageTitle)}</Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="m-b-12">
        <Col xs={24} lg={6}>
          <Card title={intl.formatMessage(messages.counterTotalTitle)} bordered={false}>
            <Row>
              <Col xs={24} lg={12}>
                <Statistic
                  title={intl.formatMessage(messages.counterTotalSeven)}
                  value={
                    hubAnalytics?.hubAccessDates
                      ? Object.values(hubAnalytics.hubAccessDates)
                          .splice(
                            Object.values(hubAnalytics?.hubAccessDates).length -
                              7
                          )
                          .reduce((acc, val) => acc + val, 0)
                      : 0
                  }
                />
              </Col>
              <Col xs={24} lg={12}>
                <Statistic
                  title={intl.formatMessage(messages.counterTotalThirtyOne)}
                  value={
                    hubAnalytics?.hubAccessDates
                      ? Object.values(hubAnalytics.hubAccessDates).reduce(
                          (acc, val) => acc + val,
                          0
                        )
                      : 0
                  }
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="m-b-12">
        <Col xs={24} lg={12}>
          <Card title={intl.formatMessage(messages.lineChartSevenTitle)} bordered={false}>
            <LineChart
              data={hubAnalytics?.hubAccessDates}
              limit={7}
              title={intl.formatMessage(messages.lineChartSevenLegend)}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title={intl.formatMessage(messages.barCharttThirtyOneTitle)} bordered={false}>
            <BarChart data={hubAnalytics?.hubAccessDates} title={intl.formatMessage(messages.barCharttThirtyOneLegend)} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="m-b-12">
        <Col xs={24} lg={24}>
          <Card
            title={intl.formatMessage(messages.tableCountriesTitle)}
            bordered={false}
            style={{ height: "100%" }}
          >
            <TopCountries data={hubAnalytics?.hubAccessCountries} />
          </Card>
        </Col>

        {/* WAITING FOR DEVICE METRICS */}

        {/* <Col xs={24} lg={8}>
          <Card
            title="Page loads per device type (%)"
            bordered={false}
          >
            <DoughnutChart />
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default memo(HubAnalytics);

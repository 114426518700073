import * as React from "react"

function SvgComponent({id, props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.1 77.6" {...props}>
      <defs>
        <linearGradient
          id={`prefix__a04f38bb-dddd-4478-9415-239d9fdcbf53__logo__${id ? id : ''}`}
          x1={10.07}
          y1={-6.94}
          x2={48.54}
          y2={106.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ffd301" />
          <stop offset={0.21} stopColor="#f4ad19" />
          <stop offset={0.43} stopColor="#e98930" />
          <stop offset={0.65} stopColor="#e17040" />
          <stop offset={0.84} stopColor="#dd604a" />
          <stop offset={1} stopColor="#db5b4d" />
        </linearGradient>
        <linearGradient
          id={`prefix__ffa2e785-39c4-4be1-9b6e-a00bb9249a3d__logo__${id ? id : ''}`}
          x1={99.77}
          y1={-11.18}
          x2={59.6}
          y2={89.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.11} stopColor="#d7e2f1" />
          <stop offset={0.25} stopColor="#adc3e1" />
          <stop offset={0.4} stopColor="#8aa9d5" />
          <stop offset={0.54} stopColor="#6f95cb" />
          <stop offset={0.69} stopColor="#5b87c4" />
          <stop offset={0.83} stopColor="#507fbf" />
          <stop offset={0.99} stopColor="#4c7cbe" />
        </linearGradient>
        <linearGradient
          id={`prefix__a8e63d32-8d6d-4741-8aa2-a2b280486ead__logo__${id ? id : ''}`}
          x1={52.13}
          y1={89.12}
          x2={51.82}
          y2={-18.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.05} stopColor="#fbe2e2" />
          <stop offset={0.13} stopColor="#f6b5b5" />
          <stop offset={0.22} stopColor="#f18d8d" />
          <stop offset={0.32} stopColor="#ed6c6b" />
          <stop offset={0.42} stopColor="#ea5050" />
          <stop offset={0.52} stopColor="#e73b3a" />
          <stop offset={0.64} stopColor="#e52c2b" />
          <stop offset={0.78} stopColor="#e42423" />
          <stop offset={1} stopColor="#e42120" />
        </linearGradient>
        <style>
          {`.prefix__e5d7da7a-290a-4b9c-be99-1b83c1804dbf__logo__${id ? id : ''}{fill:#4c7cbe}`}
        </style>
      </defs>
      <g id={`prefix__e39bddeb-cf17-491e-917b-86e9fd858470__logo__${id ? id : ''}`} data-name="Camada 2">
        <g
          id={`prefix__bc681c7c-f361-43f3-a3a7-a5db114276d1__logo__${id ? id : ''}`}
          data-name="Camada 1"
        >
          <path
            d="M35.9 31.8a13.2 13.2 0 01.6 3.9 14 14 0 003.3 8.6.1.1 0 00.1.1L52 58.2l-9.3 10.4-3 3.5a14.1 14.1 0 01-8.9 4.6h-2.6a12.8 12.8 0 01-2.7-.5 15.1 15.1 0 01-3.5-1.4 15.6 15.6 0 01-5.1-4.8 11.7 11.7 0 01-1.8-3.9l-1.4-4.3-13-42.3A15 15 0 0110.6.7 13.5 13.5 0 0115 0a14.4 14.4 0 016.2 1.4l.8.3a15 15 0 017.4 8.9l6.3 20.5z"
            fill={`url(#prefix__a04f38bb-dddd-4478-9415-239d9fdcbf53__logo__${id ? id : ''})`}
          />
          <path
            d="M68 31.8a17.4 17.4 0 00-.5 3.9 14 14 0 01-3.3 8.6h-.1L52 58.2l9.2 10.4 3.1 3.5a13.8 13.8 0 008.8 4.6h2.6a12.6 12.6 0 002.8-.5 14.3 14.3 0 003.4-1.4 15.9 15.9 0 005.2-4.8 11.7 11.7 0 001.8-3.9l1.3-4.3 13.1-42.3A15.1 15.1 0 0093.4.7 13.9 13.9 0 0089 0a15.2 15.2 0 00-6.3 1.4l-.7.3a15 15 0 00-7.4 8.9l-6.4 20.5z"
            fill={`url(#prefix__ffa2e785-39c4-4be1-9b6e-a00bb9249a3d__logo__${id ? id : ''})`}
          />
          <path
            d="M63.1 43.3l-.3.4L52 55.9 40.9 43.3h-.1a12.5 12.5 0 01-3-9.5 12.8 12.8 0 014.2-8l2-1.8a12.1 12.1 0 018-3 12.3 12.3 0 018 3l2 1.8a12.4 12.4 0 014 7.4 12.2 12.2 0 01-2.9 10.1z"
            fill={`url(#prefix__a8e63d32-8d6d-4741-8aa2-a2b280486ead__logo__${id ? id : ''})`}
          />
          <circle cx={52} cy={36.8} r={2.1} fill="#fff" />
          <path d="M184.4 28l-12.6 38.5h-9.5l-8.5-26.1-8.8 26.1h-9.5L122.9 28h9.2l8.7 27 9.1-27h8.2l8.8 27.3 9-27.3zM214 54.1h-22.4c.9 3.7 4 5.9 8.5 5.9a10 10 0 007.4-2.8l4.6 4.9c-2.8 3.2-6.9 4.8-12.2 4.8-10.3 0-16.9-6.4-16.9-15.2s6.8-15.2 15.8-15.2 15.4 5.8 15.4 15.3a17.9 17.9 0 01-.2 2.3zm-22.5-5h14.6a7.1 7.1 0 00-7.3-6.2 7 7 0 00-7.3 6.2zM219.9 25.7h8.6v40.8h-8.6zM234.3 51.7c0-8.9 6.8-15.2 16.5-15.2 6.2 0 11.1 2.6 13.2 7.5l-6.6 3.6a7.5 7.5 0 00-6.7-4.1c-4.3 0-7.8 3-7.8 8.2s3.5 8.2 7.8 8.2a7.3 7.3 0 006.7-4.1l6.6 3.6c-2.1 4.8-7 7.5-13.2 7.5-9.7 0-16.5-6.3-16.5-15.2zM266.4 51.7c0-8.9 6.8-15.2 16.3-15.2s16.2 6.3 16.2 15.2-6.8 15.2-16.2 15.2-16.3-6.3-16.3-15.2zm23.8 0c0-5.1-3.3-8.2-7.5-8.2s-7.6 3.1-7.6 8.2 3.3 8.2 7.6 8.2 7.5-3.1 7.5-8.2zM354.6 49.5v17H346V50.9c0-4.8-2.1-7-5.6-7s-6.5 2.5-6.5 7.7v14.9h-8.6V50.9c0-4.8-2.1-7-5.6-7s-6.5 2.5-6.5 7.7v14.9h-8.6V36.9h8.2v3.4a12.1 12.1 0 019.3-3.8c4.1 0 7.6 1.5 9.7 4.8a13.4 13.4 0 0110.7-4.8c7 0 12.1 4 12.1 13zM391.1 36.9l-13.4 31.4c-2.8 7.1-6.9 9.3-12.2 9.3a13.5 13.5 0 01-8.3-2.7l3.2-6.1a7.8 7.8 0 004.8 1.9c2.4 0 3.7-1.1 4.8-3.7l.2-.3-12.9-29.8h8.9l8.3 20.1 8.4-20.1z" />
          <path
            className={`prefix__e5d7da7a-290a-4b9c-be99-1b83c1804dbf__logo__${id ? id : ''}`}
            d="M184.4 28l-12.6 38.5h-9.5l-8.5-26.1-8.8 26.1h-9.5L122.9 28h9.2l8.7 27 9.1-27h8.2l8.8 27.3 9-27.3zM214 54.1h-22.4c.9 3.7 4 5.9 8.5 5.9a10 10 0 007.4-2.8l4.6 4.9c-2.8 3.2-6.9 4.8-12.2 4.8-10.3 0-16.9-6.4-16.9-15.2s6.8-15.2 15.8-15.2 15.4 5.8 15.4 15.3a17.9 17.9 0 01-.2 2.3zm-22.5-5h14.6a7.1 7.1 0 00-7.3-6.2 7 7 0 00-7.3 6.2zM219.9 25.7h8.6v40.8h-8.6zM234.3 51.7c0-8.9 6.8-15.2 16.5-15.2 6.2 0 11.1 2.6 13.2 7.5l-6.6 3.6a7.5 7.5 0 00-6.7-4.1c-4.3 0-7.8 3-7.8 8.2s3.5 8.2 7.8 8.2a7.3 7.3 0 006.7-4.1l6.6 3.6c-2.1 4.8-7 7.5-13.2 7.5-9.7 0-16.5-6.3-16.5-15.2zM266.4 51.7c0-8.9 6.8-15.2 16.3-15.2s16.2 6.3 16.2 15.2-6.8 15.2-16.2 15.2-16.3-6.3-16.3-15.2zm23.8 0c0-5.1-3.3-8.2-7.5-8.2s-7.6 3.1-7.6 8.2 3.3 8.2 7.6 8.2 7.5-3.1 7.5-8.2zM354.6 49.5v17H346V50.9c0-4.8-2.1-7-5.6-7s-6.5 2.5-6.5 7.7v14.9h-8.6V50.9c0-4.8-2.1-7-5.6-7s-6.5 2.5-6.5 7.7v14.9h-8.6V36.9h8.2v3.4a12.1 12.1 0 019.3-3.8c4.1 0 7.6 1.5 9.7 4.8a13.4 13.4 0 0110.7-4.8c7 0 12.1 4 12.1 13zM391.1 36.9l-13.4 31.4c-2.8 7.1-6.9 9.3-12.2 9.3a13.5 13.5 0 01-8.3-2.7l3.2-6.1a7.8 7.8 0 004.8 1.9c2.4 0 3.7-1.1 4.8-3.7l.2-.3-12.9-29.8h8.9l8.3 20.1 8.4-20.1z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

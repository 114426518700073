import { Button } from "antd";
import React, { useState } from "react";
import Workspaces from "../workspaces";
import CreateWorkspaceModal from "../../components/CreateWorkspaceModal";
import { getIntl } from "../../utils/intl";

function SubAccounts(props) {
  const [createWorkSpaceModalVisible, setcreateWorkSpaceModalVisible] =
    useState(false);
  const addWorkSpaceModal = () => {
    setcreateWorkSpaceModalVisible(true);
  };
  const handleOnCancelCreateWorkspace = () => {
    setcreateWorkSpaceModalVisible(false);
  };

  const intl = getIntl();
  const createWorkspaceButton = intl.formatMessage({
    id: "SubAccounts.CreateWorkspace.Button.Text",
    defaultMessage: "Kundenzugang erstellen"
  });
  const workspaceTitle = intl.formatMessage({
    id: "SubAccounts.CreateWorkspace.Title",
    defaultMessage: "Übersicht Ihrer Hubs"
  });
  const workspaceDescription = intl.formatMessage({
    id: "SubAccounts.CreateWorkspace.Description",
    defaultMessage: "Hier können Sie Ihre Hubs verwalten und neue Zugänge erstellen."
  });


  return (
    <div className="sub-accounts__wrapper">
      <CreateWorkspaceModal
        visible={createWorkSpaceModalVisible}
        onCancel={handleOnCancelCreateWorkspace}
      ></CreateWorkspaceModal>
      <div className="workspace-header">
        <h1>
          {workspaceTitle}
          <span className="block-type">
            {workspaceDescription}
          </span>
        </h1>

        <Button type="primary" onClick={addWorkSpaceModal}>
          {createWorkspaceButton}
        </Button>
      </div>
      <Workspaces/>
    </div>
  );
}

export default SubAccounts;


import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  downloadText: {
    id: "SideMenu.Cards.Download.Text",
    defaultMessage: "Hub-Zugang herunterladen:",
  },
  downloadSubtext: {
    id: "SideMenu.Cards.Download.Subtext",
    defaultMessage: "QR-Code",
  },
  downloadAction: {
    id: "SideMenu.Cards.Download.Action",
    defaultMessage: "Herunterladen",
  },
  copyText: {
    id: "SideMenu.Cards.Copy.Text",
    defaultMessage: "Hub-Zugang kopieren:",
  },
  copySubtext: {
    id: "SideMenu.Cards.Copy.Subtext",
    defaultMessage: "Kurz URL",
  },
  copyAction: {
    id: "SideMenu.Cards.Copy.Action",
    defaultMessage: "Kopieren",
  },
  copyActionSuccess: {
    id: "SideMenu.Cards.Copy.Action.Success",
    defaultMessage: "Kopiert!",
  },
  copyActionError: {
    id: "SideMenu.Cards.Copy.Action.Error",
    defaultMessage: "Die Kurz-URL konnte nicht kopiert werden, bitte kontaktieren Sie den Support!",
  },

  // WHEN COPY COMMAND IS NOT ALLOWED IN BROWSER
  copyGenerateBtn: {
    id: "SideMenu.QuickAcions.ShortUrl.Generate",
    defaultMessage: "Anzeigen",
  },
    modalCloseBtn: {
    id: "SideMenu.QuickAcions.Modal.ShortUrl.Close",
    defaultMessage: "Schließen",
  },
  modalDisclaimer: {
    id: "SideMenu.QuickAcions.Modal.ShortUrl.Disclaimer",
    defaultMessage: "Teilen Sie diese personalisierte URL mit Ihren Nutzern, um auf den Hub zuzugreifen, ohne einen QR-Code zu scannen.",
  },
  modalTitle: {
    id: "SideMenu.QuickAcions.Modal.ShortUrl.Title",
    defaultMessage: "Die Kurz-URL der Hub",
  },
});

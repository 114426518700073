import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { digitalMenuDishesSetter } from "../../redux/actions";
import http, { getErrorMsg } from "../../utils/http";
import { displayNotification } from "../../components/Notification";
import { getIntl } from "../../utils/intl";
import { useSpiceLevels } from "./SpiceLevelCtx";
import Chilli from "../../static/Chili";
/** Start Image Crop Imports */
import ImgCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";
/** End Image Crop Imports */

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Rate,
  Row,
  Upload,
  Switch,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { uploadImages } from "../../utils/image";

function CreateDish({
  isOpen,
  showModal,
  setDishToEdit,
  dishToEdit,
  ...props
}) {
  const dispatch = useDispatch();
  const dishCategory = useSelector((state) => state.dishConfig.category);
  const company_id = useSelector((state) => state.companyConfig.company_id);
  const verticalId = useSelector((state) => state.companyConfig.vertical_id);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const spiceLevels = useSpiceLevels();
  const intl = getIntl();
  const dishes = useSelector((state) => state.digitalMenuConfig.dishes);
  const [isMultiPricing, setIsMultiPricing] = useState(null);
  const screens = useBreakpoint();

  const [form] = Form.useForm();

  useEffect(() => {
    if (dishCategory && dishCategory.id) {
      setCategoryId(dishCategory.id);
    }
  }, [dishCategory]);

  const getFileList = () => {
    if (
      dishToEdit !== null &&
      dishToEdit.images &&
      dishToEdit.images.length > 0
    ) {
      const result = dishToEdit.images.map((img, index) => ({
        uid: ((index + 1) * -1).toString(),
        url: img,
      }));
      return result;
    }
    return [];
  };

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (
      dishToEdit !== null &&
      dishToEdit.images &&
      dishToEdit.images.length > 0 &&
      fileList.length === 0
    ) {
      const files = getFileList();
      setFileList(files);
    }
  }, [dishToEdit]);

  const [allergens, setAllergens] = useState([]);
  const [preservatives, setPreservatives] = useState([]);
  const getAllergens = async () => {
    const response = await http.get(`gastro/${company_id}/allergen`);
    let { payload } = response.data;
    const _allergens = payload.reduce((acc, allergen) => {
      if (!allergen.is_preservative) {
        acc.push({ label: allergen.name, value: allergen.id });
      }
      return acc;
    }, []);

    const _preservatives = payload.reduce((acc, preservative) => {
      if (preservative.is_preservative) {
        acc.push({ label: preservative.name, value: preservative.id });
      }
      return acc;
    }, []);
    setAllergens(_allergens);
    setPreservatives(_preservatives);
  };
  useEffect(() => {
    if (verticalId === 3) getAllergens();
  }, [company_id]);

  const cancel = (...arg) => {
    setFileList([]);
    form.resetFields();
    setDishToEdit(null);
    showModal(false);
  };

  const showLoading = (loadingState) => {
    setIsLoading(loadingState);
  };

  const resetImagesList = async () => {
    await setFileList([]);
  };

  const onCreate = async (values) => {
    showLoading(true);
    let data = {};
    let title, message, status;
    const fields = [
      "name",
      "price",
      "availability",
      "description",
      "allergens", // Preservatives are part of the allergens group
      "spice_level",
      "preservatives",
      "multiprice",
    ];
    if (!values.errorFields) {
      fields.map((field, index) => {
        let value = values[field];
        if (value !== undefined) {
          if (Array.isArray(value) && value.length > 0) {
            if (field === "preservatives") {
              if (values["allergens"] !== undefined) {
                value = values["allergens"].concat(value);
                value = JSON.stringify(value);
                data["allergens"] = value;
                return;
              }
            }
            if (field === "multiprice" && values[field] !== undefined) {
              let priceObj = {};
              values[field].forEach((el) => {
                priceObj[el.priceLabel] = el.priceValue;
              });
              value = JSON.stringify(priceObj);
              data["price"] = value;
              values.price = values[field];
              delete values[field];
              return;
            }
            value = JSON.stringify(value);
            data[field] = value;
          } else if (!Array.isArray(value)) {
            if (field === "price" && values[field] !== undefined) {
              const price = { default: value };
              value = JSON.stringify(price);
              values.price = price;
            }
            data[field] = value;
          }
        }
      });

      if (fileList && fileList.length > 0) {
        let allImages = [], imageUrls = [];
        fileList.map((file) => {
          allImages.push(file);
        });

        if (allImages.length > 0) {
          imageUrls = await uploadImages(company_id, allImages, 'category/dish');
          if (imageUrls.length == 0) {
            status = "error";
            const title = intl.formatMessage({
              id: "CreateDishPage.Detail.Notification.ImageUploadError.Title",
              defaultMessage: "Entschuldigung! Sie können keine Bilder hochladen"
            });
            const message = intl.formatMessage({
              id: "CreateDishPage.Detail.Notification.Success.Body",
              defaultMessage: "Ihr Gericht wurde erfolgreich erstellt",
            });
            setTimeout(() => {
              displayNotification(status, title, message);
              showModal(false);
              showLoading(false);
              form.resetFields();
              setDishToEdit(null);
              resetImagesList();
            }, 2000);
            return;
          }
        }
        data["images"] = JSON.stringify(imageUrls);
      }

      try {
        const res = await http.post(
          `gastro/${company_id}/category/${categoryId}/dish`,
          data
        );
        let status = "success";
        let title = intl.formatMessage({
          id: "Success",
          defaultMessage: "Erfolg!",
        });
        let message = intl.formatMessage({
          id: "CreateDishPage.Detail.Notification.Success.Body",
          defaultMessage: "Ihr Gericht wurde erfolgreich erstellt",
        });
        const dish = {
          id: res.data.payload.id,
          name: values.name,
          price: values.price,
          availability: values.availability,
          description: values.description,
          allergens: values.allergens,
          spice_level: values.spice_level,
          images: res.data.payload.images,
          preservatives: values.preservatives,
        };

        dishes[dishCategory.index].push(dish);
        dispatch(digitalMenuDishesSetter(dishes));
        setTimeout(() => {
          displayNotification(status, title, message);
          showModal(false);
          showLoading(false);
          form.resetFields();
          setDishToEdit(null);
          resetImagesList();
        }, 2000);
      } catch (error) {
        console.log("Error", error);
        status = "error";
        title = intl.formatMessage({
          id: "CreateDishPage.Detail.Notification.Error.Title",
          defaultMessage: "Gericht konnte nicht erstellt werden",
        });
        message = getErrorMsg(error, intl, false, true);
        setTimeout(() => {
          displayNotification(status, title, message);
          showModal(false);
          showLoading(false);
          form.resetFields();
          setDishToEdit(null);
          resetImagesList();
        }, 2000);
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const removeFile = (file) => {
    const files = fileList.filter((aFile) => aFile.uid !== file.uid);
    setFileList(files);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = async (file) => {
    file.thumbUrl = await getBase64(file);
    setFileList([file, ...fileList]);
    return false;
  };

  const deleteDish = async () => {
    try {
      const response = await http.delete(
        `gastro/${company_id}/category/${categoryId}/dish/${dishToEdit.id}`
      );
      dishes[dishCategory.index] = dishes[dishCategory.index].filter(
        (dish) => dish.id !== dishToEdit.id
      );
      dispatch(digitalMenuDishesSetter(dishes));
      showModal(false);
      setDishToEdit(null);
      resetImagesList();
      form.resetFields();
      const title = intl.formatMessage({
        id: "OrganizeMenuPage.DeleteItem.Success.Title",
        defaultMessage: "Erfolg!",
      });
      const body = intl.formatMessage({
        id: "OrganizeMenuPage.DeleteItem.Success.Body",
        defaultMessage: "Der Menüpunkt wurde erfolgreich gelöscht",
      });
      displayNotification("success", title, body);
    } catch (error) {
      console.error(`Unable to delete dish ${dishToEdit.id}`, error);
      const title = intl.formatMessage({
        id: "OrganizeMenuPage.DeleteItem.Error.Title",
        defaultMessage: "Der Löschvorgang ist fehlgeschlagen",
      });
      const message = getErrorMsg(error, intl, false, true);
      displayNotification("error", title, message);
    }
  };

  const editDish = async (values) => {
    showLoading(true);
    try {
      let data = {};
      const fields = Object.keys(values);
      if (!values.errorFields) {
        fields.map((field) => {
          let value = values[field];
          if (value !== undefined) {
            if (Array.isArray(value)) {
              if (field === "preservatives") {
                if (values["allergens"] !== undefined) {
                  value = values["allergens"].concat(value);
                  value = JSON.stringify(value);
                  data["allergens"] = value;
                  return;
                }
              }
              if (field === "multiprice" && values[field] !== undefined) {
                let priceObj = {};
                values[field].forEach((el) => {
                  priceObj[el.priceLabel] = el.priceValue;
                });
                value = JSON.stringify(priceObj);
                data["price"] = value;
                values.price = values[field];
                delete values[field];
                return;
              }
              value = JSON.stringify(value);
            }
            if (field === "price" && values[field] !== undefined) {
              const price = { default: value };
              value = JSON.stringify(price);
              values.price = price;
            }
            data[field] = value;
          }
        });

        if (fileList) {
          let allImages = [], imageUrls = [];
          fileList.map((file) => {
            allImages.push(file);
          });
          if (allImages.length > 0) {
            imageUrls = await uploadImages(company_id, allImages, 'category/dish');
            if (imageUrls.length == 0) {
              const title = intl.formatMessage({
                id: "CreateDishPage.Detail.Notification.ImageUploadError.Title",
                defaultMessage: "Entschuldigung! Sie können keine Bilder hochladen"
              });
              const message = intl.formatMessage({
                id: "CreateDishPage.Detail.Notification.Success.Body",
                defaultMessage: "Ihr Gericht wurde erfolgreich erstellt",
              });
              setTimeout(() => {
                displayNotification("error", title, message);
                showModal(false);
                showLoading(false);
                form.resetFields();
                setDishToEdit(null);
                resetImagesList();
              }, 2000);
              return;
            }
          }
          data["images"] = JSON.stringify(imageUrls);
        }

        const response = await http.put(
          `gastro/${company_id}/category/${categoryId}/dish/${dishToEdit.id}`,
          data
        );
        dishes[dishCategory.index] = dishes[dishCategory.index].map((dish) => {
          if (dish.id === dishToEdit.id) {
            dish = {
              ...dish,
              ...values,
              images: response.data.payload.images,
            };
          }
          return dish;
        });
        dispatch(digitalMenuDishesSetter(dishes));
        const title = intl.formatMessage({
          id: "OrganizeMenuPage.EditItem.Success.Title",
          defaultMessage: "Erfolg!",
        });
        const body = intl.formatMessage({
          id: "OrganizeMenuPage.EditItem.Success.Body",
          defaultMessage: "Ihre Änderung warerfolgreich",
        });

        setTimeout(() => {
          displayNotification("success", title, body);
          showModal(false);
          showLoading(false);
          form.resetFields();
          setDishToEdit(null);
          resetImagesList();
        }, 2000);
      }
    } catch (error) {
      console.error(`Unable to update dish: ${dishToEdit.id}`);
      const title = intl.formatMessage({
        id: "OrganizeMenuPage.EditItem.Error.Title",
        defaultMessage: "Die Änderung war nicht erfolgreich",
      });
      const message = getErrorMsg(error, intl, false, true);
      setTimeout(() => {
        displayNotification("error", title, message);
        showModal(false);
        showLoading(false);
        form.resetFields();
        setDishToEdit(null);
        resetImagesList();
      }, 2000);
    }
  };

  const modalHeader = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Header",
    defaultMessage: "Menüpunkt erstellen",
  });
  const nameLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Name",
    defaultMessage: "Name",
  });
  const availableLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Available",
    defaultMessage: "Verfügbar",
  });
  const priceLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Price",
    defaultMessage: "Preis",
  });
  const descriptionLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Description",
    defaultMessage: "Beschreibung",
  });
  const allergensLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Allergens",
    defaultMessage: "Allergene",
  });
  const preservativesLabel = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Preservatives",
    defaultMessage: "Inhaltsstoffe",
  });
  const discardText = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Button.Discard",
    defaultMessage: "Verwerfen",
  });
  const saveText = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Button.Save",
    defaultMessage: "Bestätigen",
  });
  const updateText = intl.formatMessage({
    id: "Button.Update",
    defaultMessage: "Updaten",
  });
  const spiceText = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Spice",
    defaultMessage: "Schärfegrad",
  });

  const multiPricing = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.MultiPricing",
    defaultMessage: "Mehrfache Preisoptionen"
  });

  const addPrice = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.Button.AddPrice",
    defaultMessage: "Produktvariante hinzufügen"
  });

  const priceLabelText = intl.formatMessage({
    id: "OrganizeMenuPage.DishModal.MultiPricing.Price.Label",
    defaultMessage: "Bezeichnung"
  });

  const createInitialValues = (is_new_dish) => {
    const isSinglePrice = () => {
      if (dishToEdit !== null && dishToEdit.price instanceof Object) {
        const labels = Object.keys(dishToEdit.price);
        return labels.length === 1;
      }
      return false;
    };

    const makePrice = () => {
      if (dishToEdit !== null && dishToEdit.price instanceof Object) {
        /**
         * If the user just created/updated this dish, reopening will make
         * the multipricing object to be parsed again. Letting us with 
         * { priceLabel: label, priceValue: {
         *    priceLabel: label, priceValue: dishToEdit.price[label]
         *   }
         * }
         * This is here to prevent it
         */
        if (dishToEdit.price instanceof Array) {
          return dishToEdit.price;
        }
        const labels = Object.keys(dishToEdit.price);
        // A single price dish would return an object with {default : price}
        if (labels.length === 1) return dishToEdit.price[labels[0]];
        // It is multi-pricing
        return labels.map((label) => {
          let a = { priceLabel: label, priceValue: dishToEdit.price[label] };
          return a;
        });
      }
    };

    const values = {
      remmeber: false,
      name: dishToEdit !== null ? dishToEdit.name : "",
      availability: dishToEdit !== null ? dishToEdit.availability : true,
      description: dishToEdit !== null ? dishToEdit.description : "",
      allergens: dishToEdit !== null ? dishToEdit.allergens : [],
      preservatives: dishToEdit !== null ? dishToEdit.preservatives : [],
      spice_level: dishToEdit !== null ? dishToEdit.spice_level : 0,
    };

    if (is_new_dish) {
      values.price = 0.0;
      values.multiprice = [
        { priceLabel: "", priceValue: 0 },
        { priceLabel: "", priceValue: 0 },
      ];
      setIsMultiPricing(false);
    }

    if (dishToEdit !== null) {
      if (isSinglePrice()) {
        setIsMultiPricing(false);
        values.price = makePrice();
        values.multiprice = [
          { priceLabel: "", priceValue: 0 },
          { priceLabel: "", priceValue: 0 },
        ];
      } else {
        values.multiprice = makePrice();
        if (!isMultiPricing) setIsMultiPricing(true);
      }
    }

    form.setFieldsValue(values);
  };

  useEffect(() => {
    if (dishToEdit !== null) {
      createInitialValues();
    } else {
      createInitialValues(true);
      setIsMultiPricing(null)
    }
  }, [isOpen])

  return (
    <Modal
      centered
      width="65%"
      visible={isOpen}
      zIndex={999}
      title={modalHeader}
      cancelText={discardText}
      okText={dishToEdit === null ? saveText : updateText}
      onCancel={cancel}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading }}
      forceRender={true}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (dishToEdit === null) {
              onCreate(values);
            } else {
              editDish(values);
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Row>
        <Col xs={24}>
          <Form
            form={form}
            name="create-dish"
            layout="vertical"
            preserve={false}
          >
            <Row gutter={16}>
              <Col xs={24} md={16}>
                <Form.Item
                  label={nameLabel}
                  name="name"
                  preserve={false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your a dish name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={availableLabel}
                  name="availability"
                  initialValue={
                    dishToEdit !== null ? dishToEdit.availability : true
                  }
                  valuePropName="checked"
                  preserve={false}
                >
                  <Switch valuePropName="checked"></Switch>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <ImgCrop>
                  <Upload
                    beforeUpload={beforeUpload}
                    customRequest={() => null}
                    listType="picture-card"
                    fileList={fileList}
                    // onPreview={this.handlePreview}
                    onRemove={removeFile}
                  >
                    {fileList.length >= 5 ? null : uploadButton}
                  </Upload>
                </ImgCrop>
              </Col>
            </Row>
            <Row gutter={16} className="m-b-12">
              <Col xs={24} md={14}>
                <Switch
                  checkedChildren={multiPricing}
                  unCheckedChildren={multiPricing}
                  checked={isMultiPricing}
                  onChange={() => setIsMultiPricing(!isMultiPricing)}
                />
              </Col>
            </Row>
            {!isMultiPricing && (
              <Row gutter={16}>
                <Col xs={24} md={14}>
                  <Form.Item
                    label={priceLabel}
                    name="price"
                    preserve={false}
                    rules={[
                      {
                        required: true,
                        message: "Please input a price!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isMultiPricing && (
              <Form.List name="multiprice">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row gutter={{ xs: 0, md: 16 }} key={key}>
                        <Col xs={24} md={5}>
                          <Form.Item
                            {...restField}
                            label={priceLabelText}
                            name={[name, "priceLabel"]}
                            fieldKey={[fieldKey, "priceLabel"]}
                            preserve={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input a label!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                          <Form.Item
                            {...restField}
                            label={priceLabel}
                            name={[name, "priceValue"]}
                            fieldKey={[fieldKey, "priceValue"]}
                            preserve={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input a price!",
                              },
                            ]}
                          >
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        {key > 1 &&
                          <Col xs={24} md={3}>
                            <Button
                              className={screens.md ? "m-t-32" : "m-b-32"}
                              block={!screens.md}
                              type="primary"
                              danger
                              onClick={() => remove(name)}
                            >
                              {" "}
                              -{" "}
                            </Button>
                          </Col>
                        }
                      </Row>
                    ))}
                    <Row>
                      <Col xs={24} md={13}>
                        <Button
                          type="primary"
                          className="m-b-6"
                          block
                          onClick={() => add()}
                        >
                          {addPrice}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            )}
            <Row>
              <Col xs={24} md={16}>
                <Form.Item
                  name={"description"}
                  label={descriptionLabel}
                  preserve={false}
                  className="all-white-wpaces-preserved"
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            {
              (verticalId === 3) &&
                <Row>
                  <Col xs={24} md={5}>
                    <Form.Item
                      label={spiceText}
                      name="spice_level"
                      preserve={false}
                    >
                      <Rate
                        allowClear
                        defaultValue={0}
                        count={spiceLevels.length}
                        tooltips={spiceLevels.map((level) => level.name)}
                        className="chilli"
                        character={<Chilli />}
                      />
                    </Form.Item>
                  </Col>
              </Row>
            }
            {
              (verticalId === 3) &&
              <Row>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="allergens"
                    label={allergensLabel}
                    valuePropName="value"
                  >
                    <Checkbox.Group
                      options={allergens}
                      defaultValue={
                        dishToEdit !== null ? dishToEdit.allergens : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="preservatives"
                    label={preservativesLabel}
                    valuePropName="value"
                  >
                    <Checkbox.Group
                      options={preservatives}
                      defaultValue={
                        dishToEdit !== null ? dishToEdit.preservatives : []
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            }
            {dishToEdit !== null && (
              <Row>
                <Col xs={24}>
                  <Button type="primary" danger onClick={deleteDish}>
                    <FormattedMessage
                      id="OrganizeMenuPage.DishModal.Button.Create"
                      defaultMessage="Menüpunkt löschen"
                    ></FormattedMessage>
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

CreateDish.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "createDish",
};

export default React.memo(CreateDish);

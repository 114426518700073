import React from 'react';
import dsgvo from "../../../../../static/certifications/white-background/dsgvo_cert.png";
import hostedInGermany from "../../../../../static/certifications/white-background/hosted_in_germany.png";

function Certifications(props) {
  return (
    <div className="certification-icons">
      <img src={dsgvo} />
      <img src={hostedInGermany} />
    </div>
  );
}

export default Certifications;

import http from "../utils/http";

export const getUsersApi = (companyId) => {
  return http.get(`/company/${companyId}/user`);
};

export const deleteUserApi = (userId, companyId) => {
  return http.delete(`/company/${companyId}/user/${userId}`);
};

export const updateInvitedUserApi = (userId, companyId, role) => {
  return http.put(`/company/${companyId}/user/${userId}`, {
    ...role,
  });
};

export const inviteUserApi = (companyId, user) => {
  return http.post(`/company/${companyId}/user`, {
    ...user,
    company_id: companyId,
  });
};

import jsPDF from "jspdf";
import QRtemplate from "../../static/qr_code_template.png";
import { getIntl } from "../../utils/intl";
import { displayNotification } from "../Notification";
import http from "../../utils/http";


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const newDimensions = (width, height, maxWidth=50, maxHeight=50) => {
  let ratio = 0;  // Used for aspect ratio

  let newWidth = width, newHeight = height;

  // Check if the current width is larger than the max
  if(width > maxWidth){
      ratio = maxWidth / width;   // get ratio for scaling image
      newWidth = maxWidth; // Set new width
      newHeight = height * ratio;  // Scale height based on ratio

      height = height * ratio;    // Reset height to match scaled image
      width = width * ratio;    // Reset width to match scaled image
  }

  // Check if current height is larger than max
  if(height > maxHeight){
      ratio = maxHeight / height; // get ratio for scaling image
      newHeight = maxHeight;   // Set new height
      newWidth = width * ratio;    // Scale width based on ratio

      width = width * ratio;    // Reset width to match scaled image
      height = height * ratio;    // Reset height to match scaled image
  }
  return [ parseInt(newWidth.toFixed(0)), parseInt(newHeight.toFixed(0))];

} 


 /**
  * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
  * images to fit into a certain area.
  *
  * @param {Number} srcWidth width of source image
  * @param {Number} srcHeight height of source image
  * @param {Number} maxWidth maximum available width
  * @param {Number} maxHeight maximum available height
  * @return {Object} { width, height }
  */
 export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth*ratio, height: srcHeight*ratio };
}


export const generateQRCodeTemplate = async (company_logo, short_link, toggleLoading) => {
  const intl = getIntl();

  toggleLoading();
  let logo;
  try {
    logo = await http.get(company_logo, {
      responseType: "blob",
    });

    // QR CODE is hidden in the DOM in order to make it work.
    const canvas = document.getElementById("qr-code-to-pdf");
    const qrDraw = canvas.toDataURL("image/jpeg", 1);

    // Default export is a4 paper, portrait, using millimeters for units
    const mime = logo.data.type;
    getBase64(logo.data, (b64) => {
      let img = new Image();
      img.onload = function () {
        const [logoWidth, logoHeight] = newDimensions(
          img.width,
          img.height,
          40,
          40
        );
        const [qrWidth, qrHeight] = newDimensions(800, 800, 180, 240);

        const doc = new jsPDF();
        doc.addImage(QRtemplate, "PNG", 0, 0, 210, 297);

        doc.addImage(img, mime, 105 - logoWidth / 2, 25, logoWidth, logoHeight);
        doc.addImage(
          qrDraw,
          "image/jpeg",
          105 - qrWidth / 2,
          70,
          qrWidth,
          qrHeight
        );
        if (short_link) {
          doc.setFont("Helvetica", "normal", "normal");
          doc.setFontSize(28);
          doc.text("Oder nutzen Sie den folgenden Link:", 27, qrHeight + 81);
          doc.setFont("Helvetica", "bold");
          doc.text(short_link, 50, qrHeight + 95);
        }
        doc.save("Welcomy - QR Code.pdf");
        toggleLoading();
      };

      img.src = b64;
    });
  } catch (error) {
    const errorTitle = intl.formatMessage({
      id: "Error",
      defaultMessage: "Etwas ist schief gelaufen!",
    });
    console.log(error);
    toggleLoading(false);
    displayNotification("error", errorTitle, "");
  }
};

export const generateSimpleQrCode = async () => {
  // QR CODE is hidden in the DOM in order to make it work.
  const canvas = document.getElementById("qr-code-to-pdf");
  const qrDraw = canvas.toDataURL("image/jpeg", 1);
  const [qrWidth, qrHeight] = newDimensions(400, 400, 80, 140);

  const doc = new jsPDF();
  doc.addImage(
    qrDraw,
    "image/jpeg",
    105 - qrWidth / 2,
    70,
    qrWidth,
    qrHeight
  );

  doc.save("Welcomy - QR Code.pdf");

};




import { setConfig as configSetter } from "./companyConfig";
import { setDish as dishSetter } from "./dishSetter";
import {
  setDigitalMenuCategories as digitalMenuCatsSetter,
  setDigitalMenuDishes as digitalMenuDishesSetter,
  setDigitalMenuType as digitalMenuTypeSetter,
  setDigitalMenuUrl as digitalMenuUrlSetter,
  setDigitalMenuSearch as digitalMenuSearchSetter,
  showDishesFilterModal,
  setAllergenPreservativesFilter,
  setSpiceFilter,
} from "./DigitalMenuSetter";
import { setRichDocumentSaved } from "./editorsSetter";
import { selectQrExportType } from "./qrCodeExport";
import { setPdfConversation } from "./pdfAI";
import { setHubConfig } from './hubConfig';

export {
  configSetter,
  dishSetter,
  digitalMenuCatsSetter,
  digitalMenuDishesSetter,
  digitalMenuTypeSetter,
  digitalMenuUrlSetter,
  digitalMenuSearchSetter,
  showDishesFilterModal,
  selectQrExportType,
  setAllergenPreservativesFilter,
  setHubConfig,
  setSpiceFilter,
  setRichDocumentSaved,
  setPdfConversation,
};

import React, { useState, useEffect } from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Table,
    Tag,
    Typography,
  } from "antd";

import http from '../../utils/http';
import { displayNotification } from '../../components/Notification';
import { getIntl } from "../../utils/intl";

import Modal from '../../components/Modal';

const { Title, Paragraph } = Typography;

function CoronaAlerts(props) {
    const company_id = useSelector((state) => state.companyConfig.company_id);
    const [notifyModal, setNotifyModal] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [newAlerts, setNewAlerts] = useState([]);
    const [oldAlerts, setOldAlerts] = useState([]);

    const [toVerify, setToVerify] = useState(null);

    const intl = getIntl();

    useEffect(() => {
      fetchAlerts()
    }, [company_id]);

    const fetchAlerts = async () => {
      if (company_id) {

        try {
          const response = await http.get(`/company/${company_id}/emergencies`);

          if (response.status === 200) {
            const alerts = response.data.payload;
            setNewAlerts(alerts.active_emergencies);
            setOldAlerts(alerts.past_emergencies);
          } else {
            const title = intl.formatMessage({
              id: "Error",
              defaultMessage: "Etwas ist schief gelaufen!",
            });
            const body = intl.formatMessage({
              id: "AlertPage.Notification.Error",
              defaultMessage: "Not able to retrieve alerts from server.",
            });
            displayNotification("error", title, body);
          }
        } catch (error) {
          const title = intl.formatMessage({
            id: "Error",
            defaultMessage: "Etwas ist schief gelaufen!",
          });
          const body = intl.formatMessage({
            id: "AlertPage.Notification.Error",
            defaultMessage: "Not able to retrieve alerts from server.",
          });
          displayNotification("error", title, body);
        }
      }
    };

    const verifyAlert = async () => {
      try {
        const response = await http.put(`/company/${company_id}/emergency/${toVerify.id}`, {
          verified: true,
        });

        if (response.status === 204) {
          const title = intl.formatMessage({
            id: 'Success',
            defaultMessage: 'Erfolg!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Verify.Success',
            defaultMessage: 'Zeitlich relevante Aufenthaltsorte der Person wurden über Ihre Meldungsbestätigung informiert.',
          });
          displayNotification('success', title, body);
          fetchAlerts();
        } else {
          const title = intl.formatMessage({
            id: 'Error',
            defaultMessage: 'Etwas ist schief gelaufen!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Verify.Error',
            defaultMessage: 'Meldung konnte nicht bestätigt werden, versuchen Sie es später noch einmal.',
          });
          displayNotification('error', title, body);
        }
        setVerifyModal(false);
      } catch (error) {
        setVerifyModal(false);
        const title = intl.formatMessage({
          id: "Error",
          defaultMessage: "Etwas ist schief gelaufen!",
        });
        const body = intl.formatMessage({
          id: 'AlertPage.Notification.Verify.Error',
          defaultMessage: 'Meldung konnte nicht bestätigt werden, versuchen Sie es später noch einmal.',
        });
        displayNotification('error', title, body);
      }
    }

    const resolveAlert = async (alert) => {
      try {
        const response = await http.put(`/company/${company_id}/emergency/${alert.id}`, {
          resolved: true,
        });

        if (response.status === 204) {
          const title = intl.formatMessage({
            id: 'Success',
            defaultMessage: 'Erfolg!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Resolve.Success',
            defaultMessage: 'Diese Meldung wurde als gelöst markiert und in der Rubrik "Bearbeitete Meldungen" verschoben',
          });
          displayNotification('success', title, body);
          fetchAlerts();
        } else {
          const title = intl.formatMessage({
            id: 'Error',
            defaultMessage: 'Etwas ist schief gelaufen!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Resolve.Error',
            defaultMessage: "Meldung konnte nicht als gelöst markiert werden, versuchen Sie es später noch einmal",
          });
          displayNotification('error', title, body);
        }
      } catch (error) {
        const title = intl.formatMessage({
          id: 'Error',
          defaultMessage: 'Etwas ist schief gelaufen!',
        });
        const body = intl.formatMessage({
          id: 'AlertPage.Notification.Resolve.Error',
          defaultMessage: "Meldung konnte nicht als gelöst markiert werden, versuchen Sie es später noch einmal",
        });
        displayNotification('error', title, body);
      }
    }

    const dismissAlert = async (alert) => {
      try {
        const response = await http.put(`/company/${company_id}/emergency/${alert.id}`, {
          dismissed: true,
        });

        if (response.status === 204) {
          const title = intl.formatMessage({
            id: 'Success',
            defaultMessage: 'Erfolg!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Dismiss.Success',
            defaultMessage: 'Die Meldung wurde als "verworfen" markiert und in der Rubrik "Bearbeitete Meldungen" verschoben.',
          });
          displayNotification('success', title, body);
          fetchAlerts();
        } else {
          const title = intl.formatMessage({
            id: 'Error',
            defaultMessage: 'Etwas ist schief gelaufen!',
          });
          const body = intl.formatMessage({
            id: 'AlertPage.Notification.Dismiss.Error',
            defaultMessage: 'Meldung konnte nicht als gelöst markiert werden, versuchen Sie es später noch einmal',
          });
          displayNotification('error', title, body);
        }
      } catch (error) {
        const title = intl.formatMessage({
          id: 'Error',
          defaultMessage: 'Etwas ist schief gelaufen!',
        });
        const body = intl.formatMessage({
          id: 'AlertPage.Notification.Dismiss.Error',
          defaultMessage: 'Meldung konnte nicht als gelöst markiert werden, versuchen Sie es später noch einmal',
        });
        displayNotification('error', title, body);
      }
    }

    const verified = (
      <>
        <Tag color="blue">
          <FormattedMessage
            id="AlertPage.Tag.Verified"
            defaultMessage="Verifiziert"
          ></FormattedMessage>
        </Tag>
      </>
    );

    const unverified = (
      <>
        <Tag color="red">
          <FormattedMessage
            id="AlertPage.Tag.Unverified"
            defaultMessage="Nicht verifiziert"
          ></FormattedMessage>
        </Tag>
      </>
    );

    const resolved = (
      <>
        <Tag color="green">
          <FormattedMessage
            id="AlertPage.Tag.Resolved"
            defaultMessage="Behoben"
          ></FormattedMessage>
        </Tag>
      </>
    );

    const dismissed = (
      <>
        <Tag color="red">
          <FormattedMessage
            id="AlertPage.Tag.Dismissed"
            defaultMessage="Abgewiesen"
          ></FormattedMessage>
        </Tag>
      </>
    );

    const BtnResolve = ({ record, disabled=false }) => (
      <Button
        type="primary"
        className="m-r-6"
        disabled={disabled}
        onClick={() => {resolveAlert(record)}}
      >
        <FormattedMessage
          id="AlertPage.Button.Resolve"
          defaultMessage="Lösen"
        ></FormattedMessage>
      </Button>
    );

    const BtnVerify = ({ record, disabled=false }) => (
      <Button
        type="primary"
        className="m-r-6"
        disabled={disabled}
        onClick={() => {
          setToVerify(record);
          setVerifyModal(true);
        }}
        disabled={record.verified}
    >
        <FormattedMessage
          id="AlertPage.Button.Verify"
          defaultMessage="Prüfen"
        ></FormattedMessage>
    </Button>
    );

    const BtnDismiss = ({ record, disabled=false }) => (
      <Button
        type="primary"
        className="m-r-6"
        disabled={disabled}
        onClick={() => {dismissAlert(record)}}
      >
        <FormattedMessage
          id="AlertPage.Button.Dismiss"
          defaultMessage="Verwerfen"
        ></FormattedMessage>
      </Button>
    );

    const verifyModalTitle = intl.formatMessage(
      {
        id: "AlertPage.Modal.Title",
        defaultMessage: "Verifizierung einer Infektionsmeldung",
      }
    );

    const welcomyNetwork = intl.formatMessage(
      {
        id: "AlertPage.Modal.Network",
        defaultMessage: "Welcomy Netzwerks",
      }
    );

    const verifyModalOk = intl.formatMessage(
      {
        id: "AlertPage.Modal.Button.Ok",
        defaultMessage: "Ok",
      }
    );

    const verifyModalCancel = intl.formatMessage(
      {
        id: "AlertPage.Modal.Button.Cancel",
        defaultMessage: "Zurück",
      }
    );

    const verifyModalBody = intl.formatMessage(
      {
        id: "AlertPage.Tag.Verified",
        defaultMessage: "Verifiziert",
      }
    );

    const status = intl.formatMessage(
      {
        id: "AlertPage.Columns.Status",
        defaultMessage: "Status",
      }
    );

    const name = intl.formatMessage(
      {
        id: "AlertPage.Columns.Name",
        defaultMessage: "Name",
      }
    );

    const dateOfVisit = intl.formatMessage(
      {
        id: "AlertPage.Columns.Date.Visit",
        defaultMessage: "Aufenthaltsdatum",
      }
    );

    const dateOfTest = intl.formatMessage(
      {
        id: "AlertPage.Columns.Date.Test",
        defaultMessage: "Getestet am",
      }
    );

    const phone = intl.formatMessage(
      {
        id: "AlertPage.Columns.Phone",
        defaultMessage: "Telefon",
      }
    );
    
    const email = intl.formatMessage(
      {
        id: "AlertPage.Columns.Email",
        defaultMessage: "Email",
      }
    );

    const actions = intl.formatMessage(
      {
        id: "AlertPage.Columns.Actions",
        defaultMessage: "Aktion",
      }
    );



      const newAlertColumns = [
        {
            title: status,
            key: 'status',
            render: (_, record) => (
                <React.Fragment>
                  {
                    record.verified &&
                    verified
                  }
                  {
                    !record.verified &&
                    unverified
                  }
                </React.Fragment>
              ),
        },
        {
          title: name,
          dataIndex: 'full_name',
          key: 'name',
        },
        {
          title: dateOfVisit,
          dataIndex: 'date_created',
          key: 'dateCreated',
          render: (_, record) => moment(record.date_created).format('DD-MM-YYYY'),
        },
        {
          title: dateOfTest,
          dataIndex: 'event_date',
          key: 'eventDate',
          render: (_, record) => moment(record.event_date).format('DD-MM-YYYY'),
        },
        {
          title: phone,
          dataIndex: 'phone_number',
          key: 'phoneNumber',
        },
        // @TODO
        //
        // REMOVED UNTIL GDPR GUIDANCE
        //
        // {
        //   title: email,
        //   dataIndex: 'email',
        //   key: 'email',
        // },
        {
            title: actions,
            key: "actions",
            render: (text, record, index) => (
              <React.Fragment>
                <Row>
                  <Col>
                    <BtnResolve record={record} disabled={record.resolved || record.dismissed}/>
                  </Col>
                  <Col>
                    <BtnVerify record={record} disabled={record.resolved || record.dismissed}/>
                  </Col>
                  <Col>
                    <BtnDismiss record={record}/>
                  </Col>
                </Row>
              </React.Fragment>
            ),
          }
      ];

      const oldAlertColumns = [
        {
            title: status,
            key: 'status',
            render: (_, record) => (
                <React.Fragment>
                  {
                    record.verified &&
                    verified
                  }
                  {
                    !record.verified &&
                    unverified
                  }
                  {
                    record.resolved &&
                    resolved
                  }
                  {
                    record.dismissed &&
                    dismissed
                  }
                </React.Fragment>
              ),
        },
        {
          title: name,
          dataIndex: 'full_name',
          key: 'name',
        },
        {
          title: dateOfVisit,
          dataIndex: 'date_created',
          key: 'dateCreated',
          render: (_, record) => moment(record.date_created).format('DD-MM-YYYY'),
        },
        {
          title: dateOfTest,
          dataIndex: 'event_date',
          key: 'eventDate',
          render: (_, record) => moment(record.event_date).format('DD-MM-YYYY'),
        },
        {
          title: phone,
          dataIndex: 'phone_number',
          key: 'phoneNumber',
        },
        // @TODO
        //
        // REMOVED UNTIL GDPR GUIDANCE
        //
        // {
        //   title: email,
        //   dataIndex: 'email',
        //   key: 'email',
        // },
        {
            title: actions,
            key: "actions",
            render: (text, record, index) => (
              <React.Fragment>
                <Row>
                  <Col>
                    <BtnResolve record={record} disabled={record.resolved || record.dismissed}/>
                  </Col>
                  <Col>
                    <BtnVerify record={record} disabled={record.resolved || record.dismissed}/>
                  </Col>
                  <Col>
                    <BtnDismiss record={record} disabled={record.resolved || record.dismissed}/>
                  </Col>
                </Row>
              </React.Fragment>
            ),
          }
      ];

    return (
        <div>
            <Modal
              title={verifyModalTitle}
              visible={verifyModal}
              okText={verifyModalOk}
              cancelText={verifyModalCancel}
              onOk={verifyAlert}
              onCancel={() => {
                setVerifyModal(false);
                setToVerify(null);
              }}
            >
              <Paragraph>
                <FormattedMessage
                  id="AlertPage.Modal.Body"
                  defaultMessage="Wenn Sie eine Meldung als {tag} markieren, teilen Sie allen relevanten Parteien innerhalb des {network} mit, dass es Kontaktpunkte mit einer infizierten Person gab und der vorliegende Infektionsfall bereits verifiziert wurde"
                  values={{tag: <Tag color="red">{verifyModalBody}</Tag>, network: <b>{welcomyNetwork}</b>, you: <u><b>you</b></u>, confirmed: <b>confirmed</b>}}
                ></FormattedMessage>
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="AlertPage.Modal.Notice"
                  defaultMessage="Bitte gehen Sie mit dieser Funktion behutsam um."
                ></FormattedMessage>
              </Paragraph>
            </Modal>
            <Row className="m-b-12">
              <Col xs={24}>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <a href="/dashboard">
                    <FormattedMessage
                      id="VisitorLogPage.Title"
                      defaultMessage="Besucherprotokoll"
                    ></FormattedMessage>
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <FormattedMessage
                      id="AlertPage.Title"
                      defaultMessage="Meldungsbereich"
                    ></FormattedMessage>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Title level={1}>
                    <FormattedMessage
                      id="AlertPage.Title"
                      defaultMessage="Meldungsbereich"
                    ></FormattedMessage>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Title level={4}>
                      <FormattedMessage
                        id="AlertPage.Subtitle.Alerts.New"
                        defaultMessage="Neue Meldungen"
                      ></FormattedMessage>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Table dataSource={newAlerts} columns={newAlertColumns} rowKey="id" pagination={false}/>
                </Col>
            </Row>
            <Row  className="m-t-32">
                <Col xs={24}>
                    <Title level={4}>
                        <FormattedMessage
                          id="AlertPage.Subtitle.Alerts.Old"
                          defaultMessage="Bearbeitete Meldungen"
                        ></FormattedMessage>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Table dataSource={oldAlerts} columns={oldAlertColumns} rowKey="id" pagination={false}/>
                </Col>
            </Row>
        </div>
    );
}

export default CoronaAlerts;
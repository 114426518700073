/**
 * @description Return a base64 string representation of a file loaded in memory.
 * @param {File like Object} img - File like object to be read and loaded.
 * @param {Function} callback - Callback function, e.g. update state.
 */
export const getBase64 = (img, callback) => {
  if (callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  } else {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
};

import {
    CloudDownloadOutlined,
    FacebookOutlined,
    FormOutlined,
    InstagramOutlined, LaptopOutlined, LinkedinFilled,
    LinkOutlined,
    MailOutlined,
    PhoneOutlined, ProfileOutlined,
    SendOutlined, SolutionOutlined,
    TwitterOutlined, WhatsAppOutlined
} from "@ant-design/icons";
import React from "react";

export const linkIcons = {
    'link': <LinkOutlined/>,
    'facebook': <FacebookOutlined/>,
    'instagram': <InstagramOutlined/>,
    'email': <MailOutlined/>,
    'phone': <PhoneOutlined/>,
    'twitter': <TwitterOutlined/>,
    'menu': <SolutionOutlined/>,
    'registration': <FormOutlined/>,
    'whatsapp': <WhatsAppOutlined/>,
    'linkedin': <LinkedinFilled/>,
    'file': <CloudDownloadOutlined/>,
    'upload': <CloudDownloadOutlined/>,
    'newsletter': <SendOutlined/>,
    'software': <LaptopOutlined/>,
    'product_and_services': <ProfileOutlined />,
}
import React from 'react';
import { Col, Row, Typography } from 'antd';
import Page404 from '../../static/PageNotFound';

const { Title } = Typography;

function PageNotfound(props) {
  return (
    <div className="page-not-found-wrapper">
      <Row>
        <Col xs={24}>
          <Page404 className="h-align image-404"/>
        </Col>
      </Row>
      <Row >
        <Col xs={24}>
          <Title level={1} className="text-center">Page not found</Title>
        </Col>
      </Row>
    </div>
  );
}

export default PageNotfound;

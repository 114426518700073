import { newDimensions } from "../../QrCode";

import vcardDarkBg from "../../../static/vcard-bgs/grey-a8a8a8.png";
import vcardWhiteBg from "../../../static/vcard-bgs/white-f1f1f1.png";
import {
  drawBusinessCardForDownload,
  drawBusinessCardPreview,
} from "../draw/draw";

export const CARDS_BGS = {
  QR_CODE_WHITE_CARD: { image: vcardWhiteBg, bg: "transparent", fg: "#000" },
  QR_CODE_DARK_CARD: { image: vcardDarkBg, bg: "transparent", fg: "#F9F9F9" },
};

const ORIGINAL_BG_DIMENSIONS = {
  // in pixels
  width: 1109,
  height: 1720,
};

export const qrCodeVCardRender = (
  ctx,
  canvas,
  type,
  companyLogo,
  shortLink,
  canvasBoundries,
  logoSize = {
    maxWidth: 50,
    maxHeight: 50,
  },
  preview = true,
  callback
) => {
  const backgroundUrl = CARDS_BGS[type].image;
  const background = new Image();
  background.src = backgroundUrl;
  // setting up card background
  background.onload = () => {
    if (preview) {
      drawBusinessCardPreview(
        type,
        canvasBoundries,
        shortLink,
        ctx,
        CARDS_BGS,
        companyLogo,
        logoSize,
        background
      );
    } else {
      drawBusinessCardForDownload(
        type,
        canvasBoundries,
        shortLink,
        ctx,
        CARDS_BGS,
        companyLogo,
        logoSize,
        background,
        callback
      );
    }
  };
};

export const downloadPngQRCode = (callback) => {
  const canvasQr = document.getElementById("qr-code-to-pdf");
  const [qrWidth, qrHeight] = newDimensions(800, 800, 600, 600);
  const qrX = (800 - qrWidth) / 2;
  const qrY = (800 - qrHeight) / 2;
  const newCanvas = document.createElement("canvas");
  const ctx = newCanvas.getContext("2d");
  newCanvas.width = 800;
  newCanvas.height = 800;
  ctx.drawImage(canvasQr, qrX, qrY, qrWidth, qrHeight);

  const newImageUrl = newCanvas.toDataURL("image/png", 1.0);

  const link = document.createElement("a");
  link.href = newImageUrl;
  link.download = "myqr.png";
  link.click();
  link.remove();
  newCanvas.remove();

  if (callback) {
    callback();
  }
};

export const downloadVCardQrCode = (type, companyLogo, shortLink, callback) => {
  const newCanvas = document.createElement("canvas");
  const ctx = newCanvas.getContext("2d");
  newCanvas.width = ORIGINAL_BG_DIMENSIONS.width;
  newCanvas.height = ORIGINAL_BG_DIMENSIONS.height;

  const _download = () => {
    const newImageUrl = newCanvas.toDataURL("image/png", 1.0);
    const link = document.createElement("a");
    link.href = newImageUrl;
    link.download = "myVCard.png";
    link.click();
    link.remove();
    newCanvas.remove();
  };

  qrCodeVCardRender(
    ctx,
    newCanvas,
    type,
    companyLogo,
    shortLink,
    { x: ORIGINAL_BG_DIMENSIONS.width, y: ORIGINAL_BG_DIMENSIONS.height },
    { maxWidth: 450, maxHeight: 450 },
    false,
    _download
  );
  if (callback) {
    callback();
  }
};

import { Space } from "antd";
import React from "react";
import { AiChatIcon } from "../../../../../../static/ai-chat-icon.jsx";

const ChatWithPdfButton = ({ linkName, isLastItem }) => {
  return (
    <div className={`neumorphic m-t-12 ${isLastItem ? "m-b-24" : ""}`}>
      <button className="neumorphic-button">
        <span>
          <Space>
            <AiChatIcon />
            {linkName}
          </Space>
        </span>
      </button>
    </div>
  );
};

const LinkButtonType = ({
  internalTypes,
  aLink,
  index,
  linkAddress,
  linksList,
  icon,
  isPreview = false,
}) => {
  let target = internalTypes.indexOf(aLink.type) >= 0 ? "" : "_blank";
  let href = linkAddress;
  if (aLink.chat_with_pdf) {
    target = "_self";
    href = `/public/welcome/form/ai?cid=${aLink.id}&file=${linkAddress}`;
  }
  return (
    <a
      target={target}
      rel="noreferrer nofollowing ugc"
      key={`link-${index}-${aLink.id}`}
      href={href}
      // Prevent clicking on Preview Mode
      style={{pointerEvents: isPreview ? 'none' : 'auto'}}
    >
      {!aLink.chat_with_pdf && (
        <div
          className={`neumorphic m-t-12 ${
            linksList.length - 1 === index ? "m-b-24" : ""
          }`}
        >
          <button className="neumorphic-button">
            <span>
              <Space>
                {icon}
                {aLink.name}
              </Space>
            </span>
          </button>
        </div>
      )}

      {aLink.chat_with_pdf && (
        <ChatWithPdfButton
          isLastItem={linksList.length - 1 === index}
          linkName={aLink.name}
        />
      )}
    </a>
  );
};

export default LinkButtonType;

import React from "react";
import { Modal, Button } from "antd";
import { getIntl } from "../../utils/intl";

const ConfirmationModal = ({
  visible,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const intl = getIntl();
  const cancelButtonText = intl.formatMessage({
    id: "Cancel",
  });  const confirmButtonText = intl.formatMessage({
    id: "Confirm",
  });
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {cancelButtonText}
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          {confirmButtonText}
        </Button>,
      ]}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmationModal;

import {
    ADD_LIVE_PREVIEW_LINK,
    DELETE_LIVE_PREVIEW_LINK,
    SET_LIVE_PREVIEW_LINKS, UPDATE_LIVE_PREVIEW_COMPANY_SETUP,
    UPDATE_LIVE_PREVIEW_LINK, UPDATE_LIVE_PREVIEW_NEW_LINK
} from '../actions/livePreviewActions';

const initialState = {
    links: [],
    companySetup: {
        basicInformation: null,
        logo: null,
        companyTitle: null,
        description: null,
        hubName: null,
    }
};

const livePreviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LIVE_PREVIEW_LINKS:
            return {
                ...state,
                links: action?.payload ? [...action.payload] : []
            }
        case ADD_LIVE_PREVIEW_LINK:
            return {
                ...state,
                links: [action.payload, ...state.links]
            };
        case UPDATE_LIVE_PREVIEW_LINK:
            const updatedPreviewLinks = state.links.map(link =>
                link.id === action.payload.id ? {...link, ...action.payload.updatedLink} : link
            );

            return {
                ...state,
                links: [...updatedPreviewLinks]
            }
        case UPDATE_LIVE_PREVIEW_NEW_LINK:
            const updatedLinks = state.links.map((link) => {
                if (link.id === action.payload.id) {
                    return {
                        ...link,
                        ...action?.payload.content,
                    }
                }
                return link;
            })
            return {
                ...state,
                links: updatedLinks
            }
        case DELETE_LIVE_PREVIEW_LINK:
            return {
                ...state,
                links: state.links.filter((link) => link.id !== action?.payload.id)
            }
        case UPDATE_LIVE_PREVIEW_COMPANY_SETUP:
            return {
                ...state,
                companySetup: {...state.companySetup, ...action?.payload}
            }
        default:
            return state;
    }
};

export default livePreviewReducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';

import { Button, Col, Upload, Row, Typography, Spin } from "antd";
import { InboxOutlined } from '@ant-design/icons';

import { getIntl } from "../../utils/intl";
import http from "../../utils/http";
import { displayNotification } from '../../components/Notification';
import { getCookie } from '../../components/getCookie';

const { Dragger } = Upload;
const { Title } = Typography;

function PdfUpload(props) {
  const access_token = getCookie("_a");
  const company_id = useSelector((state) => state.companyConfig.company_id);
  const [loadingState, setLoadingState] = useState(false);

  const storeStaticMenu = async (url, onSuccess, onError) => {
    try {
      await http.post(
        `gastro/${company_id}/static_menu`,
        { url: url }
      );
    } catch (error) {
      console.log("Error", error);
      onError(error);
      return;
    }
    onSuccess();
  }
  const draggerOpts = {
    name: "menu",
    multiple: false,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    showUploadList: false,
    accept: '.pdf',
    async customRequest(fileOpts) {
      const { onSuccess, onError } = fileOpts;
      let data = new FormData();
      data.append('file', fileOpts.file);
      try {
        const res = await http.post(
          `/company/${company_id}/file`,
          data
        );
        await storeStaticMenu(res.data.payload.url, onSuccess, onError);
      } catch (error) {
        console.log("Error uploading file", error);
        onError(error);
      }
    },
    beforeUpload(file) {
      const isPdf = file.type === "application/pdf";
      const intl = getIntl();
      if (!isPdf) {
        const title = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.InvalidFileType.Title",
            defaultMessage: "Ungültiger Dateityp"
          }
        );
        const body = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.InvalidFileType.Body",
            defaultMessage: "Sie können nur PDF Dateien hochladen"
          }
        );
        displayNotification("error", title, body);
        setLoadingState(false);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        const title = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.FileTooLarge.Title",
            defaultMessage: "Datei zu groß"
          }
        );
        const body = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.FileTooLarge.Body",
            defaultMessage: "Das Datei muss kleiner als 4 MB sein"
          }
        );
        displayNotification("error", title, body);
        setLoadingState(false);
        return false;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      const intl = getIntl();
      if (status === "uploading") {
        setLoadingState(true);
      }
      if (status === "done") {
        const title = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.UploadSuccess.Title",
            defaultMessage: "Erfolg!"
          }
        );
        const body = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.UploadSuccess.Body",
            defaultMessage: "Ihre Karte wurde geupdated"
          }
        );
        setLoadingState(false);
        displayNotification("success", title, body);
      } else if (status === "error") {
        setLoadingState(true);
        console.log('Error uploading static menu:', info);
        const title = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.UploadFail.Title",
            defaultMessage: "Ihre Karte wurde nicht geupdated"
          }
        );
        const lastLine = intl.formatMessage(
          {
            id: "DigitalMenuPage.PdfUpload.UploadFail.Body",
            defaultMessage: `Wenn das Problem weiterhin besteht, 
            bitte kontaktieren Sie unser freundliches Support-Team! :)`,
          }
        );
        setLoadingState(false);
        displayNotification("error", title, lastLine);
      }
    }
  };
  return (
    <div>
      <Row>
        <Col xs={24}>
          <Title level={4} className="page-title m-t-32">
            <FormattedMessage
              id="OrganizeMenuPage.PDFUpload.SubHeader"
              defaultMessage="Laden Sie hier Ihre PDF-basierte Speisekarte hoch"
            ></FormattedMessage>
          </Title>
        </Col>
      </Row>
      <Row>
      </Row>
      <Row>
        <Col xs={24}>
          <Spin
            spinning={loadingState}
            size="large"
            style={{ height: "20vh" }}
          >
            <Dragger {...draggerOpts}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <FormattedMessage
                  id="DigitalMenuPage.UploadArea.Title"
                  defaultMessage="Klicken oder ziehen Sie Ihre Datei zum Hochladen in diesen Bereich"
                ></FormattedMessage>
              </p>
            </Dragger>
            <Button type="primary" className="m-t-12 m-b-32" block>
              <FormattedMessage
                id="DigitalMenuPage.Button.Save"
                defaultMessage="Bestätigen"
              ></FormattedMessage>
            </Button>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default PdfUpload;

import { calculateAspectRatioFit, newDimensions } from "../../QrCode";

/**
 * THIS WILL DELETE EVERYTHING PREVIOUSLY DRAWN TO THE CANVAS
 *
 * MAKE SURE TO GET THE CORRECT SIZE AND POSITIONS BEFORE PUTTING CONTENT
 * TO THE CANVAS!!
 */
export const getFontSizeCanvas = (
  ctx,
  text,
  textAreaWidth = 60,
  canvasWidth = 150,
  canvasHeight = 200
) => {
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  // Initial font size
  var fontSize = 100;

  // Set a maximum number of iterations to prevent infinite loop
  var maxIterations = 100;
  var iteration = 0;

  // Loop until the text fits in the text area
  while (iteration < maxIterations) {
    ctx.font = "bold " + fontSize + "px Arial";

    // Measure the width of the text
    var textWidth = ctx.measureText(text).width;
    if (textWidth <= textAreaWidth) {
      // If text fits, break out of the loop
      break;
    }

    // Decrease font size and try again
    fontSize--;
    iteration++;
  }

  // Draw the text
  // ctx.fillText(text, posWidth, posHeight);
  return fontSize;
};

export const drawBusinessCardPreview = (
  type,
  canvasBoundries,
  shortLink,
  ctx,
  CARDS_BGS,
  companyLogo,
  logoSize,
  background
) => {
  // setting up qr code
  const canvasQr = document.getElementById(`qr-template-${type}`);

  const qrWidth = 80;
  const qrHeight = 80;
  const qrTop = 25;

  const qrX = (canvasBoundries.x - qrWidth) / 2;
  const qrY = qrTop;

  let txtTop = qrTop + qrHeight;
  if (shortLink) {
    // Set up short URL text
    ctx.fillStyle = CARDS_BGS[type].fg;
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";

    /**
     * THIS WILL DELETE EVERYTHING PREVIOUSLY DRAWN TO THE CANVAS
     */
    const fontSize = getFontSizeCanvas(
      ctx,
      shortLink,
      qrWidth,
      canvasBoundries.x,
      canvasBoundries.y
    );
    ctx.font = `bold ${fontSize}px Arial`;
    const textMetrics = ctx.measureText(shortLink);
    const txtHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;
    txtTop += txtHeight;
    const textX = (canvasBoundries.x - textMetrics.width) / 2;

    // Set up company logo
    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = companyLogo;
    logo.onload = () => {
      const [logoWidth, logoHeight] = newDimensions(
        logoSize.width ? logoSize.width : logo.naturalWidth,
        logoSize.height ? logoSize.height : logo.naturalHeight,
        logoSize.maxWidth,
        logoSize.maxHeight
      );

      const logoTop = txtTop + 20;
      const logoX = (canvasBoundries.x - logoWidth) / 2;
      ctx.drawImage(background, 0, 0, canvasBoundries.x, canvasBoundries.y);
      ctx.drawImage(canvasQr, qrX, qrY, qrWidth, qrHeight);
      ctx.fillText(shortLink, textX, txtTop);
      ctx.drawImage(logo, logoX, logoTop, logoWidth, logoHeight);
    };
  }
};

export const drawBusinessCardForDownload = (
  type,
  canvasBoundries,
  shortLink,
  ctx,
  CARDS_BGS,
  companyLogo,
  logoSize,
  background,
  callback
) => {
  // setting up qr code
  const canvasQr = document.getElementById(`qr-template-${type}`);

  const qrWidth = 700;
  const qrHeight = 700;
  const qrTop = 250;

  const qrX = (canvasBoundries.x - qrWidth) / 2;
  const qrY = qrTop;

  let txtTop = qrTop + qrHeight;
  if (shortLink) {
    // Set up short URL text
    ctx.fillStyle = CARDS_BGS[type].fg;
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";

    /**
     * THIS WILL DELETE EVERYTHING PREVIOUSLY DRAWN TO THE CANVAS
     */
    const fontSize = getFontSizeCanvas(
      ctx,
      shortLink,
      qrWidth,
      canvasBoundries.x,
      canvasBoundries.y
    );
    ctx.font = `bold ${fontSize}px Arial`;
    const textMetrics = ctx.measureText(shortLink);
    const txtHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;
    txtTop += txtHeight;
    const textX = (canvasBoundries.x - textMetrics.width) / 2;

    // Set up company logo
    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = companyLogo;
    logo.onload = () => {
      const { width: logoWidth, height: logoHeight } = calculateAspectRatioFit(
        logo.naturalWidth,
        logo.naturalHeight,
        logoSize.maxWidth,
        logoSize.maxHeight
      );

      const logoTop = txtTop + 200;
      const logoX = (canvasBoundries.x - logoWidth) / 2;
      ctx.drawImage(background, 0, 0, canvasBoundries.x, canvasBoundries.y);
      ctx.drawImage(canvasQr, qrX, qrY, qrWidth, qrHeight);
      ctx.fillText(shortLink, textX, txtTop);
      ctx.drawImage(logo, logoX, logoTop, logoWidth, logoHeight);

      if (callback) callback();
    };
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import German from "../i18n/de.json";
import English from "../i18n/en.json";
// import Greek from "../i18n/gr.json";
import { configSetter } from "../redux/actions";
import { Spin } from "antd";

const { Provider, Consumer } = React.createContext();

class LanguagePicker extends Component {
  constructor(...args) {
    super(...args);

    this.messages = {
      de: German,
      en: English,
      // gr: Greek,
      pt: English,
    };

    this.switchToEnglish = () => {
      const locale = "en";
      this.setState({ locale: locale, messages: English });
      window.localStorage.setItem("_wl_locale", locale);
      this.props.dispatch(
        configSetter({
          locale: locale,
        })
      );
    };
    this.switchToGerman = () => {
      const locale = "de";
      this.setState({ locale: locale, messages: German });
      window.localStorage.setItem("_wl_locale", locale);
      this.props.dispatch(
        configSetter({
          locale: locale,
        })
      );
    };

    // this.switchToGreek = () => {
    //   const locale = "gr";
    //   this.setState({ locale: locale, messages: German });
    //   window.localStorage.setItem("_wl_locale", locale);
    //   this.props.dispatch(
    //     configSetter({
    //       locale: locale,
    //     })
    //   );
    // };

    this.switchTo = (iso) => {
      const locale = iso;
      this.setState({ locale: locale, messages: this.messages[iso] });
      window.localStorage.setItem("_wl_locale", locale);
      this.props.dispatch(
        configSetter({
          locale: locale,
        })
      );
    };

    this.state = {
      locale: "de",
      messages: German,
      switchToEnglish: this.switchToEnglish,
      switchToGerman: this.switchToGerman,
      // switchToGreek: this.switchToGreek,
      switchTo: this.switchTo,
    };
  }

  componentDidMount() {
    const locale = window.localStorage.getItem("_wl_locale");
    if (locale && locale === "en") {
      this.setState({ locale: locale, messages: English });
      this.props.dispatch(
        configSetter({
          locale: locale,
        })
      );
    }
    // else if (locale && locale === "gr") {
    //   this.setState({ locale: locale, messages: Greek });
    //   this.props.dispatch(
    //     configSetter({
    //       locale: locale,
    //     })
    //   );
    // }
    else {
      this.setState({ locale: "de", messages: German });
      this.props.dispatch(
        configSetter({
          locale: "de",
        })
      );
    }
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Provider value={this.state}>
        <IntlProvider locale={locale} messages={messages}>
          {!this.props.companyConfig.locale &&
            <Spin size="large"/>
          }
          {this.props.companyConfig.locale && 
            children
          }
        </IntlProvider>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export { Consumer as LanguagePickerConsumer };
export default connect(mapStateToProps)(LanguagePicker);

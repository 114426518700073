import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, Result, Row, Typography } from "antd";
import { useQuery } from "../../hooks/useQuery";
import Logo from "../../static/Logo";
import { useForm } from "antd/lib/form/Form";
import http from "../../utils/http";
import { useHistory } from "react-router-dom";
import { getIntl } from "../../utils/intl";
import { PasswordResetTranslations as messages} from "./translations";

const { Title, Paragraph } = Typography;

function PasswordReset(props) {
  const tokenRef = useRef(null);
  const [tokenError, setTokenError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const query = useQuery();
  const history = useHistory();
  const [form] = useForm();
  const translation = getIntl();


  useEffect(() => {
    const token = query.get("tk");
    if (!token) {
      setTokenError(
          translation.formatMessage(messages.tokenError)
      );
    } else {
      tokenRef.current = token;
    }
  }, []);

  const submitPassword = async () => {
    const { newPassword, repeatNewPassword } = form.getFieldsValue();

    if (newPassword !== repeatNewPassword) {
      setPasswordError(translation.formatMessage(messages.passwordMatchError));
    } else {
      setPasswordError(null);
      try {
        const result = await http.post("/auth/reset_password", {
          password: newPassword,
          token: tokenRef.current,
        });

        if (result.status >= 200 && result.status <= 299) {
          setPasswordSuccess(true);
        }
      } catch (error) {
        if (error?.response?.data?.error) {
          const { password: passwordErrorMessage } = error.response.data.error;
          if (passwordErrorMessage === "Length must be between 8 and 36.") {
            setPasswordError(translation.formatMessage(messages.passwordResetLengthError));
          } else {
            setPasswordError(passwordErrorMessage);
          }
        } else {
          setPasswordError(
            translation.formatMessage(messages.passwordResetGenericError)
          );
        }
      }
    }
  };

  return (
    <section className="password-reset__wrapper">
      <Row>
        <Col xs={0} lg={6}></Col>
        <Col xs={24} lg={12}>
          <div className="content m-t-64">
            <div>
              <div className="logo m-b-12">
                <Logo />
              </div>
              <Title level={1}>{translation.formatMessage(messages.pageTitle)}</Title>
              {tokenError && (
                <Paragraph className="error">{tokenError}</Paragraph>
              )}
              {!tokenError && !passwordSuccess && (
                <Form
                  form={form}
                  layout="vertical"
                  wrapperCol={12}
                  onFinish={submitPassword}
                >
                  <Form.Item label={translation.formatMessage(messages.inputNewPassword)} name="newPassword">
                    <Input type="password" />
                  </Form.Item>
                  <Form.Item
                    label={translation.formatMessage(messages.inputRepeatPassword)}
                    name="repeatNewPassword"
                  >
                    <Input type="password" />
                  </Form.Item>
                  {passwordError && (
                    <Paragraph className="error">{passwordError}</Paragraph>
                  )}
                  <Button type="primary" htmlType="submit">
                    {translation.formatMessage(messages.saveButton)}
                  </Button>
                </Form>
              )}
              {!tokenError && passwordSuccess && (
                <Result
                  status="success"
                  title={translation.formatMessage(messages.passwordResetSuccess)}
                  subTitle={translation.formatMessage(messages.subtitle)}
                  extra={
                    <Button type="primary" onClick={() => history.push("/login")}>
                      {translation.formatMessage(messages.goToLogin)}
                    </Button>
                  }
                />
              )}
            </div>
          </div>
        </Col>
        <Col xs={0} lg={6}></Col>
      </Row>
    </section>
  );
}

export default PasswordReset;

import React from "react";
import "flag-icons/css/flag-icons.min.css";
import { Spin, Table } from "antd";
import { messages } from "../translations";
import { getIntl } from "../../../utils/intl";


function TopCountries({ data }) {
  const intl = getIntl();

  const columns = [
    {
      title: intl.formatMessage(messages.tableCountriesColumnCountry),
      key: "country",
      dataIndex: "country",
      sorter: (a, b) => {
        if (a.country > b.country) return 1;
        if (a.country < b.country) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage(messages.tableCountriesColumnUsage),
      dataIndex: "loads",
      key: "loads",
      sorter: (a, b) => a.loads - b.loads,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
  ];

  let _data = [];
  if (data) {
    _data = Object.keys(data).map((country) => ({
      country,
      loads: data[country],
    }));
  }

  if (!data)
    return (
      <div className="graph-wrapper-vertical">
        <div className="graph-wrapper-horizontal">
          <Spin className="chart-spin" />
        </div>
      </div>
    );

  return (
    <div>
      <Table columns={columns} dataSource={_data} pagination={false} />
    </div>
  );
}

export default TopCountries;

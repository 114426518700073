import { getCookie } from "../components/getCookie";

const cookieDomain = () => {
  if (!process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    window.location.host.indexOf('localhost') >= 0) {
    return ".localhost.com";
  } else {
    return ".welcomyapp.com";
  }
};

/**
 * @description Evaluate, together with Redux and Cookies if an account is a super account.
 * @param {boolean} isSuper Redux state value for super_account ComapnyConfig key.
 */
const isAllSuperAccount = (isSuper) => {
  const superId = getCookie('_super_c');
  /**
   * _super_c is a cookie that points to a main account id. For the current usage
   * of this function we want to guarantee both of the definations evaluate to true.
   */
  if (typeof isSuper !== 'undefined' && isSuper && !superId) return true;
  return false;
}

export { cookieDomain, isAllSuperAccount };

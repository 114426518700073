import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      focusable="false"
      data-icon="chilli-icon"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path d="M30.9.005a7.367 7.367 0 00-6.297 5.086 8.438 8.438 0 00-5.101-1.038 8.509 8.509 0 00-5.876 3.328c-2.58 3.377-3.649 6.45-4.685 9.42-1.489 4.275-2.896 8.313-8.61 13.457a1 1 0 00.74 1.739c14.253-1.021 22.64-5.956 27.192-16.002 1.473-3.25.676-7.115-1.915-9.606a5.362 5.362 0 014.751-4.393A1 1 0 0030.9.005zm-4.196 14.423c-.752-.195-1.422-.032-1.951.102-.662.169-.904.203-1.256-.032-.351-.234-.412-.472-.512-1.146-.109-.748-.261-1.772-1.381-2.52-1.121-.747-2.124-.492-2.855-.304-.66.169-.901.203-1.252-.03-.35-.233-.411-.471-.51-1.144-.081-.55-.186-1.25-.681-1.879a6.506 6.506 0 014.151-1.476 6.45 6.45 0 014.168 1.524c2.046 1.713 2.833 4.458 2.079 6.905z" />
    </svg>
  );
}

export default SvgComponent;

import { defineMessages } from "react-intl";

export const messages = defineMessages({
  pageTitle: {
    id: "Analytics.Page.Title",
    defaultMessage: "Welcomy Hub Analytics",
  },
  counterTotalTitle: {
    id: "Analytics.Counters.Total.Title",
    defaultMessage: "Gesamtnutzungsrate der Hub",
  },
  counterTotalSeven: {
    id: "Analytics.Counters.Total.Seven",
    defaultMessage: "Letzten 7 Tage",
  },
  counterTotalThirtyOne: {
    id: "Analytics.Counters.Total.ThirtyOne",
    defaultMessage: "Letzten 31 Tage",
  },
  lineChartSevenTitle: {
    id: "Analytics.Line.Seven.Title",
    defaultMessage: "Nutzungsrate der Hub in den letzten 7 Tagen",
  },
  lineChartSevenLegend: {
    id: "Analytics.Line.Seven.Legend",
    defaultMessage: "Anzahl der Aufrufe",
  },
  barCharttThirtyOneTitle: {
    id: "Analytics.Bar.ThirtyOne.Title",
    defaultMessage: "Nutzungsrate der Hub in den letzten 31 Tagen",
  },
  barCharttThirtyOneLegend: {
    id: "Analytics.Bar.ThirtyOne.Legend",
    defaultMessage: "Anzahl der Aufrufe",
  },
  tableCountriesTitle: {
    id: "Analytics.Countries.Title",
    defaultMessage: "Nutzungsrate der Hub pro Land in den letzten 31 Tagen",
  },
  tableCountriesColumnCountry: {
    id: "Analytics.Countries.Column.Country",
    defaultMessage: "Land",
  },
  tableCountriesColumnUsage: {
    id: "Analytics.Countries.Column.Usage",
    defaultMessage: "Nutzungsrate der Hub",
  },
});

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button } from "antd";
import RichEditor from '../../components/RichEditor'
import http from '../../utils/http';
import { useSelector } from 'react-redux';
import { getIntl } from '../../utils/intl';


const { Title, Paragraph } = Typography;

function Impressum(props) {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const [impressum, setImpressum] = useState('');

  const intl = getIntl();

  const TitleText = intl.formatMessage({
      id: "ImpressumPage.Title",
      defaultMessage: "Anzahl der Besucher",
  });

  const BodyText = intl.formatMessage({
    id: "ImpressumPage.Body",
    defaultMessage: "Anzahl der Besucher",
  });

  const NoteText = intl.formatMessage({
    id: "ImpressumPage.Note",
    defaultMessage: "Anzahl der Besucher",
  });


  const getFirstLoadImpressum = async () => {
    const result = await http.get(`gastro/${companyId}/metadata`);
    const _impressum = result?.data?.payload?.impressum;
    
    if (!_impressum) {
      await http.post(`gastro/${companyId}/metadata`, {
        impressum: '',
      });
    }
    
    setImpressum(_impressum);
  }
  
  useEffect(() => {
    getFirstLoadImpressum();
  }, []);

  const saveDocument = async (html) => {
    return await http.put(`/gastro/${companyId}/metadata`, {
      impressum: html,
    });
  };

  return (
    <div>
      <Row>
        <Col xs={0} lg={5}></Col>
        <Col xs={24} lg={14}>
          <Row>
            <Col xs={24}>
              <Title>{TitleText}</Title>
              <Paragraph>
                {BodyText}
              </Paragraph>
              <Paragraph italic>
                {NoteText}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              {
                <RichEditor initialEditorState={impressum?.length > 0 ? impressum : ""} saveDocument={saveDocument}/>
              }
            </Col>
          </Row>
        </Col>
        <Col xs={0} lg={5}></Col>
      </Row>
    </div>
  );
}

export default Impressum;
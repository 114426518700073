import React, { useEffect, useState, Suspense, useRef } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import http from "../utils/http";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  configSetter,
  digitalMenuUrlSetter,
  digitalMenuTypeSetter,
 } from "../redux/actions";

import Login from "../pages/login";
import ReportEmergency from "../pages/ReportEmergency";
import PageNotfound from "../pages/PageNotFound";
import UnsubscribePage from "../pages/Unsubscribe";
import { getCookie } from "./getCookie";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import { Spin } from "antd";


const Tablet = React.lazy(() => import("../pages/Tablet"));

const hasAllTokens = (prevState) => {
  const c = getCookie("_c"); // Company Id
  const v = getCookie("_v"); // Company Vertical
  const a = getCookie("_a"); // Access Token
  const ur = getCookie("_ur"); // User Role Token
  if (!c || !a || !v || !ur) return false;
  return c.length > 0 && a.length > 0 && v.length > 0 && ur.length > 0;
};

const hasSubdomain = (str) => {
  /**
   * www prefix is optional, subdomain is mandatory to pass regex test.
   */
  const regex = /^(?:www\.)?(?!www\..*$)(?=[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:[a-zA-Z0-9-]+\.){1,}[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/

  return regex.test(str);
};

const Auth = React.memo((props) => {
  const companyConfig = useSelector((state) => state.companyConfig);
  const dispatch = useDispatch();
  let step = useRef('initial');
  const [hasError, errorSetter] = useState(null);
  let isLogged = hasAllTokens();
  useEffect(() => {
    if (hasError === null) {
      getConfig();
    }
  }, [companyConfig.company_id]);
  const extractCompanyFromURL = async () => {
    const path = window.location.hostname.split(".")[0];
    if (path && path !== "welcomyapp" && path !== "localhost") {
      try {
        const result = await http.get("/company_path", {
          params: {
            path,
          },
        });
        return result.data.payload;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const menuExists = async (companyId) => {
    const result = await http.get(`gastro/${companyId}/menu`);
    const hasStaticOrMenu = Object.keys(result.data.payload).length > 0;
    if (hasStaticOrMenu) {
      let menuType = result.data.payload;
        let value;
        if (typeof menuType.menu_url !== 'undefined') {
            batch(() => {
              dispatch(digitalMenuUrlSetter(result.data.payload.menu_url));
              dispatch(digitalMenuTypeSetter('pdf'));
            });
        } else {
            dispatch(digitalMenuTypeSetter('digital'));
        }
    }
  };

  const getConfig = async () => {
    try {
      let id = companyConfig.company_id || getCookie["_c"];
      let vertical = companyConfig.vertical_id || getCookie["_v"];
      let hasFetchedConfig = companyConfig.hasFetchedConfig || false;
      let hasFetchedId = companyConfig.hasFetchedId || false;

      if (typeof id === "undefined") {
        let result = await extractCompanyFromURL();
        if (!result){
          return;
        }
        id = result.id;
        vertical = result.vertical.id;
      }

      if (vertical && (vertical === 3 || vertical === 4)){
        await menuExists(id);
      }

      // IN CASE WE ARE IN LOGIN SCREEN
      // FETCH ID AND SKIP
      if (id && window.location.pathname.includes("login") && !hasFetchedId) {
        step.current = 'fetched_id';
        dispatch(configSetter(
          {
            company_id: id,
            hasFetchedId: true,
            vertical_id: vertical,
          }
        ));
        return;
      }

      // FALLBACK IN CASE CONFIG WAS NOT SET BY LOGIN PROCESS OR IN CASE
      // OF PAGE REFRESH
      if (!hasFetchedConfig ) {
        step.current = 'fetched_config';
        let default_name = "New Company";
        let default_logo_url = "";
        let default_short_link = ""
        let default_sub_accounts = [];
        let default_super_account = false;
        let languageId = 1; // GERMAN
        let langISO = 'de';
        let langName = 'Deutsch'
        let default_links = [];
        let amplId = '';
        let defaultLegalEntity = '';

        let _language = {
          id: languageId,
          iso_code: langISO,
          name: langName,
        };

        const isPublic = window.location.pathname.includes("public");

        let toBeSetted = {};

        if (id && (isLogged || isPublic)) {
          const configResponse = await http.get(`/company/${id}`);

          if (!isPublic) {
            const accountInfo = await http.get(`/company/${id}/account`);
            let { sub_accounts, super_account} = accountInfo.data.payload;

            // For not exposing super accounts to the public,
            // this is setted here
            toBeSetted.sub_accounts = sub_accounts ? sub_accounts : default_sub_accounts;
            toBeSetted.super_account = super_account ? super_account : default_super_account;

          }
          let { name, logo_url, checkin_short_url, language_id, iso_code, language, links, ampl_id, legal_entity } = configResponse.data.payload;
          default_name = name === null ? default_name : name;
          default_logo_url = logo_url === null ? default_logo_url : logo_url;
          default_short_link = checkin_short_url === null ? default_short_link : checkin_short_url;
          default_links = links.length === 0 ? default_links : links;
          amplId = ampl_id || amplId
          defaultLegalEntity = legal_entity || defaultLegalEntity;

          // Setting up supported language by the company
          if (typeof language_id !== 'undefined' && typeof iso_code !== 'undefined' && typeof language !== 'undefined') {
            _language = {
              id: language_id,
              iso_code: iso_code,
              name: language,
            };
          }
        }

        if (!companyConfig.company_id) {
          toBeSetted = {
            ...toBeSetted,
            name: default_name,
            company_logo: default_logo_url,
            company_id: id,
            hasFetchedConfig: true,
            short_link: default_short_link,
            language: _language,
            vertical_id: parseInt(vertical),
            links: default_links,
            amplId: amplId,
            legalEntity: defaultLegalEntity,
          };
        } else {
          toBeSetted = {
            ...toBeSetted,
            name: default_name,
            company_logo: default_logo_url,
            hasFetchedConfig: true,
            short_link: default_short_link,
            language: _language,
            vertical_id: parseInt(vertical),
            links: default_links,
            amplId: amplId,
            legalEntity: defaultLegalEntity,
          };
        }
        dispatch(configSetter({ ...toBeSetted }));

      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status !== 401) errorSetter(error);
      else console.error(error);
    }
  };

  function PrivateRoute({ children, ...rest }) {
    if (
      (!companyConfig.hasFetchedConfig) &&
      (step.current === "initial" || step.current === "fetched_id") &&
      hasSubdomain(window.location.hostname)
    ) {
      return (
        <Spin
          size="large"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        />
      );
    }

    if (!companyConfig.hasFetchedConfig) {
      return (
        <Route
          {...rest}
          render={({ location, ...rest }) => (
            <Redirect
              exact
              to={{ pathname: "/login", state: { from: location } }}
            />
          )}
        />
      );
    }

    if (companyConfig.hasFetchedConfig) {
      return (
        <Route
          {...rest}
          render={({ location, ...rest }) => {
            return isLogged ? (
              children
            ) : (
              <Redirect
                exact
                to={{ pathname: "/login", state: { from: location } }}
              />
            );
          }}
        />
      );
    }
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <PrivateRoute path="/dashboard">{props.children}</PrivateRoute>
          {(hasError !== null) & <div>Server down</div>}
          <PrivateRoute path="/welcome">
            <Tablet />
          </PrivateRoute>
          <Route path="/public/welcome">
            <Tablet />
          </Route>
          <Route path="/covid">
            <ReportEmergency />
          </Route>
          <Route path="/public/unsubscribe">
            <UnsubscribePage />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/preset" >
            <PasswordReset />
          </Route>
          <Route path="*" component={PageNotfound}/>
        </Switch>
      </Suspense>
    </Router>
  );
});

export default Auth;

import React, { createContext, useContext, useState, useEffect } from "react";
import http from "../../../../../utils/http";
import { useSelector } from 'react-redux';
const AllergensCtx = createContext();
const PreservativesCtx = createContext();

export const useAllergens = () => useContext(AllergensCtx);
export const usePreservatives = () => useContext(PreservativesCtx);

function AllergensContext({ children, ...props }) {
  const companyId = useSelector(state => state.companyConfig.company_id);
  const verticalId = useSelector(state => state.companyConfig.vertical_id);
  const [allergens, setAllergens] = useState([]);
  const [preservatives, setPreservatives] = useState([]);
  useEffect(() => {
    const fetchAllergens = async () => {
      let payload;
      try {
        const response = await http.get(`gastro/${companyId}/allergen`);
        payload = response.data.payload;
      } catch (error) {
        console.error("Not able to fetch allergens", error);
      }
      if (payload) {
        setAllergens(payload.filter(al => !al.is_preservative));
        setPreservatives(payload.filter(al => al.is_preservative));
      }
    }
    if (verticalId === 3) fetchAllergens();
  }, []);

  return (
    <AllergensCtx.Provider value={allergens}>
      <PreservativesCtx.Provider value={preservatives}>
        {children}
      </PreservativesCtx.Provider>
    </AllergensCtx.Provider>);
}

export default AllergensContext;

import { DoubleLeftOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Cascader, Col, Row, Space, Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { cookieDomain, isAllSuperAccount } from "../../utils/envs";
import { getIntl } from "../../utils/intl";
import { getCookie } from "../getCookie";

function AccountSelector({ options = [], accounts = {}, ...props }) {
  const intl = getIntl();
  const _options = [...options];

  const companyId = useSelector((state) => state.companyConfig.company_id);
  const verticalId = useSelector((state) => state.companyConfig.vertical_id);
  const name = useSelector((state) => state.companyConfig.name);
  const superAccount = useSelector(
    (state) => state.companyConfig.super_account
  );
  const userRole = getCookie("_ur");
  const superPath = getCookie("_super_path");
  const isAdmin = userRole === 'admin';
  const isSuperAccount = isAllSuperAccount(superAccount);

  const SubAccountsText = intl.formatMessage({
    id: "SideMenu.SubMenu.Subaccounts.placeholder",
    defaultMessage: "Bitte wählen",
  });

  const accountSeletorTitle = intl.formatMessage({
    id: "SideMenu.SubMenu.Subaccounts.Title",
    defaultMessage: "Hub auswählen",
  });

  const subAccountBackButton = intl.formatMessage({
    id: "SideMenu.SubMenu.Subaccounts.Button.Back",
    defaultMessage: "Zur Accountübersicht",
  });


  
  const changeToAccount = (selectedAccount, _, toMainAccount = false) => {
    const domain = cookieDomain();
    const _selectedAccount = selectedAccount[0];
    const cookieChange = (accountData) => {
      document.cookie = `_c=${accountData.id};domain=${domain};path=/;`;
      document.cookie = `_v=${accountData.vertical};domain=${domain};path=/;`;
      if (isSuperAccount) {
        document.cookie = `_super_name=${name};domain=${domain};path=/;`;
        /**
         * Subdomains cannot be multilevel in order for this to work (multiple dots).
         */
        document.cookie = `_super_path=${
          window.location.hostname.split(".")[0]
        };domain=${domain};path=/;`;
        document.cookie = `_super_c=${companyId};domain=${domain};path=/;`;
        document.cookie = `_super_v=${verticalId};domain=${domain};path=/;`;
      } else {
        document.cookie = `_super_name=${name};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        /**
         * Subdomains cannot be multilevel in order for this to work (multiple dots).
         */
        document.cookie = `_super_path=${
          window.location.hostname.split(".")[0]
        };domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
        document.cookie = `_super_c=${companyId};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        document.cookie = `_super_v=${verticalId};path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      }
      let path = 'dashboard';
      if (toMainAccount){
        path = 'dashboard/sub-accounts'
      }
      if (domain === ".localhost.com") {
        window.location = `http://${accountData.path}.localhost.com:3000/${path}`;
      } else {
        window.location = `http://${accountData.path}.welcomyapp.com/${path}`;
      }
    }

    if (toMainAccount) {
      cookieChange(_selectedAccount);
    } else if (accounts[_selectedAccount]) {
      cookieChange(accounts[_selectedAccount]);
    }
  };


  const backToMainAccount = () => {
    const superName = getCookie("_super_name");
    const superPath = getCookie("_super_path");
    const superId = getCookie("_super_c");
    const superVertical = getCookie("_super_v");
    changeToAccount(
      [{
        name: superName,
        id: superId,
        vertical: superVertical,
        path: superPath,
      }], {}, true);
  };
  if (superAccount === null) return null;
  else if (isSuperAccount && isAdmin) {
    return (
      <Row justify="center" align="stretch">
        <Col xs={24}>
          <Divider orientation="left" plain className="m-b-6">
            {accountSeletorTitle}
          </Divider>
          <Space className="sidemenu__multi-account">
            <Cascader
              options={_options}
              dropdownMatchSelectWidth={true}
              placeholder={SubAccountsText}
              onChange={changeToAccount}
              showSearch
              allowClear
            />
          </Space>
          <Divider className="m-t-12" />
        </Col>
      </Row>
    );
  } else if (!isSuperAccount && isAdmin && superPath) {
    return (
      <Row justify="center" align="stretch">
        <Col xs={24}>
          <Divider orientation="left" plain className="m-b-6">
            {accountSeletorTitle}
          </Divider>
          <div className="sidemenu__multi-account sidemenu--small-button">
            <Button
              type="primary"
              onClick={backToMainAccount}
              icon={<DoubleLeftOutlined />}
              block
            >
              {subAccountBackButton}
            </Button>
          </div>
          <Divider className="m-t-12" />
        </Col>
      </Row>
    );
  } else return null;

}

export default AccountSelector;

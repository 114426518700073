import React, { memo, useState } from "react";
import { displayNotification } from "../components/Notification";
import { getIntl } from "../utils/intl";
import { Form, Upload } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getBase64 } from "./WelcomyHub/utils";
import { UploadOutlined } from "@ant-design/icons";

export const LogoUpload = memo(({ onFormChange, inputLabel = "", logoImage = "", required = false, disabled = false }) => {
  const intl = getIntl();
  const [form] = Form.useForm();
  const [logoToBeSaved, setLogoToBeSaved] = useState(logoImage);
  const hub_logo = useSelector((state) => state.companyConfig.hub_logo);


  const uploadReadylabel = intl.formatMessage({
    id: "Ready",
    defaultMessage: "Bereit",
  });

  const beforeUpload = (file) => {
    // Check if image is less than 2 Mb.
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      const title = intl.formatMessage({
        id: "SetupPage.Notification.ImageTooLarge.Title",
        defaultMessage: "Bildgröße zu groß",
      });
      const body = intl.formatMessage({
        id: "SetupPage.Notification.ImageTooLarge.Body",
        defaultMessage: "Das Bild muss kleiner als 2 MB sein",
      });
      displayNotification("error", title, body);
    }
    // Prevent auto-upload
    return false;
  };

  const handleFileChange = (fileList) => {
    // Update the form field 'companyLogo' when a new file is selected
    form.setFieldsValue({ companyLogo: fileList });
    if (fileList?.length > 1) {
      fileList.shift();
    }

    getBase64(fileList[0].originFileObj, setLogoToBeSaved);

    onFormChange({
      ...form.getFieldsValue(),
      companyLogo: fileList,
    });
  };

  // Upload button configuration
  const UploadButton = () => (
    <div>
      {hub_logo ? (
        <img src={hub_logo} alt="logo" style={{ width: "100%" }} />
      ) : (
        <div>
          <UploadOutlined />
          <div className="ant-upload-text">Upload Logo</div>
        </div>
      )}
    </div>
  );

  return (
    <Form.Item
      name="companyLogo"
      valuePropName="fileList"
      getValueFromEvent={(e) => e && e.fileList}
      rules={[{ required: required, message: "Please upload a company logo" }]}
      wrapperCol={{ span: 24 }}
      label={
        inputLabel ? inputLabel : <FormattedMessage
          id="SetupPage.Form.Logo"
          defaultMessage="Firmenlogo"
        ></FormattedMessage>
      }
    >
      <Upload
        name="logo"
        type="button"
        listType="picture-card"
        accept=".jpg,.png"
        multiple={false}
        handleFileChange
        beforeUpload={beforeUpload}
        showUploadList={false}
        maxCount={1}
        onChange={(info) => handleFileChange(info.fileList)}
        disabled={disabled}
      >
        {logoToBeSaved ? (
          <div className="upload-button-wrapper">
            <img src={logoToBeSaved} alt="logo" style={{ width: "100%" }} />
            <div className="upload-label">{uploadReadylabel}</div>
          </div>
        ) : (
          <UploadButton />
        )}
      </Upload>
    </Form.Item>
  );
});

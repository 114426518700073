import React from 'react';
import WelcomyHubPreview from '../WelcomyHubPreview/WelcomyHubPreview';
import WelcomyHubConfig from './WelcomyHubConfig';

const PreviewWrapperConfigHub = (props) => {
  return (
    <WelcomyHubPreview>
      <WelcomyHubConfig />
    </WelcomyHubPreview>
  );
}

export default PreviewWrapperConfigHub;
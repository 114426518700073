import React, { useEffect, useState } from "react";
import { Button, Col, Input, Space, Table } from "antd";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import UpdateUserModal from "./UpdateUser";
import { getIntl } from "../../utils/intl";
import { displayNotification } from "../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserApi } from "../../api/userManagementApiService";
import { deleteUser } from "../../redux/actions/usersActions";
import { getCookie } from "../../components/getCookie";

const UserManagementTable = ({ users }) => {
  const intl = getIntl();
  const dispatch = useDispatch();
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [updateUser, setUpdateUser] = useState(null);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const companyName = useSelector(state => state.companyConfig.name);
  const sub_accounts = useSelector(
    (state) => state.companyConfig["sub_accounts"],
  );
  const accountNames = {};
  accountNames[companyId] = companyName;
  sub_accounts.map((subAccount, _) => {
    accountNames[subAccount.id] = subAccount.name;
  });
  const superAccount = useSelector(
    (state) => state.companyConfig.super_account,
  );
  const userRole = getCookie("_ur");
  const isAdmin = userRole === 'admin';
  const editButtonText = intl.formatMessage({
    id: "Edit",
  });
  const deleteButtonText = intl.formatMessage({
    id: "Delete",
  });
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");

  const handleEditUser = (workspace) => {
    setUpdateUser(workspace);
  };

  const handleDeleteUser = (key) => {
    setConfirmationModalVisible(true);
    setDeleteUserId(key);
  };

  const handleUpdateModalConfirm = (e) => {
    setUpdateUser(null);
  };

  const handleConfirm = () => {
    // delete user
    deleteUserApi(deleteUserId, companyId)
      .then(() => {
        dispatch(deleteUser(deleteUserId));
        displayNotification("success", "Success", "User deleted successfully.");
        setConfirmationModalVisible(false);
      })
      .catch((e) => {
        displayNotification(
          "error",
          "Error",
          "Failed to delete user. try again later.",
        );
      });
  };

  const handleCancel = () => {
    setConfirmationModalVisible(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "",
      key: "actions",
      render: (text, user) => (
        <Space size="middle">
          {isAdmin && <Button type="primary" onClick={() => handleEditUser(user)}>{editButtonText}</Button>}
          {isAdmin && <Button onClick={() => handleDeleteUser(user.id)} danger>
            {deleteButtonText}
          </Button>}
        </Space>
      ),
    },
  ];
  if (superAccount){
    const hubColumn = {
      title: "Hub",
      dataIndex: "company_id",
      render: (item) => {return accountNames[item];},
      key: "role",
    };
    columns.splice(1, 0, hubColumn);
  }

  return (
    <>
      <div className="workspace-wrapper max-width-1200">
        <Col span={24}>
          <div className="workspaces-container">
            <Input.Search
              className="workspace-search"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Table dataSource={users} columns={columns} pagination={false} />
            <ConfirmationModal
              visible={confirmationModalVisible}
              title="Confirmation"
              message="Are you sure you want to delete the user?"
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
            <UpdateUserModal
              user={updateUser}
              onConfirm={(e) => handleUpdateModalConfirm(e)}
              onCancel={() => setUpdateUser(null)}
            />
          </div>
        </Col>
      </div>
    </>
  );
};
export default UserManagementTable;

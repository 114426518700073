import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadText: {
    id: "Upload",
    defaultMessage: "Datei hochladen",
  },
  linkCall: {
    id: "WelcomyHub.Link.Call",
    defaultMessage: "Telefonanruf",
  },
  linkTypeText: {
    id: 'WelcomyHub.Link.Type',
    defaultMessage: 'Art des Links',
  },
  integrationDocsTitle: {
    id: 'WelcomyHub.Link.Title.Integration',
    defaultMessage: 'Integrationen & Dokumente',
  },
  contactSocialTitle: {
    id: 'WelcomyHub.Link.Title.Contact',
    defaultMessage: 'Kontaktinformationen & Social Links',
  },
  email: {
    id: "Email",
    defaultMessage: "Email",
  },
  newRibbon: {
    id: 'Ribbon.New',
    defaultMessage: "Neu",
  },
  AiSupportAssistant: {
    id: "WelcomyHub.Link.AiPdf",
    defaultMessage: "Support KI",
  },
});

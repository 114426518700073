import { LINK_TYPES } from "./linkMappings";

export const linkCouldBeIcon = (linkType) => {
  if (
    linkType === LINK_TYPES.INSTAGRAM ||
    linkType === LINK_TYPES.TWITTER ||
    linkType === LINK_TYPES.FACEBOOK ||
    linkType === LINK_TYPES.WHATSAPP ||
    linkType === LINK_TYPES.LINKEDIN ||
    linkType === LINK_TYPES.PHONE ||
    linkType === LINK_TYPES.EMAIL
  ) {
    return true;
  }
  return false;
};

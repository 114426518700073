import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Alerts from "../pages/Alerts";
import DigitalMenu from "../pages/DigitalMenu";
import PrintMenu from "../pages/PrintMenu";
import PageNotFound from "../pages/PageNotFound";
import { useDispatch, useSelector } from "react-redux";
import Subscriptions from "../pages/Subscriptions";
import Impressum from "../pages/Impressum";
import SubAccounts from "../pages/SubAccounts";
import { isAllSuperAccount } from "../utils/envs";
import DataProtection from "../pages/DataProtection";
import PreviewWrapperHub from "../pages/WelcomyHub/PreviewWrapperHub";
import PreviewWrapperConfigHub from "../pages/WelcomyHub/PreviewWrapperConfigHub";
import Workspaces from "../pages/workspaces";
import UserManagement from "../pages/UserManagement";
import HubAnalytics from "../pages/HubAnalytics";
import { getHubConfig } from "../api/layOutApiService";
import { setHubConfig } from "../redux/actions";
import CompanySetup from "../pages/CompanySetup";

const MainContent = (props) => {
  const dispatch = useDispatch();
  const vertical_id = useSelector((state) => state.companyConfig.vertical_id);
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const superAccount = useSelector(
    (state) => state.companyConfig.super_account,
  );

  const loadHubConfig = async () => {
    const response = await getHubConfig(companyId);
    dispatch(setHubConfig({
      ...response.data.payload,
    }));
  }

  useEffect(() => {
    if (companyId) {
      loadHubConfig();
    }
  }, [companyId]);

  let { path } = useRouteMatch();
  if (vertical_id) {
    return (
      <div className="routes-wrapper">
        <Switch>
          <Route path={`${path}/workspaces`} component={Workspaces} />
          <Route path={`${path}/user-management`} component={UserManagement} />
          <Route exact path={`${path}/`} component={PreviewWrapperHub} />
          <Route
            exact
            path={`${path}/hub/config`}
            component={PreviewWrapperConfigHub}
          />
          <Route path={`${path}/alerts`} component={Alerts} />
          {(vertical_id === 3 || vertical_id === 4) && (
            <Switch>
              <Route path={`${path}/digital-menu`} component={DigitalMenu} />
              <Route path={`${path}/print-menu`} component={PrintMenu} />
              <Route path={`${path}/subscriptions`} component={Subscriptions} />
              <Route path={`${path}/impressum`} component={Impressum} />
              <Route path={`${path}/hub/analytics`} component={HubAnalytics} />
              <Route
                path={`${path}/data-protection`}
                component={DataProtection}
              />
              <Route path={`${path}/company-setup`} component={CompanySetup} />
              {isAllSuperAccount(superAccount) && (
                <Switch>
                  <Route path={`${path}/sub-accounts`} component={SubAccounts} />
                </Switch>
              )}
            </Switch>
          )}
          <Route path={"*"} component={PageNotFound} />
        </Switch>
      </div>
    );
  } else return null;
};

export default React.memo(MainContent);

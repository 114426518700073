import { createIntl, createIntlCache } from 'react-intl';
import English from '../i18n/en.json';
import German from '../i18n/de.json';
// import Greek from '../i18n/gr.json';

export const getIntl = () => {
    const locale = window.localStorage.getItem("_wl_locale") || "de";
    let lang = German;
    if (locale === 'en') {
        lang = English;
    }
    // else if (locale === 'gr') {
    //     lang = Greek;
    // }
    const cache = createIntlCache();
    return createIntl({
        locale: locale,
        messages: lang
    }, cache);
}

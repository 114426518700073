import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { getIntl } from "../../utils/intl";
import InviteUserModal from "../../components/InviteUserModal";
import UserManagementTable from "./Table";
import { getUsersApi } from "../../api/userManagementApiService";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../../redux/actions/usersActions";
import { getCookie } from "../../components/getCookie";

const UserManagement = () => {
  const intl = getIntl();
  const dispatch = useDispatch();
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const handleOnCancelInviteUser = () => {
    setInviteUserModal(false);
  };
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const users = useSelector((state) => state.usersManagement.users);

  const userRole = getCookie("_ur");
  const isAdmin = userRole === 'admin';

  const userManagementTitle = intl.formatMessage({
    id: "UserManagement.Title",
    defaultMessage: "Nutzerverwaltung"
  });
  const userManagementDescription = intl.formatMessage({
    id: "UserManagement.Description",
    defaultMessage: "Verwalten Sie hier Ihre Benutzer"
  });
  const inviteUserButtonText = intl.formatMessage({
    id: "UserManagement.InviteUser.Button.Text",
    defaultMessage: "Benutzer einladen"
  });


  useEffect(() => {
    getUsersApi(companyId).then((users) => {
      dispatch(setUsers(users?.data.payload));
    });
  }, []);

  return (
    <>
      <div className="sub-accounts__wrapper">
        <InviteUserModal
          visible={inviteUserModal}
          onCancel={handleOnCancelInviteUser}
        ></InviteUserModal>
        <div className="workspace-header">
          <h1>
            {userManagementTitle}
            <span className="block-type"> {userManagementDescription}</span>
          </h1>

          <Button type="primary" onClick={() => setInviteUserModal(true)}>
            {isAdmin && inviteUserButtonText}
          </Button>
        </div>
        <UserManagementTable users={users}></UserManagementTable>
      </div>
    </>
  );
};

export default UserManagement;

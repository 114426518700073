const defaultState = {
    navbarDark: false,
};
const layoutConfigReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_LAYOUT':
            return {...state, navbarDark: action.layoutConfig};

        default:
            return state;
    }
};

export default layoutConfigReducer;
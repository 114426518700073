import { LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qrCodeExportModalOpen } from "../../../redux/actions/qrCodeExport";
import { getIntl } from "../../../utils/intl";
import { generateQRCodeTemplate } from "../../QrCode";
import { messages } from "./translations";

const { Paragraph, Title } = Typography;

function ActionCards() {
  const dispatch = useDispatch();
  const companyLogo = useSelector((state) => state.companyConfig.company_logo);
  const hubLogo = useSelector((state) => state.hubConfig.hubLogo);
  const shortLink = useSelector((state) => state.companyConfig.short_link);
  const verticalId = useSelector((state) => state.companyConfig.vertical_id);
  const modalOpen = useSelector((state) => state.qrCodeExportConfig.modalOpen);

  const [shortUrlModalOpen, setShortUrlModalOpen] = useState(false);

  const IS_BROWSER_CLIPBOARD_ALLOWED = navigator.clipboard;

  const logo = hubLogo || companyLogo;

  const [isLoadingQr, setIsLoadingQr] = useState(false);

  const translation = getIntl();

  const generateQrCode = () => {
    if (verticalId === 4) {
      changeQrModalVisibility();
    } else {
      // Backwards compability with restaurants, check useEffect.
      setIsLoadingQr(true);
    }
  };

  // Trigger QR Code download for restaurants as they use a different
  // template for now.
  useEffect(() => {
    if (isLoadingQr) {
      generateQRCodeTemplate(logo, shortLink, () =>
        setTimeout(() => {
          setIsLoadingQr(false);
        }, 2500)
      );
    }
  }, [isLoadingQr]);

  const changeQrModalVisibility = () => {
    dispatch(qrCodeExportModalOpen(!modalOpen));
  };

  const copyShortUrl = () => {
    if (shortLink) {
      /**
       * We tried copying the url to the clipboard but that presented
       * itself as tricky across browsers so we decided to default
       * to just displaying the url to the user and let them manually copy it.
       */
      setShortUrlModalOpen(true);
    }
  };

  return (
    <>
      <div className="qr-code-btn__wrapper">
        <div className="qr-code-btn__wrapper--vertical">
          <Paragraph strong className="card-text">
            {translation.formatMessage(messages.downloadText)}
          </Paragraph>
          <Paragraph strong className="card-text--blue">
            {translation.formatMessage(messages.downloadSubtext)}
          </Paragraph>
          {!isLoadingQr ? (
            <Button type="primary" onClick={() => generateQrCode()}>
              {translation.formatMessage(messages.downloadAction)}
            </Button>
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
        </div>
      </div>
      <div className="qr-code-btn__wrapper">
        <div className="qr-code-btn__wrapper--vertical">
          <Paragraph strong className="card-text">
            {translation.formatMessage(messages.copyText)}
          </Paragraph>
          <Paragraph strong className="card-text--blue">
            {translation.formatMessage(messages.copySubtext)}
          </Paragraph>
          <Button type="primary" onClick={() => copyShortUrl()}>
            {navigator.clipboard
              ? translation.formatMessage(messages.copyAction)
              : translation.formatMessage(messages.copyGenerateBtn)}
          </Button>
        </div>
      </div>
      <Modal
        centered
        open={shortUrlModalOpen}
        destroyOnClose
        onCancel={() => setShortUrlModalOpen(false)}
        footer={[
          <Button
            key="shortUrlModalBtn"
            onClick={() => setShortUrlModalOpen(false)}
          >
            {translation.formatMessage(messages.modalCloseBtn)}
          </Button>,
        ]}
      >
        <div className="m-t-12 m-b-12">
          <Title level={3} className="text-center">
            {translation.formatMessage(messages.modalTitle)}
          </Title>
          <Paragraph className="text-center p-l-32 p-r-32">
            {translation.formatMessage(messages.modalDisclaimer)}
          </Paragraph>
          <Title level={4} className="text-center highlight-text--primary">
            {shortLink}
          </Title>
        </div>
      </Modal>
    </>
  );
}

export default ActionCards;

import React, { useEffect, useState } from "react";
import PreMenu, { generateLogo } from "../../pages/Tablet/public/components/Restaurant/PreMenu";
import { LINK_VARIATIONS } from "../NewLink/linkMappings";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { getIntl } from "../../utils/intl";
import {
  setLivePreviewLinks,
  updateLivePreviewCompanySetup,
} from "../../redux/actions/livePreviewActions";
import { linkIcons } from "../../utils/LinkIcons";

const LivePreview = () => {
  const { company_id, company_logo } = useSelector(
    (state) => state.companyConfig,
  );
  const intl = getIntl();
  const { Title, Paragraph } = Typography;
  const readMore = intl.formatMessage({
    id: "WelcomyHubConfig.Phone.ReadMore",
    defaultMessage: "Weiter lesen",
  });
  const previewLinkIcons = linkIcons;
  const dispatch = useDispatch();
  const links = useSelector((state) => state.companyConfig.links);
  const livePreviewLinks = useSelector((state) =>
    state.livePreview.links.filter((link) => link.variation === LINK_VARIATIONS.BUTTON),
  );
  const livePreviewIcons = useSelector((state) =>
    state.livePreview.links.filter((link) => link.variation === LINK_VARIATIONS.ICON),
  );
  const { hubName, logo, companyTitle, description } = useSelector(
    (state) => state.livePreview.companySetup,
  );
  
  const companyName = useSelector(state => state.companyConfig.name);
  const companyLogo = useSelector(state => state.companyConfig.company_logo);
  
  const _hubName = useSelector(state => state.hubConfig.name);
  const hubLogo = useSelector(state => state.hubConfig.hubLogo);
  const hubTitle = useSelector(state => state.hubConfig.title);
  const hubSubtitle = useSelector(state => state.hubConfig.subtitle);


  useEffect(() => {
    const name = _hubName || companyName;
    const logo = hubLogo || companyLogo;
    dispatch(updateLivePreviewCompanySetup({
      hubName: name,
      description: hubSubtitle,
      companyTitle: hubTitle,
      logo,
    }));
  }, [hubLogo]);

  useEffect(() => {
    const previewLinks = links?.filter((link) => link.visible === true);
    dispatch(setLivePreviewLinks(previewLinks));
  }, []);

  return (
    <>
      <div className="preview p-l-12 p-r-12 p-t-32">
        <div className="preview__notch"></div>
        <div style={{height: '100%', width: '100%'}}>
          <div className="tablet-wrapper">
            <div className="tablet-content-wrapper">
              <PreMenu isPreview/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LivePreview;

import { Badge, Divider, Radio, Space, Typography } from "antd";
import {
  CloudUploadOutlined,
  FacebookOutlined,
  FileOutlined,
  FormOutlined,
  InstagramOutlined,
  LaptopOutlined,
  LinkedinFilled,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  ProfileOutlined,
  RobotOutlined,
  SendOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { messages } from "./Translations";
import "./LinksVariations.scss";
import { getIntl } from "../../utils/intl";

const { Title } = Typography;

const LinkVariations = ({ initialValue, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue || "link");
  const translation = getIntl();

  useState(() => {
    onChange(selectedValue);
  }, [selectedValue, onChange]);

  const linkTypeChanges = (e) => {
    const linkType = e.target.value;
    setSelectedValue(linkType);
    onChange(linkType);
  };

  return (
    <div className="links-variations">
      <Title level={3}>{translation.formatMessage(messages.linkTypeText)}</Title>
      <div className="common-links m-b-12">
        <Title level={4}>{translation.formatMessage(messages.integrationDocsTitle)}</Title>
        <Radio.Group
          defaultValue={initialValue || "link"}
          value={selectedValue}
          buttonStyle="solid"
          size="large"
          onChange={linkTypeChanges}
        >
          <Radio.Button value="link">
            <Space>
              <LinkOutlined />
              Link
            </Space>
          </Radio.Button>
          <Radio.Button value="file">
            <Space>
              <FileOutlined />
              Cloud-Link
            </Space>
          </Radio.Button>
          <Radio.Button value="upload">
            <Space>
              <CloudUploadOutlined />
              {translation.formatMessage(messages.uploadText)}
            </Space>
          </Radio.Button>
          <Radio.Button value="software">
            <Space>
              <LaptopOutlined />
              Software-Tool
            </Space>
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="social-networks m-b-12">
        <Title level={4}>{translation.formatMessage(messages.contactSocialTitle)}</Title>
        <Radio.Group
          defaultValue={initialValue || "link"}
          value={selectedValue}
          buttonStyle="solid"
          size="large"
          onChange={linkTypeChanges}
        >
          <Radio.Button value="linkedin">
            <Space>
              <LinkedinFilled />
              LinkedIn
            </Space>
          </Radio.Button>
          <Radio.Button value="whatsapp">
            <Space>
              <WhatsAppOutlined />
              Whatsapp
            </Space>
          </Radio.Button>
          <Radio.Button value="instagram">
            <Space>
              <InstagramOutlined />
              Instagram
            </Space>
          </Radio.Button>
          <Radio.Button value="twitter">
            <Space>
              <TwitterOutlined />
              Twitter
            </Space>
          </Radio.Button>
          <Radio.Button value="facebook">
            <Space>
              <FacebookOutlined />
              Facebook
            </Space>
          </Radio.Button>
          <Radio.Button value="email">
            <Space>
              <MailOutlined />
              E-mail
            </Space>
          </Radio.Button>
          <Radio.Button value="phone">
            <Space>
              <PhoneOutlined />
              {translation.formatMessage(messages.linkCall)}
            </Space>
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="welcomy-links m-b-12">
        <Title level={4}>Welcomy Tools</Title>
        <Radio.Group
          defaultValue={initialValue || "link"}
          value={selectedValue}
          buttonStyle="solid"
          size="large"
          onChange={linkTypeChanges}
        >
          <Radio.Button value="newsletter">
            <Space>
              <SendOutlined />
              Newsletter
            </Space>
          </Radio.Button>
          <Radio.Button value="product_and_services">
            <Space>
              <ProfileOutlined />
              Produktseite
            </Space>
          </Radio.Button>
          <Radio.Button value="impressum">
            <Space>
              <MailOutlined />
              Impressum
            </Space>
          </Radio.Button>
          <Radio.Button value="pdf-ai">
              <Badge.Ribbon text={translation.formatMessage(messages.newRibbon).toUpperCase()} color="gold"  className="ribbon">
                <div>
                  <Space>
                    <RobotOutlined />
                    {translation.formatMessage(messages.AiSupportAssistant)}
                  </Space>
                </div>
              </Badge.Ribbon>
            </Radio.Button>
        </Radio.Group>
      </div>
      <Divider />
    </div>
  );
};
export default LinkVariations;

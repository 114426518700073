import { cookieDomain } from './envs';

const clearCookies = () => {
    const domain = cookieDomain();
    document.cookie = `_r=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Refresh Token
    document.cookie = `_a=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Access Token
    document.cookie = `_c=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Company Id
    document.cookie = `_v=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Vertical Id
    document.cookie = `_u=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // User Id
    document.cookie = `_ur=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account Vertical

    // Delete super account cookies just to be sure that sub accounts will leave no trace behind.
    document.cookie = `_super_name=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account path
    document.cookie = `_super_path=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account path
    document.cookie = `_super_c=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Supper Account Id
    document.cookie = `_super_v=;path=/;domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`; // Super Account Vertical
};

export { clearCookies };

import React, { useEffect, useRef } from "react";

import EditorTheme from "./theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import Toolbar from "./plugins/Toolbar";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevel from "./plugins/ListMaxIndentLevel";
import CodeHighlight from "./plugins/CodeHighlight";
import AutoLink from "./plugins/AutoLink";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useDispatch, useSelector } from "react-redux";
import { setRichDocumentSaved } from "../../redux/actions";

import http from "../../utils/http";
import { displayNotification } from '../../components/Notification';
import { $getRoot, $getSelection } from "lexical";
import { getIntl } from "../../utils/intl";

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

const EditorPlugins = ({ initialEditorState, saveDocument}) => {
  let _initialEditorState = initialEditorState;
  const [editor] = useLexicalComposerContext();
  const editorStateRef = useRef();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const shouldSave = useSelector(state => state.editorsConfig.impressumEditor.shouldSave);
  const parser = new DOMParser();
  const intl = getIntl();

  const successTitle = intl.formatMessage({
    id: 'Success',
    defaultMessage: 'Erfolg!',
  });

  const successBody = intl.formatMessage({
    id: 'ImpressumPage.Notification.Success',
    defaultMessage: 'Dokument wurde erfolgreich gespeichert!',
  });

  const errorTitle = intl.formatMessage({
    id: 'Error',
    defaultMessage: 'Etwas ist schief gelaufen!',
  });

  const errorBody = intl.formatMessage({
    id: 'ImpressumPage.Notification.Error',
    defaultMessage: 'Das Dokument konnte nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.',
  });

  const savePost = async (html) => {
    try {
      const result = await saveDocument(html);
      if (result.status >= 200 && result.status < 300) {
        displayNotification('success', successTitle, successBody);
      }
    } catch (error) {
      console.log(error);
      displayNotification('error', errorTitle, errorBody);
    }
    dispatch(setRichDocumentSaved(false));
  };

  useEffect(() => {
    editor.update(() => {
      if (_initialEditorState) {
        const dom = parser.parseFromString(_initialEditorState, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        const selection = $getSelection();
        selection.insertNodes(nodes);
      }
    })
  }, [_initialEditorState])

  useEffect(() => {
    if (!!shouldSave) {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        savePost(htmlString)
      });
    }
  }, [shouldSave]);

  return (
    <div className="editor-container">
      <Toolbar />
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
        />
        <HistoryPlugin />
        {/* <TreeViewPlugin /> */}
        <AutoFocusPlugin />
        <CodeHighlight />
        <ListPlugin />
        <LinkPlugin />
        <AutoLink />
        <ListMaxIndentLevel maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin
          onChange={(editorState) => (editorStateRef.current = editorState)}
        />
      </div>
    </div>
  );
};

export default function Editor({ initialEditorState, saveDocument }) {
  const editorConfig = {
    // The editor theme
    theme: EditorTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <EditorPlugins initialEditorState={initialEditorState} saveDocument={saveDocument} />
    </LexicalComposer>
  );
}

import http from "../utils/http";

export const createLink = (companyId, links) => {
    return http.post(`/company/${companyId}/link`, { links })
}

export const getLinks =  (companyId) => {
    return  http.get(`/company/${companyId}/link`);
}

export const deleteLink = (companyId, linkId) => {
    return http.delete(`/company/${companyId}/link/${linkId}`);
}

export const updateLinkApiService = (companyId, linkId, updatedLink) => {
    return http.put(`/company/${companyId}/link/${linkId}`, { ...updatedLink })
}

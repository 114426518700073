import { Col, Radio, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { qrCodeExportTrigger, selectQrExportType } from "../../redux/actions/qrCodeExport";
import { getIntl } from "../../utils/intl";
import ModalWrapper from "../Modal";
import QrTemplate from "./QrTemplate";
import { qrModalStrings as translations } from './Translations';

const { Title, Paragraph } = Typography;

export const QR_CODE_PNG_TRANSPARENT_BG = "QR_CODE_PNG_TRANSPARENT_BG";
export const QR_CODE_WHITE_CARD = "QR_CODE_WHITE_CARD";
export const QR_CODE_DARK_CARD = "QR_CODE_DARK_CARD";
export const QR_CODE_ORANGE_CARD = "QR_CODE_ORANGE_CARD";

const QRCodePicker = ({changeQrModalVisibility, ...props}) => {
  const translate = getIntl();
  const qrCodeSelectedType = useSelector(
    (state) => state.qrCodeExportConfig.selectedQrExportType
  );
  const modalOpen = useSelector(
    (state) => state.qrCodeExportConfig.modalOpen
  );
  const dispatch = useDispatch();

  const setExportType = (type) => {
    dispatch(selectQrExportType(type));
  };

  return (
    <ModalWrapper
      visible={modalOpen}
      width={720}
      onOk={() => {
        dispatch(qrCodeExportTrigger(true));
        changeQrModalVisibility();
      }}
      okButtonProps={{ disabled: !qrCodeSelectedType }}
      cancelText={translate.formatMessage(translations.cancel)}
      onCancel={() => {
        setExportType(null);
        changeQrModalVisibility();
      }}
    >
      <div className="QrCodePicer__wrapper">
        <Title level={3}>{translate.formatMessage(translations.title)}</Title>
        <Paragraph>
          {translate.formatMessage(translations.disclaimer)}
        </Paragraph>
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Row justify="center">
              <Col>
                <QrTemplate type={QR_CODE_PNG_TRANSPARENT_BG} />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Radio
                  checked={qrCodeSelectedType === QR_CODE_PNG_TRANSPARENT_BG}
                  onClick={() => setExportType(QR_CODE_PNG_TRANSPARENT_BG)}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph strong className="m-b-0 m-t-6">
                  {translate.formatMessage(translations.pngTitle)}
                </Paragraph>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph className="text-center">
                  {translate.formatMessage(translations.pngSubtitle)}
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Row justify="center">
              <Col>
                <QrTemplate type={QR_CODE_WHITE_CARD} />
              </Col>
            </Row>
            <Row justify="center">
              <Radio
                checked={qrCodeSelectedType === QR_CODE_WHITE_CARD}
                onClick={() => setExportType(QR_CODE_WHITE_CARD)}
              />
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph strong className="m-b-0 m-t-6">
                  {translate.formatMessage(translations.cardLightTitle)}
                </Paragraph>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph className="text-center">
                  {translate.formatMessage(translations.cardLightSubtitle)}
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Row justify="center">
              <Col>
                <QrTemplate type={QR_CODE_DARK_CARD} />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Radio
                  checked={qrCodeSelectedType === QR_CODE_DARK_CARD}
                  onClick={() => setExportType(QR_CODE_DARK_CARD)}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph strong className="m-b-0 m-t-6">
                  {translate.formatMessage(translations.cardDarkTitle)}
                </Paragraph>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Paragraph className="text-center">
                  {translate.formatMessage(translations.cardDarkSubtitle)}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ModalWrapper>
  );
};

export default QRCodePicker;

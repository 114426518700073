import { QR_CODE_EXPORT_SELECTION, QR_CODE_EXPORT_TRIGGER, QR_CODE_MODAL_OPEN } from "../actions/qrCodeExport";

const defaultState = {
  selectedQrExportType: null,
  qrCodeExportTrigger: false,
  modalOpen: false,
};
const qrCodeExportReducer = (state = defaultState, action) => {
  switch (action.type) {
      case QR_CODE_EXPORT_SELECTION:
          return {...state, selectedQrExportType: action.payload};
      case QR_CODE_EXPORT_TRIGGER:
          return {...state, qrCodeExportTrigger: action.payload};
      case QR_CODE_MODAL_OPEN:
         return {...state, modalOpen: action.payload}
      default:
          return state;
  }
};

export default qrCodeExportReducer;

import http from '../../utils/http';

const assignPublicCookie = async (company_id, callback) => {
  if (company_id) {
    const response = await http.get(`/company/${company_id}/assign_token`);
    if (callback) {
      callback(response);
    }
  }
}


export { assignPublicCookie };

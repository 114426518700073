
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    linkTypeText: {
        id: 'WelcomyHub.Link.Type',
        defaultMessage: 'Art des Links',
    },
    linkVariationText: {
        id: 'WelcomyHub.Link.Variation',
        defaultMessage: 'Variantion des Links',
    },
    withTextText: {
        id: 'WelcomyHub.Link.Variation.Text',
        defaultMessage: 'Als Button',
    },
    withoutTextText: {
        id: 'WelcomyHub.Link.Variation.Small',
        defaultMessage: 'Als Icon',
    },
    linkText: {
        id: 'WelcomyHub.Link.Text',
        defaultMessage: 'Linktext',
    },
    visibleText: {
        id: 'WelcomyHub.Visible.Text',
        defaultMessage: 'Sichtbar',
    },
    digitalCardText: {
        id: 'WelcomyHub.VirtualCard.Text',
        defaultMessage: 'Zur digitalen Visitenkarte hinzufügen',
    },
    clickPreventionText: {
        id: 'WelcomyHub.click.prevention',
        defaultMessage: 'Beenden Sie Ihre aktuelle Arbeit, bevor Sie fortfahren.',
    },
    uploadText: {
        id: 'Upload',
        defaultMessage: 'Datei hochladen',
    },
    phoneAddressText: {
        id: "Phone",
        defaultMessage: "Telefon",
    },
    whatsappAddressText: {
        id: 'whatsapp.text',
        defaultMessage: "Telefonnummer(Beispiel: 49xxxxxxxxxxx)",
    },
    fileText: {
        id: 'File.External',
        defaultMessage: 'Cloud-Link (z.B. Google Drive, iCloud, etc.)'
    },
    softwareText: {
        id: 'Link.Software',
        defaultMessage: 'Link zum Software-Widgets oder integrierte Webpage'
    },
    address: {
        id: "Address",
        defaultMessage: "Adresse",
    },
    deleteError: {
        id: "WelcomyHub.Delete.Error",
        defaultMessage: "Der Link konnte nicht gelöscht werden",
    },
    errorTitle: {
        id: "Error",
        defaultMessage: "Etwas ist schief gelaufen!",
    },
    saveError: {
        id: "WelcomyHub.Save.Error",
        defaultMessage: "Der Link konnte nicht gespeichert werden",
    },
    uploadError: {
        id: "Upload.Error",
        defaultMessage: "Dateiupload fehlgeschlagen. Bitte versuchen Sie es erneut"
    }
    ,
    addLink: {
        id: "WelcomyHub.Link.Add",
        defaultMessage: "Link hinzufügen"
    },
    cancel: {
        id: "Cancel",
        defaultMessage: "Abbrechen"
    },
    updateLink: {
        id: "WelcomyHub.Link.Update",
        defaultMessage: "Link aktualisieren"
    },
    goTo: {
        id: "Welcomyhub.Link.Goto",
        defaultMessage: "Gehe zu"
    },
    linkCreationSuccessTitle: {
        id: "WelcomyHub.Save.Success.Title",
        defaultMessage: "Link wurde erstellt"
    },
    linkCreationSuccessBody: {
        id: "WelcomyHub.Save.Success.Body",
        defaultMessage: "Ihre Änderungen waren erfolgreich."
    },
    linkUpdateSuccessTitle: {
        id: "WelcomyHub.Update.Success.Title",
        defaultMessage: "Link wurde aktualisiert"
    },
    linkUpdateSuccessBody: {
        id: "WelcomyHub.Update.Success.Body",
        defaultMessage: "Ihre Änderungen waren erfolgreich."
    },
    AiSupportAssistantDescription: {
        id: "WelcomyHub.Link.AiPdf.Description",
        defaultMessage: "Laden Sie ein PDF-Dokument hoch, das alle relevanten Informationen zu Ihrem Produkt enthält. Welcomy wird die Arbeit automatisch für Sie erledigen."
    },
    UploadMissingErrorTitle: {
        id: "WelcomyHub.Link.Upload.Missing.Error.Title",
        defaultMessage: "Dokument nicht hochgeladen.",
    },
    UploadMissingErrorBody: {
        id: "WelcomyHub.Link.Upload.Missing.Error.Body",
        defaultMessage: "Bitte laden Sie das Dokument hoch, bevor Sie den Link speichern.",
    },
    NoLinkErrorTitle: {
        id: "WelcomyHub.Link.Missing.Error.Title",
        defaultMessage: "Link-URL fehlt",
    },
    NoLinkErrorBody: {
        id: "WelcomyHub.Link.Missing.Error.Body",
        defaultMessage: "Bitte fügen Sie vor dem Speichern einen Link ein.",
    },
});

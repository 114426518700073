import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 790 702"
    preserveAspectRatio="xMinYMin slice"
    {...props}
  >
    <path
      fill="#e6e6e6"
      d="M678.923 580.033c-13.33 39.042-30.472 78-52.433 115.079-.487.835-.98 1.653-1.477 2.485l-54.369-17.2c.313-.787.656-1.668 1.024-2.63 21.929-56.71 154.232-402.236 148.294-470.361.639 5.576 21.336 190.12-41.039 372.627Z"
    />
    <path
      fill="#e6e6e6"
      d="M636.98 698.43a100.93 100.93 0 0 1-2.261 2.238l-40.786-12.904c.551-.667 1.193-1.453 1.932-2.341 12.079-14.752 47.991-58.919 83.058-105.39 37.686-49.94 74.403-102.533 79.548-124.144-1.033 4.914-33.216 154.51-121.492 242.54Z"
    />
    <path
      fill="#3f3d56"
      d="M657.367 123.114c-.016 0-.03.005-.045.005V35.69A35.69 35.69 0 0 0 621.632 0h-273.72a35.69 35.69 0 0 0-35.69 35.69v44.82a2.892 2.892 0 0 0-2.83 2.89v22.2a2.891 2.891 0 0 0 2.83 2.888v23.257h-.145a2.895 2.895 0 0 0-2.895 2.895v42.93a2.895 2.895 0 0 0 2.895 2.894h.145v17.05h-.045a2.895 2.895 0 0 0-2.895 2.896v43.35a2.895 2.895 0 0 0 2.895 2.894h.045V665.68a35.69 35.69 0 0 0 35.69 35.69h273.72a35.69 35.69 0 0 0 35.69-35.69V208.13c.015 0 .03.004.045.004a5.155 5.155 0 0 0 5.155-5.155v-74.71a5.155 5.155 0 0 0-5.155-5.155Z"
    />
    <path
      fill="#fff"
      d="M636.012 45.68v610a27.41 27.41 0 0 1-27.41 27.4h-247.66a27.41 27.41 0 0 1-27.41-27.4v-610a27.41 27.41 0 0 1 27.41-27.41h37.06v4.81a22.58 22.58 0 0 0 22.57 22.57h126a22.57 22.57 0 0 0 22.52-22.57v-4.76h39.51a27.41 27.41 0 0 1 27.41 27.36Z"
    />
    <path
      fill="#ffb8b8"
      d="M234.411 342.282a10.743 10.743 0 0 0-14.423 7.958l-88.254 47.988 15.517 17.464 81.96-52.599a10.8 10.8 0 0 0 5.2-20.811ZM211.903 687.551l10.997-7.113-22.203-45.803-16.231 10.498 27.437 42.418z"
    />
    <path
      fill="#2f2e41"
      d="m250.714 676.931-34.563 22.356-8.637-13.354 21.708-14.041a15.31 15.31 0 0 1 21.17 4.54l.322.5Z"
    />
    <path
      fill="#ffb8b8"
      d="M88.511 689.206h13.097l6.233-50.518h-19.33v50.518z"
    />
    <path
      fill="#2f2e41"
      d="M126.867 701.367H85.705v-15.904h25.853a15.31 15.31 0 0 1 15.31 15.31v.594Z"
    />
    <path
      fill="#3f3d56"
      d="M389 98.992h-27a2.5 2.5 0 0 1 0-5h27a2.5 2.5 0 0 1 0 5ZM389 105.992h-27a2.5 2.5 0 0 1 0-5h27a2.5 2.5 0 0 1 0 5ZM389 112.992h-27a2.5 2.5 0 0 1 0-5h27a2.5 2.5 0 0 1 0 5ZM598.292 106.042h-.91l-.35-.29a7.61 7.61 0 0 0 1.78-4.89 7.44 7.44 0 1 0-7.4 7.48 7.73 7.73 0 0 0 4.88-1.78l.34.29v.92l5.74 5.75 1.72-1.72Zm-6.88 0a5.18 5.18 0 1 1 5.16-5.2v.02a5.15 5.15 0 0 1-5.12 5.18h-.04Z"
    />
    <path
      fill="#6c63ff"
      d="M444.005 190.992H395.5a11 11 0 0 1 0-22h48.505a11 11 0 0 1 0 22Z"
    />
    <path
      fill="#e6e6e6"
      d="M592.5 214.992h-202a6 6 0 0 1 0-12h202a6 6 0 0 1 0 12ZM464.7 238.992h-74.2a6 6 0 0 1 0-12h74.2a6 6 0 0 1 0 12Z"
    />
    <path
      fill="#6c63ff"
      d="M444.005 456.992H395.5a11 11 0 0 1 0-22h48.505a11 11 0 0 1 0 22Z"
    />
    <path
      fill="#e6e6e6"
      d="M592.5 480.992h-202a6 6 0 0 1 0-12h202a6 6 0 0 1 0 12ZM464.7 504.992h-74.2a6 6 0 0 1 0-12h74.2a6 6 0 0 1 0 12Z"
    />
    <path
      fill="#6c63ff"
      d="M444.005 579.992H395.5a11 11 0 0 1 0-22h48.505a11 11 0 0 1 0 22Z"
    />
    <path
      fill="#e6e6e6"
      d="M592.5 603.992h-202a6 6 0 0 1 0-12h202a6 6 0 0 1 0 12ZM464.7 627.992h-74.2a6 6 0 0 1 0-12h74.2a6 6 0 0 1 0 12Z"
    />
    <path
      fill="#6c63ff"
      d="M621 394.492H235a8.51 8.51 0 0 1-8.5-8.5v-105a8.51 8.51 0 0 1 8.5-8.5h386a8.51 8.51 0 0 1 8.5 8.5v105a8.51 8.51 0 0 1-8.5 8.5Z"
    />
    <path
      fill="#2f2e41"
      d="M96.232 519.412c3.69 25.89-15.85 140.59-13.68 140.58l28.06-.15c1-1.92 13.88-63.81 17.92-73.74 5.64-13.78 11.33-27.73 13.55-42.45 2.09-13.82 1.06-27.9-.3-41.81a2.64 2.64 0 0 0-3.06-3l-37.65-1.94c-7.76-.4-9.6-2.1-8.42 5.52.82 5.69 2.76 11.23 3.58 16.99Z"
    />
    <path
      fill="#2f2e41"
      d="M103.632 456.462c-7.65 11-15.67 23.66-13.13 36.83 1.93 10 9.55 17.75 16.74 25 28.74 28.86 85.41 156.2 85.41 156.2 27.348-17.5-.652.5 27.1-18-2.46-.19-58.35-201.1-62.98-201.37-18.73-1.24-34.89-3.07-53.14 1.34Z"
    />
    <path
      fill="#e6e6e6"
      d="M133.89 351.763c-3.886-3.465-31.192-14.151-31.192-14.151a4.684 4.684 0 0 0-1.509.045c-1.554.392-2.263 2.323-1.931 3.885s-2.391 27.224-3.093 38.894c-1.23 20.367 10.765 66.503 10.66 67.914a21.076 21.076 0 0 1-6.21 13.292c.935 1.16 60.266 3.485 64.302 4.858.814-1.034-4.594-74.213-4.843-78.347-.415-6.276-21.054-31.637-26.184-36.39Z"
    />
    <path
      fill="#ffb8b8"
      d="M91.573 510.55a10.743 10.743 0 0 0 3.841-16.018l25.033-138.54-23.36-.308-17.384 136.998a10.8 10.8 0 0 0 11.87 17.869Z"
    />
    <path
      fill="#e6e6e6"
      d="M91.372 366.272a4.43 4.43 0 0 1-2.76-.72c-1.39-1.19 9.95-19.4 12.19-19.67 7.29-.84 14.79-1.12 21.86.85s13.72 6.45 16.88 13.08c1.32 2.76-8.25 16.65-12.6 14.61-5.21-2.44-28.71-7.98-35.57-8.15Z"
    />
    <circle cx={117.102} cy={309.88} r={26.239} fill="#ffb8b8" />
    <path
      fill="#2f2e41"
      d="M91.33 353.264a21.925 21.925 0 0 0 23.085-28.726c-1.044-3.14-2.79-6.017-3.745-9.184s-.983-6.912 1.138-9.451c4.15-4.968 12.148-1.843 18.545-2.831 6.817-1.053 12.047-7.592 12.592-14.468s-3.038-13.68-8.373-18.052-12.216-6.524-19.08-7.199a51.594 51.594 0 0 0-52.955 70.496c2.868 7.175 7.552 13.858 14.206 17.788s15.441 4.685 22.073.718"
    />
    <path
      fill="#fff"
      d="M372.277 320.492h-67.054c-4.258 0-7.723-4.934-7.723-11s3.465-11 7.723-11h67.054c4.259 0 7.723 4.935 7.723 11s-3.465 11-7.723 11Z"
    />
    <path
      fill="#e6e6e6"
      d="M552.5 344.492h-249a6 6 0 0 1 0-12h249a6 6 0 0 1 0 12ZM424.7 368.492H303.5a6 6 0 0 1 0-12h121.2a6 6 0 0 1 0 12Z"
    />
    <path fill="#3f3d56" d="M0 699.992h790v2H0z" />
  </svg>
)
export default SvgComponent

const defaultState = {
    categories: null,
    dishes: null,
    type: null,
    url: null,
    searchQuery: null,
    showFilterModal: false,
    allergensPreservativesFilters: [],
    spiceFilter: 0,
};
const digitalMenuConfigReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_DIGITAL_MENU_CATS':
            return {...state, categories: action.categories};

        case 'SET_DIGITAL_MENU_DISHES':
            return {...state, dishes: action.dishes};

        case 'SET_DIGITAL_MENU_TYPE':
            return {...state, type: action.menuType};

        case 'SET_DIGITAL_MENU_URL':
            return {...state, url: action.url};

        case 'SET_DIGITAL_MENU_SEARCH':
            return {...state, searchQuery: action.searchQuery};

        case 'SET_SHOW_DISHES_FILTER_MODAL':
            return {...state, showFilterModal: action.showFilterModal};

        case 'SET_ALLERGENS_FILTER':
            return {
                ...state,
                allergensPreservativesFilters: action.allergensPreservativesFilters
            };

        case 'SET_SPICE_FILTER':
            return {...state, spiceFilter: action.spiceFilter};

        default:
            return state;
    }
};

export default digitalMenuConfigReducer;
import http from "../utils/http";
import {displayNotification} from "../components/Notification";
import { getIntl } from "../utils/intl";

export const uploadHubImage = async (company_id, data) => {
    return http.post(`/company/${company_id}/image`, data);
}

export const addHubConfig = async (title, subtitle, name, imageUrl, companyId) => {
    const intl = getIntl();
    const errorTitle = intl.formatMessage({
        id: "Error",
        defaultMessage: "Etwas ist schief gelaufen!",
      });
    
      const errorBody = intl.formatMessage({
        id: "WelcomyHub.Setup.Notification.Error.Body",
        defaultMessage: "Ihre Welcomy Hub wurde nicht aktualisiert!",
      });
    
      const successTitle = intl.formatMessage({
        id: "SetupPage.Notification.FormSubmitSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const successBody = intl.formatMessage({
        id: "WelcomyHub.Setup.Notification.Success.Body",
        defaultMessage: "Ihre Welcomy Hub wurde aktualisiert!",
      });


    try {
        const response = await http.post(`company/${companyId}/hub/config`, {
            title,
            subtitle,
            name,
            image_url: imageUrl,
        });

        if (response.status >= 200 && response.status <= 300) {
            displayNotification(
                "success",
                successTitle,
                successBody,
            );
        }
    } catch (error) {
        console.log(error);
        displayNotification(
            "error",
            errorTitle,
            errorBody,
        );
    }
}

export const getHubConfig = async (companyId) => {
    return await http.get(`company/${companyId}/hub/config`);
}
import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';

import { Button, Col, Row, Select, Typography } from "antd";
import { getIntl } from "../../utils/intl";

import PdfUpload from "./PdfUpload";
import cooking from "../../static/cooking.svg";
import ProductSVG from "../../static/product-list-mobile";
import OrganizeMenu from "./OrganizeMenu";
import SpiceLevelsContext from "./SpiceLevelCtx";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

const { Option } = Select;
const { Text, Title, Paragraph } = Typography;

const scrollWithOffset = (el) => {
  el.scrollIntoView({behavior: 'smooth', block: 'center' })
}

const Categories = ({data}) => {

  if (!data) return null;
  return data.map(cat => (
    <HashLink
      to={`#${cat.id}`}
      scroll={scrollWithOffset}
      replace
    >
      <Paragraph>{cat.name}</Paragraph>
    </HashLink>
  ));
};

function DigitalMenu(props) {
  const history = useHistory();
  const intl = getIntl();
  const [menuType, setMenuType] = useState("digital");
  const verticalId = useSelector(state => state.companyConfig.vertical_id);
  const categories = useSelector(
    (state) => state.digitalMenuConfig.categories
  );


  /**
   * Translations
   */

  let pageTitleId = "DigitalMenuPage.Header";
  let pageDescription = "OrganizeMenuPage.Description.Text";
  let menuTypeText = "OrganizeMenuPage.MenuTypeDropDown.Text";
  if (verticalId && verticalId === 4){
    pageTitleId = "DigitalMenuPage.Business.Header";
    pageDescription = "OrganizeMenuPage.Business.Description.Text";
    menuTypeText = "OrganizeMenuPage.Business.MenuTypeDropDown.Text";
  }
  const pageTitle = intl.formatMessage({
    id: pageTitleId,
    defaultMessage: "Digitale Speisekarte",
  });
  const printButton = intl.formatMessage({
    id: "DigitalMenuPage.PrintFunction",
    defaultMessage: "PDF generieren",
  });

  /**
   * End Translations
   */

  const selectTypeOfMenu = (value) => {
    setMenuType(value);
  };

  const generateMenuView = () => {
    if (menuType === "pdf") {
      return <PdfUpload />;
    } else if (menuType === "digital") {
      return (
        <SpiceLevelsContext>
          <OrganizeMenu />
        </SpiceLevelsContext>
      );
    }
    return null;
  };

  return (
    <div className="digital-menu-wrapper">
      <Row justify="center">
        <Col xs={0} xl={4}></Col>
        <Col xs={24} xl={16}>
          <Row>
            {verticalId === 3 ? (
              <Col xs={24}>
                <img
                  src={cooking}
                  className="main-image main-image--gastro"
                  alt="Image with a man sitting in a Chef's hat"
                />
              </Col>
            ) : (
              <Col xs={24} className="product-image-wrapper">
                <ProductSVG className="main-image main-image--product" />
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24}>
              <Title level={1} className="page-title m-t-32 m-b-6">
                {pageTitle}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Paragraph level={1}>
                <FormattedMessage
                  id={pageDescription}
                  defaultMessage="Willkommen in dem Verwaltungsbereich Ihrer digitalen Speise- und Getränkekarte. 
                  Hier können Sie Ihre Speisen und Getränke individuell anlegen und wichtige, für das Tagesgeschäft-förderliche Details hinzufügen. 
                  Entweder laden Sie ganz einfach eine aktuelle Version Ihrer Speise- und Getränkekarte im PDF-Format hoch oder profitieren von der 
                  gesamten Welcomy Funktionalität indem sie die Option “Digital” wählen und Ihre Menüpunkte einzeln anlegen. 
                  Die “Digital”-Option ermöglicht es Ihnen nicht nur Fotos, Beschreibungen, Inhaltsstoffe und weitere Informationen hinzuzufügen, 
                  sondern können auch die smarten Welcomy-Features wie die Verfügbarkeits- und Kundenzufriedenheitsfunktionen nutzen."
                ></FormattedMessage>
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={[0, 12]} className="m-t-12 selection">
            <Col xs={24} lg={19}>
              <Text className="text m-r-12">
                <FormattedMessage id={menuTypeText}></FormattedMessage>
              </Text>
              <Select defaultValue="digital" onChange={selectTypeOfMenu}>
                <Option value="pdf">PDF</Option>
                <Option value="digital">Digital</Option>
              </Select>
            </Col>
            {menuType === "digital" && (
              <Col xs={24} lg={5}>
                <Button
                  type="primary"
                  onClick={() => history.push("/dashboard/print-menu")}
                >
                  {printButton}
                </Button>
              </Col>
            )}
          </Row>
          {generateMenuView()}
        </Col>
        <Col xs={0} xl={4}>
          {categories && (
            <div className="categories__shortcut">
              <div className="categories__shortcut--sticky-wrapper">
                <Title level={4}>Categories</Title>
                <div className="categories__titles">
                  <Categories data={categories} />
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default DigitalMenu;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import http from "../../utils/http";
function ClientLogo(props) {
  const company_id = useSelector((state) => state.companyConfig.company_id);

  const [logoUrl, setLogoUrl] = useState('');

  /**
   * Assing public cookie to allow /public queries to fetch data for some amount of time
   */
  const assignPublicCookie = async () => {
    if (company_id) {
      await http.get(`/company/${company_id}/assign_token`);
    }
  };

  const getCompany = async () => {
    if (company_id) {
      const result = await http.get(`company/${company_id}`);
      setLogoUrl(result.data.payload.logo_url);
    }
  }

  useEffect(() => {
    assignPublicCookie();
    getCompany();
  }, [company_id]);

  if (logoUrl) {
    return (
      <div className="client-logo-wrapper">
        <img src={logoUrl} alt="An image of client's logo" className="logo" />
      </div>
    )
  } else {
    return null;
  }
}

export default ClientLogo;

import http from './http';

export const uploadImages = async (company_id, images, meta) => {
  let data = new FormData();
  data.append("images", JSON.stringify(images));
  data.append("meta", meta);
  try {
    const res = await http.post(
      `/company/${company_id}/image`,
      data
    );
    return res.data.payload.images;
  } catch (error) {
    console.log("Error uploading images", error);
    return [];
  }
}

import amplitude from 'amplitude-js';


export const sendEvent = (amplId, eventName, properties) => {
    if (!amplId){
        console.log('Not sending event to amplitude as no amplId was found');
        return;
    }

    let deferInitialization = true;
    if(window.CC.getUserPreferences().accepted_categories.includes('Statistik')){
        deferInitialization = false;
    }
    amplitude.getInstance().init(
        amplId, null, {
        serverZone: 'EU',
        serverZoneBasedApi: true,
        deferInitialization: deferInitialization,
        cookieExpiration: 7,
        storage: 'cookies',
        trackingOptions: {
            city: false,
            country: true,
            carrier: false,
            device_manufacturer: false,
            device_model: false,
            dma: false,
            ip_address: false,
            language: false,
            os_name: false,
            os_version: false,
            platform: false,
            region: false,
            version_name: false
        }
    });
    amplitude.getInstance().logEvent(eventName, properties);
}

import React from 'react';
import WelcomyHubPreview from '../WelcomyHubPreview/WelcomyHubPreview';
import WelcomyHub from '.';

const PreviewWrapperHub = (props) => {
  return (
    <WelcomyHubPreview>
      <WelcomyHub />
    </WelcomyHubPreview>
  );
}

export default PreviewWrapperHub;
import { Typography } from "antd";
import React from "react";

const { Paragraph } = Typography;

function PageHeader({ title, subtitle, level, ...props }) {
  return (
    <div className="page-header m-b-24" {...props}>
      <h1 level={level || 1}>
        {title}
        {subtitle && <span>{subtitle}</span>}
      </h1>
    </div>
  );
}

export default PageHeader;

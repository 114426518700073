// client.js

import axios from "axios";
import { displayNotification } from "../components/Notification";
import { getCookie } from "../components/getCookie";
import { clearCookies } from './cookies'; 
import { getIntl } from "./intl";


const ENDPOINT = '/api/v1';

let axiosInstance = axios.create({
  baseURL: ENDPOINT,
  withCredentials: true,
});

let tokens = {
  access_token: getCookie("_a"),
  getAccessToken: function () {
    return this.access_token;
  },
  setAccessToken: function (token) {
    this.access_token = token;
  },
};

axiosInstance.interceptors.request.use(function (request) {
  // This URL is public and therefore does not need token verification.
  // We call it normally when the user is not logged anyways.
  if (request.url === "/company_path") {
    return request;
  }
  if (request.url.indexOf('https://res.cloudinary.com') !== -1 
      || request.url.indexOf('https://api.currentsapi.services/v1/search') !== -1 ) {
    request.headers = {};
    request.withCredentials = false;
    return request;
  }
  if (
    !window.location.pathname.includes("public")
    && (!axiosInstance.defaults.headers["Authorizaton"] ||
    !tokens.getAccessToken())
  ) {
    tokens.setAccessToken(getCookie("_a"));
    if (tokens.getAccessToken()) {
      axiosInstance.defaults.headers[
        "Authorization"
      ] = `Bearer ${tokens.getAccessToken()}`;
      request.headers.Authorization = `Bearer ${tokens.getAccessToken()}`;
    }
  }
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { status, data, config } = error.response;
    const originalRequest = config;
    if (
      originalRequest.url.includes("login") &&
      !data.msg === "Token has expired"
    ) {
      return Promise.reject(error);
    }

    if (data.msg === "Missing Authorization Header") {
      window.location.replace("/login");
      return Promise.reject(error);
    }

    if (status === 401 && data.msg === "Token has expired") {
      const intl = getIntl();
      const sessionExpiredTitle = intl.formatMessage(
        {
          id: "General.Session.Timeout.Title",
          defaultMessage: "Bitte erneut einloggen"
        }
      );
      const sessionExpiredBody = intl.formatMessage(
        {
          id: "General.Session.Timeout.Body",
          defaultMessage: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."
        }
      );
      displayNotification(
        "info",
        sessionExpiredTitle,
        sessionExpiredBody
      );
      setTimeout(() => {
        window.location.replace("/login");
        clearCookies();
      }, 3000);
      const requestSubscribers = new Promise((resolve) => {
          resolve(axios(originalRequest));
      });
      return requestSubscribers;
    } else {
      return Promise.reject(error);
    }
  }
);

export const getErrorMsg = (error, intl = null, returnRaw = false, generalError=false) => {
  console.log(error);
  intl = intl || getIntl();
  let errorMsg = "";
  if (error.response && error.response.data && error.response.data.error && !generalError) {
    if (returnRaw) {
      return error.response.data.error;
    }
    errorMsg += error.response.data.error;
  }
  else {
    if (returnRaw) {
      return null;
    }
    const message = intl.formatMessage(
      {
        id: "General.ErrorNotification.Body",
        defaultMessage: `Etwas ist schief gelaufen! Wenn das Problem weiterhin besteht, 
        bitte kontaktieren Sie unser freundliches Support-Team! :)`
      }
    );
    errorMsg += message;
  }
  return errorMsg;
}

axiosInstance.ENDPOINT = axiosInstance.ENDPOINT || ENDPOINT;

export default axiosInstance;

export const setDigitalMenuCategories = (cats) => {
    return {
        type: 'SET_DIGITAL_MENU_CATS',
        categories: cats,
    };
};

export const setDigitalMenuDishes = (dishes) => {
    return {
        type: 'SET_DIGITAL_MENU_DISHES',
        dishes: dishes,
    };
};

export const setDigitalMenuType = (type) => {
    return {
        type: 'SET_DIGITAL_MENU_TYPE',
        menuType: type,
    };
};

export const setDigitalMenuUrl = (url) => {
    return {
        type: 'SET_DIGITAL_MENU_URL',
        url: url,
    };
};

export const setDigitalMenuSearch = (query) => {
    return {
        type: 'SET_DIGITAL_MENU_SEARCH',
        searchQuery: query,
    };
};
export const showDishesFilterModal = (showModal) => {
    return {
        type: 'SET_SHOW_DISHES_FILTER_MODAL',
        showFilterModal: showModal,
    };
};

export const setAllergenPreservativesFilter = (allergens) => {
    return {
        type: 'SET_ALLERGENS_FILTER',
        allergensPreservativesFilters: allergens,
    };
};

export const setSpiceFilter = (spiceLevel) => {
    return {
        type: 'SET_SPICE_FILTER',
        spiceFilter: spiceLevel,
    };
};

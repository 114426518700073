import companyConfig from "./companyConfig";
import layoutConfig from "./layoutConfig";
import dishConfig from "./dishConfig";
import digitalMenuConfig from "./digitalMenuConfig";
import editorsConfig from './editorsConfig';
import qrCodeExportConfig from './qrCodeExport';

import { combineReducers } from "redux";
import livePreview from "./livePreviewReducer";
import usersManagement from "./usersReducer";
import pdfConversation from './pdfAI';
import hubConfig from './hubConfig';

const allReducers = combineReducers({
    layoutConfig,
    companyConfig,
    dishConfig,
    digitalMenuConfig,
    editorsConfig,
    livePreview,
    qrCodeExportConfig,
    usersManagement,
    pdfConversation,
    hubConfig,
});

export default allReducers;

import React, { memo, useState } from 'react';
import Navbar from './Navbar/Navbar';
import MainContent from './MainContent';
import { Layout } from 'antd';

const { Header, Content } = Layout;
const HEADER_TOP_DISTANCE = 60;
const SCROLL_WRAPPER_ID = "content-scroll-wrapper";

const ScrollListener = (props) => {
    const [isScrollTop, setIsScrollTop] = useState(false);

    const handleScroll = (e) => {
        if (e.target.id === SCROLL_WRAPPER_ID) {
            const distanceToTop = e.target.scrollTop;
            const scrolled = distanceToTop > HEADER_TOP_DISTANCE;
            setIsScrollTop(scrolled);
        }
    }

    return (
        <Content id={SCROLL_WRAPPER_ID} className="content" onScroll={handleScroll}>
            <Header>
                <Navbar isScrolled={isScrollTop} />
            </Header>
            <MainContent />
        </Content>
    );
}

export default memo(ScrollListener);
